// External Libs/Components
import React from 'react'
import moment from 'moment'

// Global Components
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'
import SidebarComponent from 'components/layout/SidebarComponent'
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'

// Containers Components
import CsvDataConnectorComponent from './CsvDataConnectorComponent'
import DataConnectorTasksComponent from './DataConnectorTasksComponent'
import FederatedDataConnectorComponent from 'containers/admin/data_sources/data_connectors/FederatedDataConnectorComponent'
import OaiPmhDataConnectorComponent from './OaiPmhDataConnectorComponent'

// Stores
import {EntityContext, EntityStore} from 'stores/EntityStore'
import {withLocaleStore} from 'stores/LocaleStore'

class DataConnectorComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSourceWithConnectors : undefined,
    };
    moment.locale(props.localeStore.actions.getLocale());
    this.getDataSourceWithConnectors = this.getDataSourceWithConnectors.bind(this);
    this.onDataConnectorChange = this.onDataConnectorChange.bind(this);
    this.onDataConnectorDelete = this.onDataConnectorDelete.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.getDataSourceWithConnectors();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname !== this.props.location.pathname) {
      this.getDataSourceWithConnectors();
    }
  }

  getDataSourceWithConnectors() {
    let { dataSourceId } = this.props.match.params;
    if( dataSourceId !== undefined) {
      this.context.actions.getById(dataSourceId, { params: { expanded : true } })
        .then((result) => {
          this.setState({ dataSourceWithConnectors : result.data })
        })
        .catch((error) => {
          this.props.history.push({
            pathname: '/error',
            search: '?error_code=' + error.response.status
          });
        });
    }
  }

  getDataConnector(dataConnectorId, dataSourceWithConnectors) {
    let dataConnectorMatch = undefined;
    let dataConnectors = dataSourceWithConnectors === undefined? [] : dataSourceWithConnectors['dataConnectors'];
    dataConnectors.map((dataConnector)=> {
      if(dataConnector.id === dataConnectorId) {
        dataConnectorMatch = dataConnector;
      }
    });
    return dataConnectorMatch;
  }

  render() {
    let dataSource = this.state.dataSourceWithConnectors;
    let { dataConnectorId } = this.props.match.params;
    let dataConnector = this.getDataConnector(dataConnectorId, dataSource);

    return (
      <React.Fragment>
        <EntityStore context='data_sources'>
          <NavbarComponent/>
          <section className='data-connector'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-auto sidebar-container'>
                  <SidebarComponent/>
                </div>
                <div className='col admin-container'>
                  {this.renderHeader(dataSource, dataConnector)}
                  {this.renderDataConnector(dataConnector)}
                  {this.renderDataConnectorTasks(dataConnector)}
                </div>
              </div>
            </div>
          </section>
          <FooterComponent/>
        </EntityStore>
      </React.Fragment>
    );
  }

  renderHeader(dataSource, dataConnector) {
    const { localeStore } = this.props;
    let { dataSourceId, dataConnectorId } = this.props.match.params;
    let items = [];
    if(dataConnector !== undefined) {
      items = [
        'data_sources',
        { value: dataSource['name'], target: '/#/admin/data_sources/' + dataSourceId },
        { key: 'data_connectors', target: '/#/admin/data_sources/' + dataSourceId },
        { value: dataConnector['name'], target: '/#/admin/data_sources/' + dataSourceId + '/data_connectors/' + dataConnectorId }
      ];
    }

    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-12'>
            <BreadcrumbComponent
              items={items}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <h1 className='container-title'>{localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.title'})}</h1>
            <p className='container-description'>{localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.description'})}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderDataConnector(dataConnector) {
    if(dataConnector !== undefined ) {
      switch (dataConnector['type']) {
        case 'OAI_PMH' :
          return <OaiPmhDataConnectorComponent
            dataSource={this.state.dataSourceWithConnectors}
            dataConnector={dataConnector}
            onDelete={this.onDataConnectorDelete}
            onChange={this.onDataConnectorChange}
          />;
        case 'CSV' :
          return <CsvDataConnectorComponent
            dataSource={this.state.dataSourceWithConnectors}
            dataConnector={dataConnector}
            onDelete={this.onDataConnectorDelete}
            onChange={this.onDataConnectorChange}
          />;
        case 'FEDERATED' :
          return <FederatedDataConnectorComponent
            dataSource={this.state.dataSourceWithConnectors}
            dataConnector={dataConnector}
            onDelete={this.onDataConnectorDelete}
            onChange={this.onDataConnectorChange}
          />;
        default:
          return <p>Not implemented</p>
      }
    }
  }

  renderDataConnectorTasks(dataConnector) {
    const { localeStore } = this.props;
    if(dataConnector !== undefined ) {
      return (
        <React.Fragment>
          <h1 className='container-title'>
            { localeStore.intl.formatMessage( {id: 'retrievo.ui.data_connector.tasks.title'} ) }
          </h1>
          <DataConnectorTasksComponent dataConnector={dataConnector}/>
        </React.Fragment>
      )
    }
  }

  onDataConnectorDelete() {
    let dataSourceWithConnectors = this.state.dataSourceWithConnectors;

    this.props.history.push({
      pathname: '/admin/data_sources/' + dataSourceWithConnectors['id']
    })
  }

  onDataConnectorChange(updatedDataConnector) {
    let dataSourceWithConnectors = this.state.dataSourceWithConnectors;
    let dataConnectors = dataSourceWithConnectors['dataConnectors'] || [];
    dataSourceWithConnectors['dataConnectors'] = dataConnectors.map((dataConnector) => {
      if (dataConnector['id'] === updatedDataConnector['id']) {
        return updatedDataConnector
      }
      return dataConnector;
    });

    this.setState({ dataSourceWithConnectors: dataSourceWithConnectors });
  }

}

DataConnectorComponent.contextType = EntityContext;

export default withLocaleStore(
    DataConnectorComponent
);
