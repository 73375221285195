// External libs
import React, { Component } from 'react';
import Axios from 'axios';

// Stores
import { withConfigStore } from 'stores/ConfigStore';

const initialState = {
  actions: {}
};

const EntityContext = React.createContext(initialState);
const EntityStoreProvider = EntityContext.Provider;
const EntityStoreConsumer = EntityContext.Consumer;

class EntityStoreComponent extends Component {

  constructor(props) {
    super(props);
    initialState.actions = {
      get : this.get.bind(this),
      getById : this.getById.bind(this),
      getVersion : this.getVersion.bind(this),
      post : this.post.bind(this),
      put : this.put.bind(this),
      delete : this.delete.bind(this)
    };
    this.state = initialState;
  }

  render() {
    return (
      <EntityStoreProvider value={this.state}>
        {this.props.children}
      </EntityStoreProvider>
    );
  }

  getContext(context, options) {
    if(options === undefined) {
      return '/' + context
    }

    return '/' + ((options.context !== undefined) ? options.context : context);
  }

  get(options) {
    const { configStore, context } = this.props;
    let url = configStore.actions.getApiUrl() + this.getContext(context, options);
    return Axios.get(url, {
      params : {
        expanded : options !== undefined ? options['expanded'] || false : false
      }
    });
  }

  getById(id, options) {
    const { configStore, context } = this.props;
    let url = configStore.actions.getApiUrl() + this.getContext(context, options);
    return Axios.get(url + '/' + id, {
        params : options !== undefined ? options.params || {} : {}
      }
    );
  }

  getVersion() {
    const { configStore } = this.props;
    let url = configStore.actions.getBaseUrl() + '/version.json';
    return Axios.get(url);
  }

  post(object, options, params = {}) {
    const { configStore, context } = this.props;
    let url = configStore.actions.getApiUrl() + this.getContext(context, options);
    return Axios.post(url, object, { params });
  }

  put(object, options, params = {}) {
    const { configStore, context } = this.props;
    let url = configStore.actions.getApiUrl() + this.getContext(context, options);
    return Axios.put(url, object, { params });
  }

  delete(object, options) {
    const { configStore, context } = this.props;
    let url = configStore.actions.getApiUrl() + this.getContext(context, options);

    return Axios.delete(url + '/' + object['id']);
  }

}

// Providing context as higher-order-component
// https://reactjs.org/docs/context.html#consuming-context-with-a-hoc
function withEntityStore(Component) {
  return function EntityStore(props) {
    return(
      <EntityStoreConsumer>
        { (entityStore) => <Component {...props} entityStore={entityStore} /> }
      </EntityStoreConsumer>
    )
  }
}

const EntityStore = withConfigStore(EntityStoreComponent);

export { EntityStore, EntityContext, withEntityStore }

