// External Libs/Components
import React, { Component } from 'react'
import moment from 'moment';
import $ from 'jquery';
import numeral from 'numeral';

// Global Components
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'
import SidebarComponent from 'components/layout/SidebarComponent'
import TaskReportEntriesComponent from 'containers/admin/tasks/TaskReportEntriesComponent'

// Stores
import { withRouter } from 'react-router-dom'
import { withConfigStore } from 'stores/ConfigStore';
import { withLocaleStore } from 'stores/LocaleStore'
import { EntityStore, EntityContext } from 'stores/EntityStore'

// CSS (css paths are the only relative paths)
import './_.css';

class TaskComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      task: undefined,
      taskReport: undefined,
      lastUpdate: undefined,
      lastUpdateInSeconds: undefined,
      refreshInterval: undefined,
      refreshIn: 30
    };
    this.refresh = this.refresh.bind(this);
    this.autoRefresh = this.autoRefresh.bind(this);
    this.handleStopTask = this.handleStopTask.bind(this);
    moment.locale(props.localeStore.actions.getLocale());
  }

  componentDidMount() {
    let refreshInterval = setInterval(this.autoRefresh, 1000);
    window.scrollTo(0, 0);
    this.refresh();
    this.setState({ refreshInterval });
  }

  componentDidUpdate(prevProps) {
    let tooltips =  $('.task-tooltip');
    tooltips.tooltip();

    if(prevProps.location.pathname !== this.props.location.pathname) {
      this.refresh();
      window.scrollTo(0, 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.state.lastUpdateInSeconds !== undefined) {
      if(this.state.task['state'] !== nextState.task['state']) {
        return true;
      } else {
        return this.state.lastUpdateInSeconds !== nextState.lastUpdateInSeconds
      }
    }
    return true;
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshInterval)
  }

  getTask() {
    let { taskId } = this.props.match.params;

    if( taskId !== undefined) {
      this.context.actions.getById(taskId, { params: { withSimpleConnector : true } })
        .then((result) => {
          this.getReport(result.data);
        })
        .catch((error) => {
          this.props.history.push({
            pathname: '/error',
            search: '?error_code=' + error.response.status
          });
        });
    }
  }

  getReport(task) {
    if( task.id !== undefined) {
      this.context.actions.get({ context : ('tasks/' + task.id + '/report')})
        .then((result) => {
          this.setState({ task: task, taskReport : result.data, lastUpdate: new Date(), lastUpdateInSeconds: undefined })
        })
        .catch(() => {
          this.setState({ task: task, lastUpdate: new Date() })
        })
    }
  }

  getCounterValue(value) {
    return value !== undefined ? value >= 0? value.toLocaleString() : '?' : '?';
  }

  getCounterDisplayValue(value) {
    if(value > 1000) {
      return numeral(value).format('0.0b').slice(0, -1);
    }

    return this.getCounterValue(value);
  }

  refresh(event) {
    if(event) {
      event.preventDefault();
    }
    this.getTask(this.getReport);
  }

  autoRefresh() {
    if(this.state.lastUpdate && this.state.task) {
      let lastUpdateInSeconds = this.getLastUpdateInSeconds(this.state.lastUpdate);

      if(this.state.task['state'] !== 'CREATED' &&
         this.state.task['state'] !== 'STARTED' &&
         this.state.task['state'] !== 'READY_FOR_INTERNAL_PROCESSING' &&
         this.state.task['state'] !== 'QUEUED_FOR_INTERNAL_PROCESSING' &&
         this.state.task['state'] !== 'DOING_INTERNAL_PROCESSING') {
        return
      }
      if(lastUpdateInSeconds > this.state.refreshIn ) {
        this.refresh();
      } else {
        this.setState({ lastUpdateInSeconds })
      }
    }
  }

  getLastUpdateInSeconds(lastUpdate) {
    let now = moment(new Date());
    return  moment.duration(now.diff(lastUpdate)).seconds();
  }

  handleStopTask(event) {
    event.preventDefault();

    let toUpdatetask = {
      state: 'TO_STOP',
      id: this.state.task.id,
      type: this.state.task.type,
      dataConnectorId: this.state.task.dataConnectorId,
      dataConnectorVersion: this.state.task.dataConnectorVersion,
      dataSourceId: this.state.task.dataSourceId,
    };


    this.context.actions.put(toUpdatetask,  { context : ('tasks')})
      .then((result) => {
        this.setState({ task : result.data, lastUpdate: new Date() })
      })
      .catch(() => {
        // FIXME maybe add notification
      });
  }

  render() {
    let shouldUpdate = this.state.lastUpdateInSeconds !== undefined? this.state.lastUpdateInSeconds >= this.state.refreshIn : true;
    return (
      <React.Fragment>
        <EntityStore context='tasks'>
          <NavbarComponent/>
          <section className='task'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='sidebar-container col-auto'>
                  <SidebarComponent/>
                </div>
                <div className='col admin-container'>
                  <div className='row'>
                    {this.renderHeader(this.state.task)}
                    {this.renderTaskReport(this.state.task, this.state.taskReport)}
                    {this.renderAdditionalInformation(this.state.task, this.state.taskReport)}
                    {this.renderTaskReportEntries(this.state.task, this.state.taskReport, shouldUpdate)}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent/>
        </EntityStore>
      </React.Fragment>
    );
  }

  renderHeader(task) {
    const { localeStore } = this.props;
    if(task !== undefined) {
      let started = task['started'] ? moment(task['started']) : moment(task['created']);
      let ended = moment(task['ended']);
      let duration = moment.duration(ended.diff(started));
      if(!duration.isValid()) {
        duration = moment.duration(moment(new Date()).diff(started));
      }

      let dataConnectorName = task.dataConnector? task.dataConnector.name : task.nameToDelete || task.nameToUpdate;
      let dataSourceId = task.dataConnector? task.dataConnector.dataSourceId : task.dataSourceId;
      let dataConnectorId = task.dataConnector? task.dataConnector.id : task.idToDelete;

      let title = localeStore.intl.formatMessage({ id: 'retrievo.ui.task.' + task['type'] + '.title'}, { data_connector: dataConnectorName });
      let description =  localeStore.intl.formatMessage({ id: 'retrievo.ui.task.' + task['type'] + '.' + task['state'] + '.description'},
        {
          name_to_delete: task['nameToDelete'],
          data_connector: task['dataConnector']? task['dataConnector']['name'] : task['dataConnectorId'],
          duration: duration.humanize(),
          started: started.format('DD-M-YYYY HH:mm:ss'),
          ended: ended.format('DD-M-YYYY HH:mm:ss'),
          startedRelative: started.fromNow(),
        });

      return (
        <React.Fragment>
          <div className='col'>
            <BreadcrumbComponent items={[
              'data_connectors',
              { value: dataConnectorName,
                target: '/#/admin/data_sources/' + dataSourceId + (dataConnectorId !== undefined? '/data_connectors/' + dataConnectorId : '')
              },
              { key: 'tasks',
                target: '/#/admin/data_sources/' + dataSourceId + (dataConnectorId !== undefined? '/data_connectors/' + dataConnectorId : '')
              },
              { value: task['id'],
                target: '/#/admin/tasks/' + task['id']}
            ]}
            />
            <h1 className='container-title' dangerouslySetInnerHTML={{ __html: title }}/>
            <p className='container-description' dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className='col-auto controls'>
            <div className='controls-container'>
              <a href='#' onClick={this.refresh}>
                <span className='fas fa-sync'/>
              </a>
              <a href='#' onClick={this.handleStopTask} style={{display: 'none'}}>
                <span className='fas fa-ban'/>
              </a>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  renderTaskReport(task, taskReport) {
    if( task !== undefined ) {
      let reportElement;
      switch (task['type']) {
        case 'HARVEST' :
        case 'CSV_HARVEST' :
        case 'REPROCESS' :
          reportElement = this.renderHarvestReport(task, taskReport) ;
          break;
        case 'FULLTEXT_THUMBNAIL' :
          reportElement = this.renderFulltextThumbnailReport(task, taskReport);
          break;
        case 'DELETE_DATA_SOURCE' :
          reportElement = this.renderDeleteDataSourceReport(task, taskReport);
          break;
        case 'DELETE_DATA_CONNECTOR' :
          reportElement = this.renderDeleteDataConnectorReport(task, taskReport);
          break;
        case 'UPDATE_VISIBILITY' :
          reportElement = this.renderUpdateVisibilityReport(task, taskReport);
          break;
        default:
          reportElement = (
            <div className='col-12'>
              <p>Not Supported</p>
            </div>
          );
      }

      return (
        <div className='col-12'>
          <div className='row task-report'>
            { reportElement }
          </div>
        </div>
      );
    }
  }

  renderHarvestReport(task, taskReport = {}) {
    const { localeStore } = this.props;

    return (
      <React.Fragment>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['recordsHarvested'])}>
            <p className='task-report-value'>
              {this.getCounterDisplayValue(taskReport['recordsHarvested'])}
            </p>
            <p className='task-report-legend'>
              { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.recordsHarvested.label'}) }
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--success)', borderLeft: '3px solid var(--success)'}}
               title={this.getCounterValue(taskReport['recordsValid'])}>
            <p className='task-report-value'>
              {this.getCounterDisplayValue(taskReport['recordsValid'])}
            </p>
            <p className='task-report-legend'>
              { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.recordsValid.label'}) }
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--danger)', borderLeft: '3px solid var(--danger)'}}
               title={this.getCounterValue(taskReport['recordsInvalid'])}>
            <p className='task-report-value'>
              {this.getCounterDisplayValue(taskReport['recordsInvalid'])}
            </p>
            <p className='task-report-legend'>
              { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.recordsInvalid.label'}) }
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--warning)', borderLeft: '3px solid var(--warning)'}}
               title={this.getCounterValue(taskReport['recordsErased'])}>
            <p className='task-report-value'>
              {this.getCounterDisplayValue(taskReport['recordsErased'])}
            </p>
            <p className='task-report-legend'>
              { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.recordsErased.label'}) }
              </p>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderFulltextThumbnailReport(task, taskReport = {}) {
    const { localeStore } = this.props;

    return (
      <React.Fragment>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['fulltextsHarvested'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['fulltextsHarvested']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.fulltextsHarvested.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--success)', borderLeft: '3px solid var(--success)'}}
               title={this.getCounterValue(taskReport['fulltextsAdded'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['fulltextsAdded']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.fulltextsAdded.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['thumbnailsHarvested'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['thumbnailsHarvested']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.thumbnailsHarvested.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--success)', borderLeft: '3px solid var(--success)'}}
               title={this.getCounterValue(taskReport['thumbnailsAdded'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['thumbnailsAdded']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.thumbnailsAdded.label'})}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderDeleteDataSourceReport(task, taskReport = {}) {
    const { localeStore } = this.props;
    return (
      <React.Fragment>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['recordsDeleted'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['recordsDeleted']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.recordsDeleted.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['reportsDeleted'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['reportsDeleted']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.reportsDeleted.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['tasksDeleted'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['tasksDeleted']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.tasksDeleted.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-3'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['dataConnectorsDeleted'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['dataConnectorsDeleted']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.dataConnectorsDeleted.label'})}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderDeleteDataConnectorReport(task, taskReport = {}) {
    const { localeStore } = this.props;
    return (
      <React.Fragment>
        <div className='col-12 col-lg-6 col-xl-4'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['recordsDeleted'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['recordsDeleted']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.recordsDeleted.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-4'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['reportsDeleted'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['reportsDeleted']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.reportsDeleted.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-4'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['tasksDeleted'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['tasksDeleted']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.tasksDeleted.label'})}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderUpdateVisibilityReport(task, taskReport = {}) {
    const { localeStore } = this.props;
    return (
      <React.Fragment>
        <div className='col-12 col-lg-6 col-xl-4'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['recordsVisibilityUpdated'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['recordsVisibilityUpdated']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.recordsVisibilityUpdated.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-4'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['dataConnectorsVisibilityUpdated'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['dataConnectorsVisibilityUpdated']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.dataConnectorsVisibilityUpdated.label'})}
            </p>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-xl-4'>
          <div className='task-report-number'
               style={{color: 'var(--primary)', borderLeft: '3px solid var(--primary)'}}
               title={this.getCounterValue(taskReport['dataConnectorsVisibilityUpdated'])}>
            <p className='task-report-value'>
              { this.getCounterDisplayValue(taskReport['dataConnectorsVisibilityUpdated']) }
            </p>
            <p className='task-report-legend'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.report.dataSourceVisibilityUpdated.label'})}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderTaskReportEntries(task, taskReport, update) {
    if(!task || !taskReport) return '';
    return (
      <div className='col-12 task-report-entries-container'>
        <TaskReportEntriesComponent task={task} taskReport={taskReport} update={update} />
      </div>
    )
  }

  renderAdditionalInformation(task, taskReport = {}) {
    const { configStore, localeStore } = this.props;
    if(task) {
      let content;
      switch (task['state']) {
        case 'CREATED':
        case 'STARTED':
        case 'READY_FOR_INTERNAL_PROCESSING':
        case 'QUEUED_FOR_INTERNAL_PROCESSING':
        case 'DOING_INTERNAL_PROCESSING': {
          let nextUpdateIn = this.state.refreshIn - (this.state.lastUpdateInSeconds || 0);
          content = <p className='container-description'>
            {localeStore.intl.formatMessage({ id: 'retrievo.ui.task.details.auto-refresh'}, { seconds: nextUpdateIn })}
          </p>;
          break;
        }
        case 'FINISHED_WITH_SUCCESS':
        case 'FINISHED_WITH_ERROR': {
            let logFileUrl = configStore.actions.getApiUrl() +  '/tasks/' + task['id'] + '/log';
            let details = (!taskReport['success'] && taskReport['details'] !== "") ? <p className='container-highlight'>{taskReport['details']}</p> : undefined;
            content = <React.Fragment>
              <p className='container-description'
                 dangerouslySetInnerHTML={
                   { __html: localeStore.intl.formatMessage({ id: 'retrievo.ui.task.details.message'},{ log_file: logFileUrl })}}
              />
              {details}
            </React.Fragment>
        }
      }
      return (
        <div className='col-12'>
          {content}
        </div>
      );
    }
  }
}


TaskComponent.contextType = EntityContext;

export default withLocaleStore(
  withConfigStore(
    withRouter(
      TaskComponent
    )
  )
);
