import React, { Component } from 'react'
import get from 'lodash/get'

class TableComponent extends Component {

  constructor(props) {
    super(props);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  handleRowClick(event) {
    event.preventDefault();
    const { onRowCLick } = this.props;
    if(onRowCLick !== undefined) {
      onRowCLick(event.currentTarget.dataset.target)
    }
  }

  render() {
    const { fields, values } = this.props;
    return (
      <div className='table-responsive'>
        <table className='table table-borderless'>
          {this.renderTableHeader(fields)}
          {this.renderTableRows(fields, values)}
        </table>
      </div>
    )
  }

  renderTableHeader(fields) {
    const { fieldsLabels } = this.props;
    let headerRows = fields.map((field, pos) => {
      let useFieldsLabels = fieldsLabels === undefined ? false : fieldsLabels.length > 0;
      return <th key={'table-header ' + field}
                 className={'table-header ' + field.replace(/\./g, '-')}
                 scope='col'>{ useFieldsLabels? fieldsLabels[pos] : (field.label || field)}</th>;
    });

    return (
      <thead>
        <tr>
          { headerRows }
        </tr>
      </thead>
    );
  }

  renderTableRows(fields, values) {
    let tableRows = values.map((value, i) => {
      return this.renderTableRow(fields, value, i);
    });

    return (
      <tbody>
        { tableRows }
      </tbody>
    );

  }

  renderTableRow(fields, value, i) {
    let rowValue;
    let successRows = this.props.successRows || [];
    let warningRows = this.props.warningRows || [];
    let errorRows = this.props.errorRows || [];
    let tableRowCells = fields.map((field) => {
      let content = get(value, field, '') || '';
      switch (typeof content) {
        case 'object' :
          // only react components and/or elements have this attr
          if(content['$$typeof']) {
            rowValue = content
          } else {
            rowValue = field + '{object}';
          }
          break;
        default:
          rowValue = content;
      }
      return <td key={'table-data-' + field} className={'table-data '+ field.replace(/\./g, '-')}>
        { rowValue }
      </td>;
    });
    return (
      <tr key={'table-row-' + i}
          className={'table-row' +
          (successRows.includes(i)? ' table-success' :
            warningRows.includes(i)? ' table-warning' :
              errorRows.includes(i)? ' table-danger' : ''
          )}
          onClick={this.handleRowClick}
          data-target={value['id'] || ''}>
        { tableRowCells }
      </tr>
    );
  }

}

export default TableComponent
