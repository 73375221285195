// External Libs/Components
import React, { Component } from 'react'

// Global Components
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'
import SearchBarComponent from 'components/search/bars/SearchBarComponent'
import SearchResultsComponent from 'components/search/results/SearchResultsComponent'
import SorterComponent from 'components/search/controls/SorterComponent'

// Container Components
import DataSourceSearchResultComponent from './search/DataSourceSearchResultComponent'

// Stores
import {SearchStore, SearchContext, withSearchStore} from 'stores/SearchStore'
import { withLocaleStore } from 'stores/LocaleStore'

// CSS (css paths are the only relative paths)
import './_.css';
import BadgeFacetsComponent from "components/search/facets/BadgeFacetsComponent";

class DataSourcesComponent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const componentQueryParameters = this.props.location.search;
    this.context.actions.updateResults(componentQueryParameters);
    window.scrollTo(0,0);
  }

  componentDidUpdate(prevProps) {
    const previousQueryParameters = prevProps.location.search;
    const currentQueryParameters = this.props.location.search;

    if(previousQueryParameters !== currentQueryParameters) {
      this.context.actions.updateResults(currentQueryParameters);
    }
  }

  componentWillUnmount() {
  }

  render() {
    const { localeStore } = this.props;
    return (
      <React.Fragment>
        <SearchStore entity='data_sources' context='data_sources'>
          <NavbarComponent/>
          <section id='main-content' className='data-sources'>
            <div className='public-header'>
              <div className='public-container'>
                <div className='row'>
                  <div className='col-12'>
                    <DataSourcesHeader/>
                  </div>
                </div>
                <div className='row data-sources-refine'>
                  <div className='col-6'>
                    <BadgeFacetsComponent allowedFacets={[
                      'tags.tagsByLocale.' + localeStore.actions.getLocale().replace('-', '_'),
                      'tags.tagsByLocale.' + localeStore.actions.getLocaleWithoutRegionCode(),
                    ]}/>
                  </div>
                  <div className='col-6'>
                    <SorterComponent/>
                  </div>
                </div>
              </div>
            </div>
            <div className='container-fluid public-container'>
              <div className='row'>
                  <div className='col-12'>
                    <SearchResultsComponent SearchResultComponent={DataSourceSearchResultComponent}/>
                  </div>
              </div>
            </div>
          </section>
          <FooterComponent/>
        </SearchStore>
      </React.Fragment>
    );
  }
}

const DataSourcesHeaderComponent = class DataSourcesHeaderComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { localeStore, searchStore } = this.props;
    return (
      <React.Fragment>
        <h1 className='container-title'>
          { localeStore.intl.formatMessage({ id: 'retrievo.ui.data_sources.title'}) }
        </h1>
        <p className='container-description'>
          { localeStore.intl.formatMessage({ id: 'retrievo.ui.data_sources.description'},
            { data_sources: searchStore.data.result.total>0? searchStore.data.result.total : '' })}
        </p>
        <SearchBarComponent showAdvanced={false}/>
      </React.Fragment>
    );
  }
};

const DataSourcesHeader = withLocaleStore(withSearchStore(DataSourcesHeaderComponent));

DataSourcesComponent.contextType = SearchContext;

export default withLocaleStore(DataSourcesComponent);
