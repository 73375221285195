// External Libs/Components
import React, { Component } from 'react'
import Axios from 'axios';

// Container Components

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'
import { withSearchStore } from 'stores/SearchStore';

// CSS (css paths are the only relative paths)
import './_.css';

class RecordFederatedSearchComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      taskWithReport: null,
      federatedSearchTaskId: null,
      federatedSearchTimer: null
    };

    this.getProgress = this.getProgress.bind(this);
    this.getFederatedSearchTaskWithReport = this.getFederatedSearchTaskWithReport.bind(this);
    this.isFederatedSearchReady = this.isFederatedSearchReady.bind(this);
  }

  componentDidMount() {
    const { searchStore } = this.props;
    let federatedSearchTaskId = searchStore.data.result.federatedSearchTaskId;
    if(federatedSearchTaskId) {
      this.getFederatedSearchTaskWithReport(federatedSearchTaskId).then(() => {
        let federatedSearchTimer = setInterval(this.isFederatedSearchReady, 1000);
        this.setState({ ready: false, taskWithReport: null, federatedSearchTimer: federatedSearchTimer, federatedSearchTaskId: searchStore.data.result.federatedSearchTaskId });
      });
    }
  }

  componentDidUpdate() {
    const { searchStore } = this.props;

    if(searchStore.data.result.federatedSearchTaskId) {
      if(searchStore.data.result.federatedSearchTaskId !== this.state.federatedSearchTaskId) {
        let federatedSearchTimer = setInterval(this.isFederatedSearchReady, 1000);
        this.setState({ ready: false, taskWithReport: null, federatedSearchTimer: federatedSearchTimer, federatedSearchTaskId: searchStore.data.result.federatedSearchTaskId });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.federatedSearchTimer);
  }

  render() {
    const { localeStore, searchStore } = this.props;
    if(!searchStore.data.result.federatedSearchTaskId) {
      return '';
    } else if(!this.state.ready) {
      return (
        <div className='federated-search'>
          <span className='progress-label'>
            {this.props.message || ''} {localeStore.intl.formatMessage({id: 'retrievo.ui.records.federated_search.message.doing'},
            { sourcesCount: this.state.taskWithReport? this.state.taskWithReport.sourcesCount : '' })}
          </span>
          <div className='progress'>
            <div
              style={{width: this.getProgress() + '%'}}
              className='progress-bar progress-bar-animated progress-bar-striped'
              role='progressbar'
              aria-valuenow={this.getProgress()}
              aria-valuemin='0'
              aria-valuemax='100'/>
          </div>
        </div>
      );
    } else if(this.state.ready && this.state.taskWithReport.report.recordsValid > 0 ) {
      let currentSearch = this.props.message || '';
      let finishedMessage = localeStore.intl.formatMessage( {id: 'retrievo.ui.records.federated_search.message.finished_with_records'}, {recordsCount: this.state.taskWithReport.report.recordsValid })
      let seeMoreMessage = localeStore.intl.formatMessage({ id: 'retrievo.ui.records.federated_search.reload'});
      let message = currentSearch + ' ' + finishedMessage;
      return (
        <React.Fragment>
          <p className='federated-search' style={{ display: 'inline-block' }}>
            {message}
            <a style={{ marginLeft: '.5rem' }} href='#' className='btn btn-sm btn-primary' onClick={(event) => { event.preventDefault(); searchStore.actions.updateResults()}} >{ seeMoreMessage }</a>
          </p>
        </React.Fragment>
      );
    } else if(this.state.ready && this.state.taskWithReport.report.recordsValid < 1) {
      return (
        <React.Fragment>
          <p className='federated-search' dangerouslySetInnerHTML={{ __html: (this.props.message || '') + ' ' + localeStore.intl.formatMessage(
            {id: 'retrievo.ui.records.federated_search.message.finished_without_records'}) }} />
        </React.Fragment>
      );
    }
  }

  getProgress() {
    let total = this.state.taskWithReport? this.state.taskWithReport.sourcesCount : 1;
    let current = 0;
    if(this.state.taskWithReport) {
      for (let i = 0; i < this.state.taskWithReport.report.entries.length ; i++) {
        let entry = this.state.taskWithReport.report.entries[i];
        if(entry.recordsValid >= 0) {
          current += 1;
        }
      }
    }
    let progress = ((current / total) * 100) || 5;
    return progress === 100? 95 : progress;
  }

  isFederatedSearchReady() {
    const { searchStore } = this.props;
    this.getFederatedSearchTaskWithReport()
      .then((result)  => {
        if(result.data.state.includes('FINISHED')) {
          clearInterval(this.state.federatedSearchTimer);
          this.setState({ ready: true, taskWithReport: result.data });
          if( searchStore.data.result.total < 1 &&  result.data.report.recordsValid < 1 ) {
            searchStore.actions.clearFederatedSearch();
          }
        } else {
          this.setState({ taskWithReport: result.data });
        }
      });
  }

  getFederatedSearchTaskWithReport(federatedSearchTaskId) {
    const { configStore } = this.props;
    let url = configStore.actions.getApiUrl() + '/federated_search/' + (federatedSearchTaskId || this.state.federatedSearchTaskId);
    return Axios.get(url);
  }

}

export default withLocaleStore(
  withConfigStore(
    withSearchStore(RecordFederatedSearchComponent)
  )
);
