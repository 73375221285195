// External Libs/Components
import React, { Component } from 'react'

// Global Components
import FooterComponent from 'components/layout/FooterComponent'
import NavbarComponent from 'components/layout/NavbarComponent'
import SidebarComponent from 'components/layout/SidebarComponent'
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'
import BadgeFacetsComponent from 'components/search/facets/BadgeFacetsComponent'
import SearchResultsComponent from 'components/search/results/SearchResultsComponent'
import SearchBarComponent from 'components/search/bars/SearchBarComponent'
import SorterComponent from 'components/search/controls/SorterComponent'
import TaskSearchResultComponent from './search/TaskSearchResultComponent'

// Stores
import {SearchStore, SearchContext, withSearchStore} from 'stores/SearchStore'
import {withLocaleStore} from 'stores/LocaleStore'
import {withRouter} from 'react-router-dom'

// CSS (css paths are the only relative paths)
import './_.css'

class TasksComponent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const componentQueryParameters = this.props.location.search;
    this.context.actions.updateResults(componentQueryParameters);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const previousQueryParameters = prevProps.location.search;
    const currentQueryParameters = this.props.location.search;

    if(previousQueryParameters !== currentQueryParameters) {
      this.context.actions.updateResults(currentQueryParameters);
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <React.Fragment>
        <SearchStore entity='tasks' context='admin/tasks'>
          <NavbarComponent/>
          <section className='tasks'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-auto sidebar-container'>
                  <SidebarComponent/>
                </div>
                <div className='admin-container col'>
                  <TasksHeader/>
                  <div className='row'>
                    <div className='col-12 tasks-search-bar'>
                      <SearchBarComponent/>
                    </div>
                    <div className='col-12 tasks-search-facets'>
                      <div className='row'>
                        <div className='col-10'>
                          <BadgeFacetsComponent allowedFacets={['type', 'state']}/>
                        </div>
                        <div className='col-2'>
                          <SorterComponent/>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <SearchResultsComponent SearchResultComponent={TaskSearchResultComponent}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent/>
        </SearchStore>
      </React.Fragment>
    );
  }
}


const TasksHeaderComponent = class TasksHeaderComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { localeStore, searchStore, location } = this.props;

    let title, description, items;
    if(location.search.includes('FINISHED')) {
      items = ['tasks', 'tasks.finished'];
      title = localeStore.intl.formatMessage({ id: 'retrievo.ui.tasks.finished.title'});
      description = localeStore.intl.formatMessage({ id: 'retrievo.ui.tasks.finished.description'},
        { total_tasks: searchStore.data.result.total>0? searchStore.data.result.total : '' });
    } else if(location.search.includes('DOING')) {
      items = ['tasks', 'tasks.doing'];
      title = localeStore.intl.formatMessage({ id: 'retrievo.ui.tasks.doing.title'});
      description = localeStore.intl.formatMessage({ id: 'retrievo.ui.tasks.doing.description'},
        { total_tasks: searchStore.data.result.total>0? searchStore.data.result.total : '' });
    } else {
      items = ['tasks'];
      title = localeStore.intl.formatMessage({ id: 'retrievo.ui.tasks.title'});
      description = localeStore.intl.formatMessage({ id: 'retrievo.ui.tasks.description'},
        { total_tasks: searchStore.data.result.total>0? searchStore.data.result.total.toLocaleString() : '' });
    }

    return (
      <div className='row tasks-title'>
        <div className='col-12'>
          <BreadcrumbComponent items={ items }/>
          <h1 className='container-title'>{ title }</h1>
          <p className='container-description'>{ description }</p>
        </div>
      </div>
    )
  }
};

const TasksHeader = withLocaleStore(withSearchStore(withRouter(TasksHeaderComponent)));


TasksComponent.contextType = SearchContext;

export default withLocaleStore(withSearchStore(TasksComponent));
