// External Libs/Components
import React, { Component } from 'react'

// Global Components
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'
import SidebarComponent from 'components/layout/SidebarComponent'
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'
import SearchBarComponent from 'components/search/bars/SearchBarComponent'
import SorterComponent from 'components/search/controls/SorterComponent'
import BadgeFacetsComponent from 'components/search/facets/BadgeFacetsComponent'
import SearchResultsComponent from 'components/search/results/SearchResultsComponent'

// Container Components
import DataSourceSearchResultComponent from './search/DataSourceSearchResultComponent'

// Stores
import {SearchStore, SearchContext, withSearchStore} from 'stores/SearchStore'
import { withLocaleStore } from 'stores/LocaleStore'

// CSS (css paths are the only relative paths)
import './_.css';

class DataSourcesComponent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const componentQueryParameters = this.props.location.search;
    this.context.actions.updateResults(componentQueryParameters);
    window.scrollTo(0,0);
  }

  componentDidUpdate(prevProps) {

    const previousQueryParameters = prevProps.location.search;
    const currentQueryParameters = this.props.location.search;

    if(previousQueryParameters !== currentQueryParameters) {
      this.context.actions.updateResults(currentQueryParameters);
    }
  }

  componentWillUnmount() {
  }

  render() {
    const { localeStore } = this.props;
    return (
      <React.Fragment>
        <SearchStore entity='data_sources' context='admin/data_sources'>
          <NavbarComponent/>
          <section className='data-sources'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='sidebar-container col-auto'>
                  <SidebarComponent/>
                </div>
                <div className='admin-container col'>
                  <div className='row'>
                    <div className='col-12'>
                      <BreadcrumbComponent items={['data_sources']} active={'data_sources'}/>
                    </div>
                    <DataSourcesHeader/>
                    <div className='col-12 col-lg-10'>
                      <BadgeFacetsComponent allowedFacets={[
                        'tags.tagsByLocale.' + localeStore.actions.getLocale().replace('-', '_'),
                        'tags.tagsByLocale.' + localeStore.actions.getLocaleWithoutRegionCode(),
                      ]}/>
                    </div>
                    <div className='col-12 col-lg-2'>
                      <SorterComponent/>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 data-source-button-container'>
                      <a href='/#/admin/data_sources/create' className='data-source-button btn btn-primary'>
                        <span className='fas fa-plus'/>
                        <span>{ localeStore.intl.formatMessage( { id: 'retrievo.ui.data_sources.add_button'} ) }</span>
                      </a>
                    </div>
                    <div className='col-12'>
                      <SearchResultsComponent SearchResultComponent={DataSourceSearchResultComponent}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent/>
        </SearchStore>
      </React.Fragment>
    );
  }
}

const DataSourcesHeaderComponent = class DataSourcesHeaderComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { localeStore, searchStore } = this.props;
    return (
      <div className='col-12' style={{ marginBottom: '1em' }}>
        <h1 className='container-title'>
          { localeStore.intl.formatMessage({ id: 'retrievo.ui.data_sources.title'}) }
        </h1>
        <p className='container-description'>
          { localeStore.intl.formatMessage({ id: 'retrievo.ui.data_sources.description'},
            { data_sources: searchStore.data.result.total>0? searchStore.data.result.total : '' })}
        </p>
        <SearchBarComponent showAdvanced={false}/>
      </div>
    );
  }
};

const DataSourcesHeader = withLocaleStore(withSearchStore(DataSourcesHeaderComponent));


DataSourcesComponent.contextType = SearchContext;

export default withLocaleStore(DataSourcesComponent);
