import $ from 'jquery'
// External
import React, { Component } from 'react';
import { pageview } from 'react-ga'
import { Route} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import CookieConsent from 'react-cookie-consent'
import cssVars from 'css-vars-ponyfill'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { withConfigStore } from 'stores/ConfigStore'

class PublicComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { configStore, location } = this.props;
    if (configStore.data.loaded) {
      pageview(location.pathname + location.search);
    }
  }

  componentDidUpdate() {
    const { configStore, location } = this.props;
    if (configStore.data.loaded) {
      pageview(location.pathname + location.search);
    }
  }

  onHelmetChange() {
    cssVars({});
  }

  renderCookieConsent() {
    const { localeStore } = this.props;
    let buttonText = localeStore.intl.formatMessage({'id' : 'retrievo.ui.cookies.accept'});
    let notificationText = localeStore.intl.formatMessage({'id' : 'retrievo.ui.cookies.notification'});

    return (
      <CookieConsent buttonText={buttonText}>
        <div dangerouslySetInnerHTML={{__html: notificationText}}/>
      </CookieConsent>
    );
  }

  addDynamicJavascript() {
    const { configStore } = this.props;
    if (configStore.data.loaded) {
      const script = document.createElement('script');
      window.$ = $;
      script.async = true;
      script.innerHTML = configStore.actions.getProperty('retrievo.ui.custom_js');
      document.body.appendChild(script);
    }
  }
  
  render() {
    const { configStore, localeStore } = this.props;

    this.addDynamicJavascript();

    let pathname = this.props.location.pathname.replace('/', '').replace(/\//g, '.');
    let title =  localeStore.intl.formatMessage({ id: 'retrievo.ui.title' });
    let pageTitle = localeStore.intl.formatMessage({id: 'retrievo.ui.' + (pathname.length > 0? pathname : 'home') + '.title', defaultMessage: ' '});
    title += (pageTitle.length > 1? ' - ' + pageTitle : '' );

    return (
      <React.Fragment>
        <Helmet
          titleTemplate={title}
          defaultTitle={title}
          onChangeClientState={this.onHelmetChange()}>
          <style id='custom-public-theme' type='text/css'>{configStore.actions.getProperty('retrievo.ui.css_theme', '')}</style>
        </Helmet>
        <Route {...this.props}/>
        {this.renderCookieConsent()}
      </React.Fragment>
    );
  }
}

export default withConfigStore(withLocaleStore(PublicComponent));
