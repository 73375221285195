// External Libs/Components
import React from 'react'
import { toast } from 'react-toastify'

// Global Components
import EntityComponent from 'components/entity/EntityComponent'

// Stores
import { EntityContext } from 'stores/EntityStore'
import { withLocaleStore } from 'stores/LocaleStore'
import { withRouter } from 'react-router-dom'

class OaiPmhDataConnectorComponent extends React.Component {

  constructor(props) {
    super(props);
    this.handleCreateHarvestMediaTask = this.handleCreateHarvestMediaTask.bind(this);
    this.handleCreateHarvestTask = this.handleCreateHarvestTask.bind(this);
    this.handleCreateReIngestTask = this.handleCreateReIngestTask.bind(this);
    this.handleCreateUpdateVisibilityTask = this.handleCreateUpdateVisibilityTask.bind(this);
    this.handleUpdateSetsAvailable = this.handleUpdateSetsAvailable.bind(this);
  }

  render() {
    const { localeStore, onChange, onDelete, dataSource, dataConnector } = this.props;

    let context = 'data_sources/' + dataSource['id'] + '/data_connectors';
    let link = '/admin/' + context + '/' + dataConnector['id'];
    let sets = dataConnector['setsAvailable'] != null? dataConnector['setsAvailable']['sets'] : [];
    sets = sets.map((set)=> {
      return {
        value: set['setSpec'],
        label: set['setLabel']
      };
    });
    let entityOptions= {
      setsAvailable: {
        sets: sets
      }
    };

    let title = (
      <React.Fragment>
        <span className='fas fa-cog'/>
        { dataConnector['name']? dataConnector['name'] :
          localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.OAI_PMH.create'})
        }
      </React.Fragment>
    );

    let viewerFields = [
      'baseUrl',
      'oaiUrl',
      'oai_pmh_slug',
      'harvest',
      'harvestThumbnail',
      'harvestFulltext',
      'lastHarvest',
      'lastThumbnailHarvest',
      'lastFulltextHarvest',
      'recordsValid',
      'thumbnailsValid',
      'fulltextsValid'
    ];

    let formFields = [
      'name',
      'baseUrl',
      'oaiUrl',
      'oai_pmh_slug',
      'harvest',
      'harvestThumbnail',
      'harvestFulltext',
      'lastHarvest',
      'lastThumbnailHarvest',
      'lastFulltextHarvest',
      'waitingTimeBetweenRequestsInSeconds'
    ];

    if(dataConnector.id === undefined) {
      formFields = [
        'name',
        'baseUrl',
        'oaiUrl',
        'oai_pmh_slug',
        'harvest',
        'harvestThumbnail',
        'harvestFulltext',
        'waitingTimeBetweenRequestsInSeconds'
      ];
    }

    if(entityOptions['setsAvailable']['sets'].length > 0) {
      formFields.push('sets');
    }

    if(!dataConnector['oai_pmh_slug'] && dataConnector['software']) {
      dataConnector['oai_pmh_slug'] = dataConnector['slug'];
    }

    return (
      <div className='row data-connector'>
        <EntityComponent
          title={title}
          context={context}
          link={'/#' + link}
          edit={dataConnector.id === undefined}
          entity={dataConnector}
          entityOptions={entityOptions}
          entityType={'data_connectors'}
          visitable={link !== this.props.location.pathname}
          actions={this.renderActions()}
          viewerFields={viewerFields}
          formFields={formFields}
          onDelete={onDelete}
          onChange={onChange}
        />
      </div>
    );
  }

  renderActions() {
    const { localeStore, dataConnector } = this.props;

    return (
      <div className="dropdown">
        <button className="btn btn-sm btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className='fas fa-cog'/>
          { localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.tasks' }) }
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a href='#' className='dropdown-item' onClick={this.handleCreateHarvestTask}>
            <span className='fas fa-plus'/>
            { localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.HARVEST.create.button'}) }
          </a>
          <a href='#' className='dropdown-item' onClick={this.handleCreateHarvestMediaTask}>
            <span className='far fa-file'/>
            { localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.create.button'}) }
          </a>
          <a href='#' className='dropdown-item' onClick={this.handleCreateReIngestTask}>
            <span className='fas fa-redo'/>
            { localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.create.button'}) }
          </a>
          <a href='#' className='dropdown-item' onClick={this.handleCreateUpdateVisibilityTask}>
            {dataConnector['visible']?
              <span className='fas fa-eye-slash'/> :
              <span className='fas fa-eye'/>
            }
            {dataConnector['visible']?
              localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.hide.create.button'}) :
              localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.show.create.button'})
            }
          </a>
          <a href='#' className='dropdown-item' onClick={this.handleUpdateSetsAvailable}>
            <span className='fas fa-sync'/>
            { localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_SETS.create.button'}) }
          </a>
        </div>
      </div>
    );
  }

  handleCreateHarvestTask(event) {
    event.preventDefault();

    const { localeStore, dataConnector } = this.props;

    let task = {
      type: 'HARVEST',
      dataConnectorId: dataConnector['id'],
      dataConnectorVersion: dataConnector['version'],
      dataSourceId: dataConnector['dataSourceId']
    };

    // create task with a promise of either redirect to the created task or display an error notification
    this.context.actions.post(task, { context: 'tasks' })
      .then((result) => {
        toast.success(
          <SuccessDialog
            taskId={result.data['id']}
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.HARVEST.created.title'})}
            message={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.HARVEST.created.message'},
              { link: '/admin/tasks/'+ result.data['id'] })}
            successButton={localeStore.intl.formatMessage( { id: 'retrievo.ui.data_connector.HARVEST.created.visit'})}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.HARVEST.created.dismiss'})}
          />
        );
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.HARVEST.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(<p dangerouslySetInnerHTML={{ __html: errorMessage}}/>);
      });
  }

  handleCreateHarvestMediaTask(event) {
    event.preventDefault();

    const { localeStore, dataConnector } = this.props;
    let task = {
      type: 'FULLTEXT_THUMBNAIL',
      dataConnectorId: dataConnector['id'],
      dataConnectorVersion: dataConnector['version'],
      dataSourceId: dataConnector['dataSourceId']
    };

    this.context.actions.post(task, { context: 'tasks' })
      .then((result) => {
        toast.success(
          <SuccessDialog
            taskId={result.data['id']}
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.created.title'})}
            message={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.created.message'},
              { link: '/admin/tasks/'+ result.data['id'] })}
            successButton={localeStore.intl.formatMessage( { id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.created.visit'})}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.created.dismiss'})}
          />
        );
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.FULLTEXT_THUMBNAIL.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(<p dangerouslySetInnerHTML={{ __html: errorMessage}}/>);
      });
  }

  handleCreateReIngestTask(event) {
    event.preventDefault();

    const { localeStore, dataConnector } = this.props;

    let task = {
      type: 'REPROCESS',
      dataConnectorId: dataConnector['id'],
      dataConnectorVersion: dataConnector['version'],
      dataSourceId: dataConnector['dataSourceId']
    };

    this.context.actions.post(task, { context: 'tasks' })
      .then((result) => {
        toast.success(
          <SuccessDialog
            taskId={result.data['id']}
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.created.title'})}
            message={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.created.message'},
              { link: '/admin/tasks/'+ result.data['id'] })}
            successButton={localeStore.intl.formatMessage( { id: 'retrievo.ui.data_connector.REPROCESS.created.visit'})}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.created.dismiss'})}
          />
        );
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(<p dangerouslySetInnerHTML={{ __html: errorMessage}}/>);
      });
  }

  handleCreateUpdateVisibilityTask(event) {
    event.preventDefault();

    const { localeStore, dataConnector } = this.props;
    this.context.actions.put({}, { context: 'data_sources/' + dataConnector['dataSourceId']
      + '/data_connectors/' + dataConnector['id'] + '/visibility' },
      { visible: !dataConnector['visible'] })
      .then((result) => {
        toast.success(
          <SuccessDialog
            taskId={result.data['id']}
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.created.title'})}
            message={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.created.message'},
              { link: '/admin/tasks/'+ result.data['id'] })}
            successButton={localeStore.intl.formatMessage( { id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.created.visit'})}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_VISIBILITY.created.dismiss'})}
          />
        );
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(<p dangerouslySetInnerHTML={{ __html: errorMessage}}/>);
      });
  }

  handleUpdateSetsAvailable(event) {
    event.preventDefault();

    const { localeStore, dataConnector, onChange } = this.props;

    this.context.actions.put({}, { context: 'data_sources/' + dataConnector['dataSourceId']
        + '/data_connectors/' + dataConnector['id'] + '/sets' })
      .then((result) => {
        onChange(result);
        toast.success(
          <SuccessDialog
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_SETS.created.title'})}
            message={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_SETS.created.message'})}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.UPDATE_SETS.created.dismiss'})}
          />
        );
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(<p dangerouslySetInnerHTML={{ __html: errorMessage}}/>);
      })
  }

}

const SuccessDialog = ({ closeToast, title, message, taskId, successButton, cancelButton}) => (
  <div className='csv-file-submission'>
    <h2 className='toast-title' dangerouslySetInnerHTML={{__html : title}}/>
    <div className='toast-body'>
      <span className='toast-message' dangerouslySetInnerHTML={{__html : message}}/>
    </div>
    <div className='toast-actions'>
      { successButton ? <a href={'/#/admin/tasks/' + taskId} className='btn btn-primary' style={{marginRight: '.2rem'}}>{successButton}</a> : '' }
      { cancelButton ? <button className='btn btn-outline-primary' onClick={closeToast}>{cancelButton}</button> : '' }

    </div>
  </div>
);



OaiPmhDataConnectorComponent.contextType = EntityContext;



export default withRouter(
  withLocaleStore( OaiPmhDataConnectorComponent )
);
