import React, { Component } from 'react';
import moment from 'moment';

import SearchResultComponent from 'components/search/results/SearchResultComponent';

import { withConfigStore } from 'stores/ConfigStore';
import { withLocaleStore } from 'stores/LocaleStore';

const DEFAULT_DISPLAY_FIELDS = ['metadataEnhanced.titles', 'metadataEnhanced.descriptions', 'metadataEnhanced.creators', 'metadataEnhanced.dates'];
const DEFAULT_EXPANDED_FIELDS = ['metadataEnhanced.types', 'metadataEnhanced.rights'];
const DEFAULT_DESCRIPTION_FIELD = 'metadataEnhanced.descriptions';


class RecordSearchResultComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.renderActions = this.renderActions.bind(this);
    moment.locale(props.localeStore.actions.getLocale());
  }

  render() {
    const { configStore } = this.props;

    let configuredDisplayFields = configStore.actions.getProperty('retrievo.ui.result.records.display.fields');
    let configuredExpandedFields = configStore.actions.getProperty('retrievo.ui.result.records.expanded.fields');
    let configuredDescriptionField = configStore.actions.getProperty('retrievo.ui.result.records.description.field');

    let displayFields = configuredDisplayFields? configuredDisplayFields : DEFAULT_DISPLAY_FIELDS;
    let expandedFields = configuredExpandedFields? configuredExpandedFields : DEFAULT_EXPANDED_FIELDS;
    let descriptionField = configuredDescriptionField? configuredDescriptionField : DEFAULT_DESCRIPTION_FIELD;

    return (
      <SearchResultComponent
        {...this.props}
        renderActions={this.renderActions}
        displayFields={displayFields}
        expandedFields={expandedFields}
        descriptionField={descriptionField}
      />
    );
  }

  renderActions(result) {
    const { localeStore } = this.props;
    if(result.metadataEnhanced['link'] !== undefined) {
      if(result.metadataEnhanced['link'].length > 0) {
        return <li>
          <a onClick={event => {event.stopPropagation()}} href={result.metadataEnhanced['link'][0]} target='_blank' rel='noopener noreferrer' title={localeStore.intl.formatMessage({ id: 'retrievo.ui.records.show-original' }) }>
            <span className='fas fa-external-link-alt'/>
            <span className='d-inline-block d-sm-none'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.records.show-original' })}
            </span>
          </a>
        </li>;
      }
    }
  }
}

export default withLocaleStore(withConfigStore(RecordSearchResultComponent));
