import React, { Component } from 'react'
import { withSearchStore } from '../../../stores/SearchStore'
import { withConfigStore } from '../../../stores/ConfigStore'

import FacetComponent from './FacetComponent'
import SelectedFacetsComponent from './SelectedFacetsComponent'

import './_.css'

const CONFIG_BASE_KEY = "retrievo.ui.search.facets.";
const COLLAPSED_SUFFIX = ".collapsed";
const COUNTERS_KEY = CONFIG_BASE_KEY + "counters";

class FacetsComponent extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {this.renderSelectedFacets()}
        {this.renderFacets()}
      </React.Fragment>
    );
  }

  isThereFacetsSelected() {
    const { searchStore } = this.props;
    let res = false;
    for(let i=0; i < searchStore.data.request.facets.length && !res; i++) {
      let facet = searchStore.data.request.facets[i];
      if(facet.selectedValues.length > 0) {
        res = true;
      }
    }
    return res;
  }

  renderSelectedFacets() {
    const { searchStore } = this.props;
    if(this.isThereFacetsSelected()) {
      return (
        <SelectedFacetsComponent
          resultFacets={ searchStore.data.result.facets }
          requestFacets={ searchStore.data.request.facets }
          onSubmit={ searchStore.actions.updateResults }
          onClear={ searchStore.actions.toggleFacet }
          onClearAll={ searchStore.actions.clearFacets }
          entityType={searchStore.actions.getEntity()}
        />
      )
    }
  }

  renderFacets() {
    const { searchStore, configStore } = this.props;
    const allowedFacets = this.props.allowedFacets || configStore.actions.getProperty(CONFIG_BASE_KEY + searchStore.actions.getEntity(), []);
    const selectedFacets = searchStore.data.request.facets;
    if(searchStore.data.result.total > 0 || selectedFacets.length > 0 ) {
      return allowedFacets.map((allowedFacetKey) => {
        if(searchStore.data.result.facets.some(this.containsFacet, allowedFacetKey)) {
          let facet = searchStore.data.result.facets.find(this.containsFacet, allowedFacetKey);
          let selected;
          for (let i = 0; i < selectedFacets.length ; i++) {
            if(selectedFacets[i]['field'] === facet.field) {
              selected = selectedFacets[i];
            }
          }
          return (
            <FacetComponent
              key={facet.field}
              facet={facet}
              selected={selected}
              onToggle={searchStore.actions.toggleFacet}
              onExpand={searchStore.actions.updateFacetLimitAndResults}
              showCounters={configStore.actions.getProperty(COUNTERS_KEY)}
              collapsed={configStore.actions.getProperty(CONFIG_BASE_KEY + searchStore.actions.getEntity() + '.' + facet.field + COLLAPSED_SUFFIX)}
              entityType={searchStore.actions.getEntity()}
            />
          );
        }
      });
    }
  }

  containsFacet(facet) {
    return facet.field === this;
  }

}

export default withSearchStore(
  withConfigStore(FacetsComponent)
);
