// External Libraries
import React, { Component } from 'react';

// Components
import MultiSelectDropdownComponent from 'components/global/elements/MultiSelectDropdownComponent';

// Stores
import { withLocaleStore } from 'stores/LocaleStore';

// CSS
import './_.css'

class SimpleSearchBarComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dynamicPlaceholder: this.props.dynamicPlaceholder
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.dynamicPlaceholder !== this.props.dynamicPlaceholder) {
      this.setState({dynamicPlaceholder: this.props.dynamicPlaceholder})
    }
  }

  filterToTypeAheadOption(field, pos) {
      let fieldCopy = Object.assign({}, field);
      fieldCopy['id'] = field.field + '-' + pos;
      fieldCopy['customOption'] = true;
      if(!field.field.startsWith('_all')) {
        fieldCopy['label'] = field.label + ': ' + field.value;
      } else {
        fieldCopy['label'] = field.value;
      }
      return fieldCopy;
  }

  newTypeAheadOption(filter) {
    return {
      id: filter.id,
      key: '_all',
      field: '_all',
      label: 'Any: ' + filter.value,
      value: filter.value,
      operator: 'EQUALS'
    }
  }

  onSubmit(event) {
    const { onSubmit } = this.props;
    onSubmit(event);
  }

  onChange(selectedFilters, submit = false) {
    let { onChange, onChangeAndSubmit } = this.props;
    if(onChange != null) {
      let sf = selectedFilters.map((filter) => {
        if(filter.field === undefined) {
          return this.newTypeAheadOption(filter);
        } else {
          return this.filterToTypeAheadOption(filter);
        }
      });
      if(submit) {
        onChangeAndSubmit(sf);
      } else {
        onChange(sf);
      }

    }
  }

  render() {
    const { localeStore } = this.props;
    const selectedFields = this.props.fields.map(this.filterToTypeAheadOption);
    return (
      <React.Fragment>
        <div className='form-row'>
          <div className='col search-bar-multi-select'>
            <MultiSelectDropdownComponent
              labelKey={'label'}
              placeholder={this.state.dynamicPlaceholder || this.props.placeholder}
              selectedOptions={selectedFields}
              onChange={this.onChange}
              onSubmit={this.onSubmit}
            />
          </div>
          <div className='col-auto search-bar-submit search-bar-simple-submit'>
            <button type='submit' onClick={this.onSubmit} className='btn btn-lg btn-primary'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                <g id="search-icon">
                  <path id="Path_2"
                        d="M16.215 14.629h-.835l-.3-.285a6.882 6.882 0 1 0-.74.74l.285.3v.835l5.286 5.275 1.575-1.575zm-6.343 0a4.757 4.757 0 1 1 4.757-4.757 4.751 4.751 0 0 1-4.757 4.757z"
                        className="cls-1" data-name="Path 2"/>
                  <path id="Path_3" d="M0 0h25v25H0z" className="cls-2" data-name="Path 3"/>
                </g>
              </svg>
              <span className='btn-text'>{ localeStore.intl.formatMessage({id: 'retrievo.ui.search.button'}) }</span>
            </button>
          </div>
        </div>
      </React.Fragment>

  );
  }

}

export default withLocaleStore(SimpleSearchBarComponent);

