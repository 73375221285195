import React from 'react'
import random from 'lodash/random'
import isEqual from 'lodash/isEqual'
import { Typeahead } from 'react-bootstrap-typeahead'
import { withLocaleStore } from 'stores/LocaleStore'

class MultiSelectDropdownComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getStateFromProps(props);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getStateFromProps(props) {
    return {
      placeholder: props.placeholder || '',
      labelKey: props.labelKey || 'label',
      allowNew: props.allowNew || true,
      options: (props.options || []).map( this.getItem ),
      selectedOptions: (props.selectedOptions || []).map(this.getItem),
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.removeInlineStyles();
    let currState = this.getStateFromProps(this.props);
    if(currState.selectedOptions != null) {
      if(!isEqual(prevState.selectedOptions.length, currState.selectedOptions.length)) {
        this.setState(currState);
      } else if(prevState.placeholder !== currState.placeholder) {
        this.setState(currState);
      }
    }
  }

  removeInlineStyles() {
    let rbtHintContainerElements = document.querySelector('.rbt-input-multi .rbt-input-wrapper .rbt-input-hint-container div').querySelectorAll('div');
    for (let i = 0; i < rbtHintContainerElements.length; i++) {
      rbtHintContainerElements[i].style.removeProperty('font-size');
    }

    let rbtHintInputElement = document.querySelector('.rbt-input-multi .rbt-input-wrapper .rbt-input-hint-container .rbt-input-hint').childNodes;
    for (let i = 0; i < rbtHintInputElement.length; i++) {
      rbtHintInputElement[i].style.removeProperty('font-size');
    }

    let rbtInputMain = document.querySelector('.rbt-input-main');
    rbtInputMain.style.removeProperty('width');
  }

  getItem(item) {
    if(typeof item === 'object' ) {
      return {
        id: random(1000000, 9999999),
        label: item['label'] || item,
        value: item['value'] || item,
        ...item
      };
    } else {
      return {
        id: random(1000000, 9999999),
        label: item['label'] || item,
        value: item['value'] || item,
      };
    }
  }

  onChange(event, submit) {
    const { onChange } = this.props;
    let shouldSubmit = submit !== undefined? submit : event !== undefined;
    let selectedOptions = this.state.selectedOptions;
    if(event != null) {
      if(event.length > selectedOptions.length) {
        let value = event.pop();
        selectedOptions = [...selectedOptions, this.getItem(value.label)];
      } else {
        selectedOptions = event;
      }
      this.setState({ selectedOptions: selectedOptions});
    }

    if(onChange != null) {
      onChange(selectedOptions, shouldSubmit);
    }
  }

  onKeyDown(event) {
    let { selectedOptions } = this.state;
    const value = event.target.value;
    const element = this.typeahead.getInstance();
    // if no value was introduced by the user
    if(!value.length && event.key === 'Enter') {
      event.preventDefault();
      this.onChange(null, true);
    } else if(value.length && event.key === 'Tab') {
      event.preventDefault();
      element.setState({ text: '' });
      this.setState({selectedOptions: [...selectedOptions, this.getItem(value)]}, () => {
        this.onChange()
      });
    } else if(value.length && event.key === 'Enter') {
      event.preventDefault();
      element.setState({ text: '' });
      this.setState({selectedOptions: [...selectedOptions, this.getItem(value)]}, () => {
        this.onChange(null, true)
      });
    }
  }


  render() {
    const { localeStore } = this.props;
    let newSelectionPrefix = localeStore.intl.formatMessage({ id: 'retrievo.ui.search.suggestion' });
    let emptyLabel = localeStore.intl.formatMessage({ id: 'retrievo.ui.search.no_match' });
    return (
      <div className='form-group dropdown multi-selected'>
        <Typeahead
          id='main-search'
          ref={(typeahead) => this.typeahead = typeahead}
          multiple
          allowNew={this.state.allowNew}
          clearButton
          selectHintOnEnter
          newSelectionPrefix={newSelectionPrefix + " "}
          emptyLabel={emptyLabel}
          bsSize='large'
          options={this.state.options}
          selected={this.state.selectedOptions}
          placeholder={this.state.placeholder}
          labelKey={this.state.labelKey}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          inputProps={{title: this.state.placeholder}}
        />
      </div>
    );
  }
}

export default withLocaleStore(MultiSelectDropdownComponent);
