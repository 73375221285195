// External Libs
import React from 'react';
import Axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import qs from 'query-string';
import { toast } from 'react-toastify';

// Stores
import { withRouter } from 'react-router-dom'
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from "stores/LocaleStore";

const initialState = {
  data: {
    request: {
      filters: [],
      facets: [],
      sort_by: [],
      projection: [],
      page: 1,
      per_page: undefined,
    },
    result: {
      items : [],
      facets : [],
      total: 0,
      error : undefined,
      federatedSearchTaskId: null,
    }
  },
  actions: {},
  loading: false
};

const SearchContext = React.createContext(initialState);
const SearchStoreProvider = SearchContext.Provider;
const SearchStoreConsumer = SearchContext.Consumer;

class SearchStoreComponent extends React.Component {
  constructor(props) {
    super(props);

    initialState.data.request.per_page = this.props.configStore.actions.getProperty('retrievo.ui.search.perpage.' + this.getEntity() +'.default');
    initialState.data.request.projection = this.props.configStore.actions.getProperty('retrievo.ui.search.projection.' + this.getEntity() + '.options') || [];
    initialState.actions = {
      getContext: this.getContext.bind(this),
      getEntity: this.getEntity.bind(this),
      getQueryParametersFromState: this.getQueryParametersFromState.bind(this),
      setStateFromQueryParameters: this.setStateFromQueryParameters.bind(this),
      updateResults: this.updateResults.bind(this),
      updatePage: this.updatePage.bind(this),
      updatePerPage: this.updatePerPage.bind(this),
      updateSorter: this.updateSorter.bind(this),
      updateFilter: this.updateFilter.bind(this),
      updateFilters: this.updateFilters.bind(this),
      updateFiltersAndResults: this.updateFiltersAndResults.bind(this),
      updateProjectionFields: this.updateProjectionFields.bind(this),
      toggleFacet: this.toggleFacet.bind(this),
      updateRankingFacetsAndResults: this.updateRankingFacetsAndResults.bind(this),
      updateFacetLimitAndResults: this.updateFacetLimitAndResults.bind(this),
      clearFacets: this.clearFacets.bind(this),
      clearFederatedSearch: this.clearFederatedSearch.bind(this)
    };
    this.redirectToResults = this.redirectToResults.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
    this.state = initialState;
  }

  getState() {
    return cloneDeep(this.state);
  }

  render() {
     return (
      <SearchStoreProvider value={this.state}>
        {this.props.children}
      </SearchStoreProvider>
    );
  }

  dispatch(action, args, callback) {
    let state = this.stateManager(this.getState(), action, args);
    this.setState(state, callback);
    // console.log('[Store: SearchStore]:[Action: ' + action + ']', state.data, 'Loading: ' + state.loading);
  }

  stateManager(state, action, args) {
    switch (action) {
      case 'SET_REQUEST':
        return {
          ...state,
          data: {
            ...state.data,
            request: args.request
          },
          loading: true
        };
      case 'SET_RESULT':
        return {
          ...state,
          data: {
            ...state.data,
            result: args.result,
          },
          loading: false
        };
      case 'SET_PAGE':
        return {
          ...state,
          data: {
            ...state.data,
            request: {
              ...state.data.request,
              page: args.page
            },
          }
        };
      case 'SET_PER_PAGE':
        return {
          ...state,
          data: {
            ...state.data,
            request: {
              ...state.data.request,
              per_page: args.perPage,
              page: 1
            }
          }
        };
      case 'SET_FILTERS' :
        return {
          ...state,
          data : {
            ...state.data,
            request: {
              ...state.data.request,
              filters: args.filters,
              page: 1
            }
          }
        };
      case 'SET_SORTER':
        return {
          ...state,
          data : {
            ...state.data,
            request: {
              ...state.data.request,
              sort_by: args.sorter
            }
          }
        };
      case 'SET_PROJECTION':
        return {
          ...state,
          data : {
            ...state.data,
            request: {
              ...state.data.request,
              projection: args.projection
            }
          }
        };
      case 'TOGGLE_FACET': // turn on and off base on the current state
        return {
          ...state,
          data: {
            ...state.data,
            request: {
              ...state.data.request,
              facets: args.requestFacets,
              page: 1
            }
          }
        };
      case 'SET_FACET_LIMIT':
        return {
          ...state,
          data: {
            ...state.data,
            request: {
              ...state.data.request,
              facets: args.requestFacets
            }
          }
        };
      case 'SET_EMPTY_FACET_LIMIT':
        return {
          ...state,
          data: {
            ...state.data,
            request: {
              ...state.data.request,
              facets: args.requestFacets,
              per_page: 0
            }
          }
        };
      case 'CLEAR_ALL_FACETS':
        return {
          ...state,
          data: {
            ...state.data,
            request: {
              ...state.data.request,
              facets: [],
              page: 1
            }
          }
        };
      case 'CLEAR_FEDERATED_SEARCH':
        return {
          ...state,
          data: {
            ...state.data,
            result: {
              ...state.data.result,
              federatedSearchTaskId: null
            },
          },
          loading: false
        };
      case 'SET_LOADING':
        return {
          ...state,
          data: {
            ...state.data,
            result: {
              ...state.data.result,
              items : [],
              total: 0
            }
          },
          loading: true
        };
      case 'SET_ERROR':
        return {
          ...state,
          data: {
            ...state.data,
            result: {
              items: [],
              facets: [],
              total: 0,
              err: args.error
            },
          },
          loading: false
        };
      default:
        return state;
    }
  }

  getContext() {
    return this.props.context;
  }

  getEntity() {
    return this.props.entity;
  }

  /* ACTIONS */
  clearFederatedSearch() {
    this.dispatch('CLEAR_FEDERATED_SEARCH');
  }

  clearFacets() {
    this.dispatch('CLEAR_ALL_FACETS', {}, this.updateResults);
  }

  toggleFacet(active, field, selectedValue, type) {
    let requestFacets = this.getState().data.request.facets;

    if(active) {
      switch (type) {
        case 'SimpleFacetValue':
          requestFacets.some(this.containsFacet, field) ?
            requestFacets.find(this.containsFacet, field).selectedValues.push(selectedValue) :
            requestFacets.push( { 'field' : field, 'selectedValues' : [selectedValue] } );
          break;
        case 'RangeFacetValue' :
          requestFacets.some(this.containsFacet, field) ?
            requestFacets.find(this.containsFacet, field).selectedValues = selectedValue :
            requestFacets.push( { 'field' : field, 'selectedValues' : selectedValue } );
          break;
        case 'BooleanFacetValue' :
          requestFacets.some(this.containsFacet, field)?
            requestFacets.find(this.containsFacet, field).selectedValues = [selectedValue] :
            requestFacets.push({ 'field' : field, 'selectedValues' : [selectedValue] });
          break;
      }
    } else {
      if(Array.isArray(selectedValue)) {
        requestFacets.splice(requestFacets.findIndex(this.containsFacet, field));
      } else {
        let foundFacet = requestFacets.find(this.containsFacet, field);

        foundFacet.selectedValues.length > 1 ?
          foundFacet.selectedValues.splice(foundFacet.selectedValues.indexOf(selectedValue), 1) :
          requestFacets.splice(requestFacets.findIndex(this.containsFacet, field), 1);
      }
    }
    this.dispatch('TOGGLE_FACET', { requestFacets: requestFacets}, this.updateResults);
  }

  containsFacet(requestFacet) {
    return requestFacet.field === this;
  }

  updateFacetLimitAndResults(field, limit) {
    let found = false;
    let requestFacets = this.getState().data.request.facets;

    for (let i = 0; i < requestFacets.length; i++) {
      if(requestFacets[i].field === field) {
        requestFacets[i]['limit'] = limit;
        found = true;
      }
    }

    if(!found) {
      requestFacets.push({field : field, selectedValues: [], limit: limit})
    }

    this.dispatch('SET_FACET_LIMIT', {requestFacets: requestFacets}, this.updateResults);
  }

  updateRankingFacetsAndResults(requestFacets) {
    this.dispatch('SET_EMPTY_FACET_LIMIT', {requestFacets: requestFacets}, () => {
      this.updateResults(undefined, false)
    });
  }

  updatePage(page) {
    this.dispatch('SET_PAGE', { page: page }, this.updateResults);
  }

  updatePerPage(perPage) {
    this.dispatch('SET_PER_PAGE', { perPage: perPage}, this.updateResults);
  }

  updateSorter(sorter) {
    let sort_by = [];
    sort_by.push(sorter);
    this.dispatch('SET_SORTER', { sorter: sort_by }, this.updateResults);
  }

  updateProjectionFields(projectionField) {
    let currentProjectionFields = this.getState().data.request.projection;
    if(currentProjectionFields.includes(projectionField)) {
      currentProjectionFields.splice(currentProjectionFields.indexOf(projectionField), 1);
    } else {
      currentProjectionFields.push(projectionField);
    }

    this.dispatch('SET_PROJECTION', { projection: currentProjectionFields });
  }

  updateResults(optionalQueryParameters, optionalRedirect) {
    const { configStore } = this.props;
    let redirect = optionalRedirect !== undefined ? optionalRedirect : this.props.redirect;
    let queryParameters = optionalQueryParameters !== undefined? optionalQueryParameters : this.getQueryParametersFromState();
    if(redirect === undefined && this.props.location.search !== queryParameters) {
      // no redirect defined but there are changes via url
      this.redirectToResults();
    } else if(redirect) {
      // if redirect is set to true reset page/per_page values
      let perPage = configStore.actions.getProperty('retrievo.ui.search.perpage.' + this.getEntity() + '.default');
      this.dispatch('SET_PER_PAGE', { perPage: perPage }, () => {
        this.dispatch('SET_PAGE', {page: 1}, this.redirectToResults)
      });
    } else {
      // refresh/update results.. redirect or URL changes will also trigger this but after URL is updated with success
      let request = this.setStateFromQueryParameters(queryParameters);
      this.dispatch('SET_LOADING', {}, () => {
        this.dispatch('SET_REQUEST', { request: request }, () => {
          this.getSearchResults(this.getQueryParametersFromState())
            .then((result) => {
              this.dispatch('SET_RESULT', { result: result.data });
              return result;
            })
            .catch((error) => {
              this.dispatch('SET_ERROR', { error });
            })
        });
      });
    }
  }

  redirectToResults() {
    let queryParameters = this.getQueryParametersFromState();
    this.props.history.push({
      pathname: '/' + this.getContext(),
      search: queryParameters
    });
  }

  updateFilter(filter, toRemove = false) {
    let found = false;
    let selectedFilters = this.getState().data.request.filters;
    if(filter == null && toRemove === true) {
      selectedFilters = [];
    } else {
      for (let i = 0; i < selectedFilters.length; i++) {
        if(selectedFilters[i].id === filter.id
          || ( selectedFilters[i].id === undefined && selectedFilters[i].field === filter.field && selectedFilters[i].value === filter.value))  {
          if(toRemove) {
            selectedFilters.splice(i, 1);
          } else {
            selectedFilters[i].field = filter.field;
            selectedFilters[i].value = filter.value;
            selectedFilters[i].operator = filter.operator;
          }
          found = true;
          break;
        }
      }
      if(!found && !toRemove) {
        selectedFilters.push(filter);
      }
    }

    if(!toRemove) {
      this.dispatch('SET_FILTERS', { filters: selectedFilters});
    } else {
      this.dispatch('SET_FILTERS', { filters: selectedFilters}, this.updateResults);
    }

  }

  updateFilters(filters) {
    this.dispatch('SET_FILTERS', { filters: filters});
  }

  updateFiltersAndResults(filters) {
    this.dispatch('SET_FILTERS', { filters: filters}, this.updateResults);
  }

  getQueryParametersFromState() {
    const request = this.getState().data.request;

    let pageParameters = {
      'page' : request.page,
      'per_page' : request.per_page
    };

    let filtersParameters = request.filters.reduce( (result, filter, i) => {
      if(filter.value !== undefined) {
        let key = 'filters.' + i +'.' + filter.field + '.' + filter.operator.toLowerCase();
        if(Array.isArray(filter.value)) {
          result[key] = filter.value;
        } else {
          if(filter.value.length > 0) {
            result[key] !== undefined ? result[key].push(filter.value) : result[key] = [filter.value]
          }
        }
      }
      return result
    }, {});

    let facetsParameters = request.facets.reduce( (result, facet) => {
      result['facets.' + facet.field + '.selected'] = facet.selectedValues;
      result['facets.' + facet.field + '.limit'] = facet.limit;
      return result;
    }, {});


    let query = '?' + qs.stringify({
      ...pageParameters,
      ...filtersParameters,
      ...facetsParameters,
      'sort_by' : request.sort_by
    }, {sort: false});
    return query;
  }

  setStateFromQueryParameters(queryParameters) {
    const { configStore } = this.props;
    let request = this.getState().data.request;
    let queryParametersObject = qs.parse(queryParameters);

    request['page'] = 1;
    request['per_page'] = configStore.actions.getProperty('retrievo.ui.search.perpage.' + this.getEntity() + '.default');
    // request['projection'] = request.projection;
    request['sort_by'] =  [configStore.actions.getProperty('retrievo.ui.search.sorter.' + this.getEntity() + '.default')];
    request['facets'] = [];
    request['filters'] = [];

    Object.keys(queryParametersObject).map(key => {
      if(key === 'page') {
        request['page'] = parseInt(queryParametersObject[key]);
      } else if(key === 'per_page') {
        request['per_page'] = parseInt(queryParametersObject[key]);
      } else if(key === 'sort_by') {
        request['sort_by'] = [queryParametersObject[key]];
      }
      else if(key.startsWith('facets.') && key.endsWith('.selected')) {
        let facet = {};
        facet['field'] = key.replace('facets.', '').replace('.selected', '');
        facet['selectedValues'] = queryParametersObject[key] instanceof Array ? queryParametersObject[key] : [queryParametersObject[key]];
        request.facets.push(facet);
      } else if(key.startsWith('facets.') && key.endsWith('.limit')) {
        let found = false;
        let field = key.replace('facets.', '').replace('.limit', '');
        for (let i = 0; i < request['facets'].length; i++) {
          if(request['facets'][i].field === field) {
            request['facets'][i].limit = queryParametersObject[key];
            found = true;
          }
        }
        if(!found) {
          request['facets'].push({field : field, selectedValues: [], limit: queryParametersObject[key]});
        }
      } else if(key.startsWith('filters.')) {
        let tempKey = key.replace(/filters\.\d+\./g, '');
        let parts = tempKey.split('.');
        let operator = parts.pop().toUpperCase();
        let field = parts.join('.');
        if(queryParametersObject[key] instanceof Array) {
          request['filters'].push({field: field, operator: operator,  value: queryParametersObject[key] });
        } else {
          request['filters'].push({field: field, operator: operator,  value: queryParametersObject[key] });
        }
      }
      return key;
    });

    return request;
  }

  /* REQUESTS  */
  getSearchResults(queryParameters) {
    const { configStore, localeStore } = this.props;
    let url = configStore.actions.getApiUrl() + '/search/' + this.getEntity() + queryParameters.replace(/filters\.\d+\./g, 'filters.');
    return Axios.get(url).catch((error) => {
      let status = error.response.status;
      if(status === 405) {
        toast.error(localeStore.intl.formatMessage({ id: 'retrievo.ui.license.not_licensed' }));
      }
    });
  }

}

// Providing context as higher-order-component
// https://reactjs.org/docs/context.html#consuming-context-with-a-hoc
function withSearchStore(Component) {
  return function searchStore(props) {
    return(
      <SearchStoreConsumer>
        { (searchStore) => <Component {...props} searchStore={searchStore} /> }
      </SearchStoreConsumer>
    )
  }
}

const SearchStore = withRouter(withConfigStore(withLocaleStore(SearchStoreComponent)));

export { SearchStore, withSearchStore, SearchContext }
