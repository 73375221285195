// External Libs/Components
import React, { Component } from 'react'
import { File } from 'react-kawaii'

// Components
import PaginationComponent from '../../layout/PaginationComponent'
import DropdownComponent from '../../global/elements/DropdownComponent'
import SpinnerComponent from '../../global/misc/SpinnerComponent'

// Containers
import GenericSearchResultComponent from './SearchResultComponent'
import RecordFederatedSearchComponent from 'containers/public/record/RecordFederatedSearchComponent';

// Stores
import { withConfigStore } from '../../../stores/ConfigStore'
import { withLocaleStore } from '../../../stores/LocaleStore'
import { withSearchStore } from '../../../stores/SearchStore'

// CSS (css paths are the only relative paths)
import './_.css'

class SearchResultsComponent extends Component {

  constructor(props) {
    super(props);
    let { configStore, searchStore } = this.props;

    this.state = {
      perPageOptions : configStore.actions.getProperty('retrievo.ui.search.perpage.' + searchStore.actions.getEntity() + '.options')
    };
    // render methods
    this.renderSearchResults = this.renderSearchResults.bind(this);
  }

  renderLoading() {
    const { searchStore } = this.props;
    if(searchStore.loading) {
      let isFacetsRendered = this.isThereFacetsSelected()?  true
        : searchStore.data.result.total > 0? searchStore.data.result.facets.length > 0 : false ;
      return <SpinnerComponent fixed={!isFacetsRendered} />
    }
  }

  isThereFacetsSelected() {
    const { searchStore } = this.props;
    let res = false;
    for(let i=0; i < searchStore.data.request.facets.length && !res; i++) {
      let facet = searchStore.data.request.facets[i];
      if(facet.selectedValues.length > 0) {
        res = true;
      }
    }
    return res;
  }

  renderEmptySearch() {
    const { localeStore, searchStore } = this.props;
    if(searchStore.data.result.federatedSearchTaskId) {
      return <RecordFederatedSearchComponent/>
    } else {
      return (
        <div className='row justify-content-left no-results'>
          <div className='col-auto no-results-image'>
            <File
              size={72}
              color='#8bd1b8'
              mood='ko'
            />
          </div>
          <div className='col-auto no-results-text'>
            <h2>{ localeStore.intl.formatMessage( { id: 'retrievo.ui.search.no-results.title' }) }</h2>
            <p>{ localeStore.intl.formatMessage( { id: 'retrievo.ui.search.no-results.description' }) }</p>
          </div>
        </div>
      );
    }
  }

  renderSearchResults() {
    const {searchStore, SearchResultComponent} = this.props;
    if (searchStore.data.result.items.length === 0 && !searchStore.loading) {
      return this.renderEmptySearch();
    } else {
      return SearchResultComponent !== undefined ?
        <SearchResultComponent
          results={searchStore.data.result.items}
          projectionFields={searchStore.data.request.projection}
          context={searchStore.actions.getContext()}
          entity={searchStore.actions.getEntity()}
        /> :
        <GenericSearchResultComponent
          results={searchStore.data.result.items}
          projectionFields={searchStore.data.request.projection}
          context={searchStore.actions.getEntity()}/>;
    }
  }

  renderControls() {
    const { localeStore, searchStore } = this.props;
    return (
      <div className='search-results-controls row'>
        <div className='search-pagination col-12 col-sm-6'>
          <PaginationComponent
            currentPage={searchStore.data.request.page}
            perPage={searchStore.data.request.per_page}
            total={searchStore.data.result.total}
            onChange={searchStore.actions.updatePage}
          />
        </div>
        <div className='search-per-page-results col-12 col-sm-6'>
          <label htmlFor={'per-page'}>
            {localeStore.intl.formatMessage({ 'id' : 'retrievo.ui.search.perpage'})}
          </label>
          <DropdownComponent
            selected={searchStore.data.request.per_page}
            options={this.state.perPageOptions}
            onChange={searchStore.actions.updatePerPage} />
        </div>
      </div>
    );
  }

  render() {
    const { searchStore } = this.props;
    const renderSearchResults = searchStore.data.result.items.length > 0 ;
    return (
      <React.Fragment>
        <div className='search-results'>
          {this.renderLoading()}
          {this.renderSearchResults()}
        </div>
        { renderSearchResults ? this.renderControls() : ''}
      </React.Fragment>
  );
  }

}

export default withConfigStore(
  withLocaleStore(
    withSearchStore(
      SearchResultsComponent
    )
  )
);
