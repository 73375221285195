import React, { Component } from 'react';

import randomColor from 'randomcolor';

// stores
import { withConfigStore } from 'stores/ConfigStore';
import { withLocaleStore } from 'stores/LocaleStore';

const blendModes = [
  "normal",
  "multiply",
  "screen",
  "overlay",
  "lighten",
  "hard-light",
  "soft-light",
  "hue",
  "color"
];

class CardSearchResultComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };

    this.cardRefs = {};
  }

  UNSAFE_componentWillUpdate(nextProps) {
    nextProps.results.map((result) => {
      this.cardRefs[result.id] = {
        seeMore: React.createRef(),
        descriptionRef: React.createRef(),
      };
    });
  }

  generateGradient() {
    let gradient = "",
      mode = "";
    for (let i = 0; i < 4; i++) {
      const deg = Math.floor(Math.random() * 180);
      const firstColor = randomColor({
        luminosity: "bright",
        hue: "green",
        format: "rgba",
        alpha: 0.1
      });
      const lastColor = randomColor({
        luminosity: "light",
        hue: "blue",
        format: "rgba",
        alpha: 0.05
      });
      const firstSpread = Math.floor(Math.random() * 90);
      const lastSpread = Math.floor(Math.random() * 180);
      mode += `${blendModes[Math.floor(Math.random() * blendModes.length)]},`;
      gradient += `linear-gradient(${deg}deg, ${firstColor} ${firstSpread}%, ${lastColor} ${lastSpread}%),`;
    }
    return { backgroundImage: `${gradient.slice(0,-1)}`, backgroundBlendMode: `${mode.slice(0, -1)}`};
  }

  render() {
    const { configStore, results } = this.props;
    let cards = results.map((result, i) => {
    let thumbnailUrl = configStore.actions.getApiUrl() + '/search/data_sources/' + result['id'] + '/thumbnail';
      return (
        <div key={'card-search-result-' + i} className='col-12 col-md-6 col-lg-6 col-xl-4'>
          <div className='card search-result'>
            <div className='card-image' style={ !result['thumbnail']? this.generateGradient() : {} }>
              { result['thumbnail']?
                <img alt={'card-image-' + i} src={thumbnailUrl}/>
                : ''}
            </div>

            <div className='card-content search-result-fields'>
              {this.renderTitle(result, 'name', result['name'])}
              {this.renderDescription(result)}
              {this.renderCounters(result)}
            </div>
          </div>
        </div>
      )
    });

    return (
      <div className='row'>
        { cards }
      </div>
    );
  }

  renderTitle(result, field, value) {
    const { localeStore } = this.props;

    let parameters = this.props.contextParams;
    let description = result['description']['descriptionsPerLocale'][localeStore.actions.getLocaleWithUnderScore()] ||
      result['description']['descriptionsPerLocale'][localeStore.actions.getLocaleWithoutRegionCode()] || '';
    return (
      <React.Fragment>
        <a style={{display: 'inline-block'}} href={'#/' + this.props.context + (parameters? parameters+result.id : '/' + result.id)} key={field} className='search-result-title'>
          <h2 dangerouslySetInnerHTML={ {__html: Array.isArray(value) ? value[0] : value} }/>
          <div className='search-result-card-actions'>
            <a style={{display: 'inline-block', float: 'right'}}
               title={localeStore.intl.formatMessage({ id: 'retrievo.ui.records.show-original-source' })}
               href={result['url']} target='_blank' rel='noopener noreferrer'>
              <span className='fas fa-external-link-alt'/>
            </a>
            { description.length > 100?
              (
                <a style={{display: 'inline-block', float: 'right', paddingRight: '.7rem' }} title={localeStore.intl.formatMessage({ id: 'retrievo.ui.records.more-info' })} href='#' onClick={(event) => {
                  event.preventDefault();
                  if(this.cardRefs[result.id].seeMore.current.classList.contains('fa-plus')) {
                    this.cardRefs[result.id].seeMore.current.classList.remove('fa-plus');
                    this.cardRefs[result.id].seeMore.current.classList.add('fa-minus');
                  } else {
                    this.cardRefs[result.id].seeMore.current.classList.remove('fa-minus');
                    this.cardRefs[result.id].seeMore.current.classList.add('fa-plus');
                  }
                  this.cardRefs[result.id].descriptionRef.current.classList.toggle('expanded');
                }}><span ref={this.cardRefs[result.id].seeMore} className='fas fa-plus'/></a>
              ) : '' }
            {
              this.props.edit?
                <a style={{display: 'inline-block', float: 'right', paddingRight: '.7rem'}} title={localeStore.intl.formatMessage({ id: 'retrievo.ui.records.edit' })} href={'#/' + this.props.context + (parameters? parameters + result.id : '/' + result.id)}>
                  <span className='fas fa-pencil-alt'/>
                </a> : ''
            }
          </div>
        </a>
      </React.Fragment>
    );
  }

  renderDescription(result) {
    const { localeStore } = this.props;
    let value = result['description']['descriptionsPerLocale'][localeStore.actions.getLocaleWithUnderScore()] ||
      result['description']['descriptionsPerLocale'][localeStore.actions.getLocaleWithoutRegionCode()];
    return  (
      <React.Fragment>
        <p key={'description.descriptionsPerLocale'}
           ref={this.cardRefs[result.id].descriptionRef}
           dangerouslySetInnerHTML={ {__html: Array.isArray(value) ? value[0] || localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.records.no-description.label' }) : value } }
           className='search-result-description'
        />
      </React.Fragment>
    );
  }

  renderCounters(result) {
    const { localeStore } = this.props;
    return (
      <div className='search-result-counters' style={{marginTop: '.5rem'}}>
        <a href={'#/records?facets.dataSourceId.selected=' + result.id} className='btn btn-link'>
          <span className='far fa-file-alt' style={{ fontSize: 'var(--fs-md)', paddingRight: '.5em'}}/>
            { result['recordsVisible'].toLocaleString() + ' ' + localeStore.intl.formatMessage({id : 'retrievo.ui.entity.data_sources.records.label'})   }
        </a>
        <a href={'#/records?facets.dataSourceId.selected=' + result.id + '&facets.thumbnail.selected=thumbnail.exist'}  className='btn btn-link'>
          <span className='far fa-file-image' style={{ fontSize: 'var(--fs-md)', paddingRight: '.5em'}}/>
            { result['thumbnailsVisible'].toLocaleString() + ' ' + localeStore.intl.formatMessage({id : 'retrievo.ui.entity.data_sources.images.label'})  }
        </a>
      </div>
    );
  }

}

export default withLocaleStore(
  withConfigStore(CardSearchResultComponent)
);
