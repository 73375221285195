import React, { Component } from 'react';

import TableComponent from 'components/global/misc/TableComponent';

import { withLocaleStore } from 'stores/LocaleStore';

class TableSearchResultComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  render() {
    const { results, localeStore } = this.props;
    let displayFields = this.props.displayFields === undefined ?
      (results.length < 1 ? [] : Object.keys(results[0])) : this.props.displayFields;

    let displayFieldLabels = displayFields.map((field) => {
      return localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.props.entity + '.' + field + '.label'});
    });

    if(results.length > 0) {
      return <TableComponent
        fields={displayFields}
        fieldsLabels={displayFieldLabels}
        values={this.props.results}
        onRowCLick={this.props.onRowClick}
        successRows={this.props.successRows}
        warningRows={this.props.warningRows}
        errorRows={this.props.errorRows}
      />
    } else {
      return <></>
    }
  }
}

export default withLocaleStore(TableSearchResultComponent);
