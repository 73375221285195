import React from 'react'

class DropdownComponent extends React.Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    event.preventDefault();
    let value = event.currentTarget.getAttribute('data-value');
    if(value != null) {
      this.props.onChange(value);
    }
  }

  render() {
    const options = this.props.options || [];
    const disabled = this.props.disabled !== undefined? this.props.disabled : false;
    const selected = options[this.props.selected ]  || this.props.selected  || 'N/a selected';

    return (
      <div className='dropdown'>
        <button
          className='btn btn-outline-light dropdown-toggle'
          type='button'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
          disabled={disabled}
        >
          {selected.label || selected.key || selected }
        </button>
        <div className='dropdown-menu'>
          {this.renderOptions(options)}
        </div>
      </div>
    )
  }

  renderOptions(options) {
    return options.map((option, i)=>{
      return <a
        key={i}
        className='dropdown-item'
        href='#'
        onClick={this.onChange}
        id={option.key || option}
        data-value={option.key || option}>{option.label || option.key || option}</a>
    });
  }

}


export default DropdownComponent;
