// External Libs/Components
import React, { Component } from 'react'

// Components
import DropdownComponent from '../../global/elements/DropdownComponent'

// Stores
import { withConfigStore} from '../../../stores/ConfigStore'
import { withLocaleStore } from '../../../stores/LocaleStore'
import { withSearchStore } from '../../../stores/SearchStore'

// CSS
import './_.css'

class SorterComponent extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { searchStore } = this.props;
    //const showLabel = this.props.showLabel === undefined ? true : this.props.showLabel;
    if(searchStore.data.result.items.length > 0) {
      return (
        <div className='sorter'>
          {this.renderSorter()}
        </div>
      );
    } else {
      return '';
    }

  }

  renderSorter() {
    const { configStore, localeStore , searchStore } = this.props;

    let sorterOptionsKeys = configStore.actions.getProperty('retrievo.ui.search.sorter.' + searchStore.actions.getEntity() + '.options') || [];
    let defaultSorterOptionKey = configStore.actions.getProperty('retrievo.ui.search.sorter.' + searchStore.actions.getEntity() +'.default');

    let selectedSorterOptionKey = searchStore.data.request.sort_by.length ?
      searchStore.data.request.sort_by[0]: defaultSorterOptionKey;

    let selectedSorterOption = undefined;
    let sorterOptions = sorterOptionsKeys.reduce((accumulator, currentValue) => {
      let sorterOption = {
        'key' : currentValue,
        'label' : localeStore.intl.formatMessage({'id' : 'retrievo.ui.search.sorter.' + searchStore.actions.getEntity() + '.' + currentValue })
      };

      if(sorterOption.key === selectedSorterOptionKey) {
        selectedSorterOption = sorterOption;
      }
      accumulator.push(sorterOption);
      return accumulator;
    }, []);

    return <DropdownComponent selected={ selectedSorterOption } options={ sorterOptions } onChange={ this.props.searchStore.actions.updateSorter } />
  }

}

export default withLocaleStore(
  withConfigStore(
    withSearchStore(SorterComponent)
  )
);
