// External Libs/Components
import React, { Component } from 'react';

// Global Components
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'
import EntityComponent from 'components/entity/EntityComponent'
import FooterComponent from 'components/layout/FooterComponent'
import NavbarComponent from 'components/layout/NavbarComponent'
import SidebarComponent from 'components/layout/SidebarComponent'
import TableComponent from 'components/global/misc/TableComponent'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { EntityStore, EntityContext } from 'stores/EntityStore'

// CSS (css paths are the only relative paths)
import './_.css';

class ReportEntryComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      reportEntry: undefined,
      record: undefined
    };
  }

  componentDidMount() {
    this.getReportEntry();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    if(prevProps.location.pathname !== this.props.location.pathname) {
      this.getReportEntry();
    }
  }

  getReportEntry() {
    let { taskId, reportEntryId } = this.props.match.params;
    if( taskId !== undefined && reportEntryId !== undefined) {
      this.context.actions.getById(reportEntryId, { context: 'tasks/' + taskId + '/report_entries' })
        .then((result) => {
          this.setState({ reportEntry : result.data });
          return result.data;
        })
        .then((reportEntry) => {
          this.getRecord(reportEntry);
        })
        .catch((error) => {
          this.props.history.push({
            pathname: '/error',
            search: '?error_code=' + error.response.status
          });
        });
    }
  }

  getRecord(reportEntry) {
    if(reportEntry !== undefined) {
      if(reportEntry['recordId'] !== undefined) {
        this.context.actions.getById(reportEntry['recordId'], {context: 'records'})
          .then((result) => {
            this.setState({ record: result.data })
          })
/*
          .catch((error) => {
            this.props.history.push({
              pathname: '/error',
              search: '?error_code=' + error.response.status
            });
          })
*/
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <EntityStore context='report_entries'>
          <NavbarComponent/>
          <section className='report-entry'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-auto sidebar-container'>
                  <SidebarComponent/>
                </div>
                <div className='col admin-container'>
                  <div className='row'>
                      {this.renderHeader()}
                      {this.renderReportEntryRecord(this.state.record)}
                      {this.renderReportEntryItems(this.state.reportEntry)}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent/>
        </EntityStore>
      </React.Fragment>
    );
  }

  renderHeader() {
    const { localeStore } = this.props;
    let { taskId, reportEntryId } = this.props.match.params;
    let items = [
      'tasks',
      { value: taskId, target: '/#/admin/tasks/' + taskId },
      'report_entries',
      { value: reportEntryId, target: '/#/admin/tasks/'+ taskId + '/report_entries/' + reportEntryId }
    ];
    return (
      <div className='col-12'>
        <BreadcrumbComponent items={items}/>
        <h1 className='container-title'>
          { localeStore.intl.formatMessage({ id: 'retrievo.ui.report_entry.record.title'}) }
        </h1>
        <p className='container-description'>
          { localeStore.intl.formatMessage({ id: 'retrievo.ui.report_entry.record.description'}) }
        </p>
      </div>
    );
  }

  renderReportEntryRecord(record) {
    if(record !== undefined) {
      return (
        <div className='col-12'>
          <div className='row report-entry-record'>
            <EntityComponent
              editable={false}
              deletable={false}
              context='report_entries.records'
              entity={record}
              viewerFields={['id','created', 'state', 'originalInXml', 'metadataEnhanced.titles', 'metadataEnhanced.types']}
            />
          </div>
        </div>
      );
    }
  }

  renderReportEntryItems(reportEntry) {
    const { localeStore } = this.props;
    const fields = ['number', 'typeEnhanced', 'actionEnhanced', 'details'];

    if (reportEntry !== undefined) {
      let fieldsLabels = fields.map((field) => {
        return localeStore.intl.formatMessage({id: 'retrievo.ui.entity.report_entries.' + field + '.label'});
      });
      let values = reportEntry['reportEntryItems'].map((reportEntryItem, i) => {
        reportEntryItem['number'] = i+1;
        reportEntryItem['actionEnhanced'] = localeStore.intl.formatMessage(
          { id: 'retrievo.ui.report_entries.report_entry_items.action.' + reportEntryItem['action'] + '.label', defaultMessage: reportEntryItem['action']});
        switch (reportEntryItem['type']) {
          case 'INFO':
            reportEntryItem['typeEnhanced'] = <span className='badge badge-success'>
              {localeStore.intl.formatMessage({id: 'retrievo.ui.report_entries.report_entry_items.type.' + reportEntryItem['type'] + '.label'})}
            </span>;
            break;
          case 'WARN':
            reportEntryItem['typeEnhanced'] = <span className='badge badge-warning'>
              {localeStore.intl.formatMessage({id: 'retrievo.ui.report_entries.report_entry_items.type.' + reportEntryItem['type'] + '.label'})}
            </span>;
            break;
          case 'ERROR':
            reportEntryItem['typeEnhanced'] = <span className='badge badge-danger'>
              {localeStore.intl.formatMessage({id: 'retrievo.ui.report_entries.report_entry_items.type.' + reportEntryItem['type'] + '.label'})}
            </span>;
            break;
          default:
            reportEntryItem['typeEnhanced'] = <span className='badge badge-warning'>
              {localeStore.intl.formatMessage({id: 'retrievo.ui.report_entries.report_entry_items.type.' + reportEntryItem['type'] + '.label'})}
            </span>;
            break;
        }

        return reportEntryItem;
      });

      return (
        <div className='col-12'>
          <h1 className='container-title'>
            { localeStore.intl.formatMessage({ id: 'retrievo.ui.report_entry.title' }) }
          </h1>
          <p className='container-description'>
            { localeStore.intl.formatMessage({ id: 'retrievo.ui.report_entry.description' }) }
          </p>
          <div className='row report-entry-items'>
            <TableComponent
              fields={fields}
              fieldsLabels={fieldsLabels}
              values={values}
            />
          </div>
        </div>
      );
    }
  }
}


ReportEntryComponent.contextType = EntityContext;

export default withLocaleStore(ReportEntryComponent);
