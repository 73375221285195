// External Libs
import React from 'react'
import get from 'lodash/get'
import set from 'lodash/set'

// Components
import MultiSelectDropdownComponent from 'components/global/elements/MultiSelectDropdownComponent';
import {withConfigStore} from "stores/ConfigStore";
import {withLocaleStore} from "stores/LocaleStore";

// Stores

class MultiSelectFormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.onMultiSelectFormFieldChange = this.onMultiSelectFormFieldChange.bind(this);
  }

  render() {
    const { configStore, localeStore } = this.props;
    const { field, fieldName, entity, entityType, entityOptions } = this.props;

    let placeholder = localeStore.intl.formatMessage({
      id: 'retrievo.ui.entity.' + entityType + '.' + field + '.placeholder',
      defaultMessage: ' '
    });
    let optionField = configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.optionField');
    let defaultValues = get(entity, field) || configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.defaultValues') || [];
    let optionFields = [];
    if(optionField) {
      optionFields = get(entityOptions, optionField) || [];
    } else {
      optionFields = configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.options') || [];
    }

    return (
      <React.Fragment>
        <label htmlFor={'input-' + field}>{fieldName}</label>
        <MultiSelectDropdownComponent
          id={'input-' + field}
          class='free-select'
          placeholder={placeholder}
          selectedOptions={defaultValues}
          options={optionFields}
          onChange={ (selectedOptions) => {
            this.onMultiSelectFormFieldChange(field, selectedOptions);
          }}  />
      </React.Fragment>
    );
  }

  onMultiSelectFormFieldChange(field, selectedOptions) {
    let { onEntityChange, entity } = this.props;
    let values = selectedOptions.map((selectedOption) => {
      return selectedOption['value']
    });

    set(entity, field, values);

    onEntityChange(entity);
  }

}

export default withConfigStore(withLocaleStore(MultiSelectFormComponent))
