import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'

class BreadcrumbComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items || []
    }
  }

  componentDidUpdate() {
    if(this.state.items !== this.props.items) {
      this.setState({items: this.props.items});
    }
  }

  render() {
    let itemsLength = this.state.items.length;
    if (itemsLength > 0) {
      let items = this.state.items.map((item, pos) => {
        return this.renderItem(item, pos, itemsLength);
      });
      let title = this.renderTitle(this.state.items);
      return (
        <React.Fragment>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              { items }
            </ol>
          </nav>
        </React.Fragment>
      );
    } else {
      return null
    }
  }

  renderTitle(items) {
    const {  localeStore } = this.props;
    let title =  localeStore.intl.formatMessage({ id: 'retrievo.ui.title' });
    for (let i = 0; i < items.length; i++) {
      let label = items[i].value || localeStore.intl.formatMessage({ id: 'retrievo.ui.sidebar.' + (items[i].key || items[i]) + '.label' }).replace(/<[^>]+>/g, '');
      title += ' - ' + label;
    }
    return title
  }

  renderItem(item, pos, length) {
    const { localeStore } = this.props;

    let link = item.action? undefined : (item.target || localeStore.intl.formatMessage({ id: 'retrievo.ui.sidebar.' + (item.key || item) + '.link' }));
    let label = item.value || localeStore.intl.formatMessage({ id: 'retrievo.ui.sidebar.' + (item.key || item) + '.label' });

    return (
      <li key={'breadcrumb-item-' + (item.key || pos)} className={'breadcrumb-item' + (pos === (length-1) ? ' active' : '')} aria-current='page'>
        <a href={link} onClick={ (event) => {
          if(item.action) {
            item.action(event)
          } else {
            event.preventDefault();
            let linkParts = link.split('?');
            let path = linkParts[0].replace('/#', '');
            let search = linkParts[1]? '?' + linkParts[1] : '';
            this.props.history.push({ pathname: path, search: search});
          }
        }} dangerouslySetInnerHTML={{__html: label}}/>
      </li>
    );
  }

}


export default withLocaleStore(withRouter(BreadcrumbComponent));
