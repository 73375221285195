// External Libs/Components
import React, { Component } from 'react';

// Global Components
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'
import EntityComponent from 'components/entity/EntityComponent'
import FooterComponent from 'components/layout/FooterComponent'
import NavbarComponent from 'components/layout/NavbarComponent'
import SidebarComponent from 'components/layout/SidebarComponent';

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { EntityContext, EntityStore } from 'stores/EntityStore'

// CSS (css paths are the only relative paths)
import './_.css';

class AboutComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      versionInfo: undefined,
      errorCode: undefined
    };
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.getVersionInfo();
  }

  componentDidUpdate() {
  }

  getVersionInfo() {
    this.context.actions.getVersion()
      .then((result) => {
        this.setState({ versionInfo : result.data })
      })
      .catch(() => {
        // FIXME add notification
        // console.log(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <EntityStore context='no_context'>
          <NavbarComponent/>
          <section className='about'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-auto sidebar-container'>
                  <SidebarComponent/>
                </div>
                <div className='col admin-container'>
                  {this.renderHeader()}
                  {this.renderBuildInfo()}
                </div>
              </div>
            </div>
          </section>
          <FooterComponent/>
        </EntityStore>
      </React.Fragment>
    );
  }

  renderHeader() {
    const { localeStore } = this.props;
    return (
      <React.Fragment>
        <BreadcrumbComponent items={['info', 'info.about']}/>
        <h1 className='container-title'>{localeStore.intl.formatMessage({ id: 'retrievo.ui.about.title'})}</h1>
        <p className='container-description'>{localeStore.intl.formatMessage({ id: 'retrievo.ui.about.description'})}</p>
      </React.Fragment>
    );
  }

  renderBuildInfo() {
    if(this.state.versionInfo !== undefined) {
      return (
        <EntityComponent
          entity={this.state.versionInfo}
          entityType={'about'}
          editable={false}
          deletable={false}
          viewerFields={[
            'git.remote.origin.url',
            'git.build.version',
            'git.commit.id',
            'git.branch',
            'git.commit.time',
            'git.build.time',
            'git.commit.user.email',
            'git.commit.user.name',
          ]}
        />
      )
    }
  }

}

AboutComponent.contextType = EntityContext;

export default withLocaleStore(AboutComponent)
