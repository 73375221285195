// External Libs/Components
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import moment from 'moment';

// Global Components
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'

// Container Components

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'
import { EntityContext, EntityStore } from 'stores/EntityStore';

// CSS (css paths are the only relative paths)
import './_.css';
import SpinnerComponent from "../../../components/global/misc/SpinnerComponent";

class RecordComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      record: undefined
    };
    moment.locale(props.localeStore.actions.getLocale());
    this.renderPageTitle = this.renderPageTitle.bind(this);
    this.renderSection = this.renderSection.bind(this);
    this.renderSections = this.renderSections.bind(this);
    this.renderMetadataValue = this.renderMetadataValue.bind(this);
    this.renderThumbnail = this.renderThumbnail.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  getRecord() {
    let { recordId } = this.props.match.params;
    if( recordId !== undefined) {
      this.context.actions.getById(recordId)
        .then((result) => {
          this.setState({ record : result.data, loading: false })
        })
        .catch((error) => {
          this.props.history.push({
            pathname: '/error',
            search: '?error_code=' + error.response.status
          });
        });
    }
  }

  componentDidMount() {
    this.getRecord();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname !== this.props.location.pathname) {
      this.getRecord();
    }
  }

  render() {
    let content = this.state.loading? <SpinnerComponent/> : (
      <React.Fragment>
        <div className='container-fluid'>
          {this.state.record? this.renderHeader() : undefined}
        </div>
        <div className='container-fluid public-container'>
          {this.state.record? this.renderSections() : undefined}
        </div>
      </React.Fragment>
  );


    return (
      <React.Fragment>
        {this.renderPageTitle()}
        <EntityStore context='search/records'>
          <NavbarComponent/>
          <section id='main-content' className='record'>
            { content }
          </section>
          <FooterComponent/>
        </EntityStore>
      </React.Fragment>
    );
  }

  renderPageTitle() {
    let { localeStore } = this.props;

    if(this.state.record) {
      return (
        <Helmet>
          <title>
            {localeStore.intl.formatMessage({ id: 'retrievo.ui.title' }) + ' - ' + this.state.record['metadataEnhanced']['titles'][0]}
          </title>
        </Helmet>
      );
    }
  }

  renderHeader() {
    let { configStore, localeStore } = this.props;
    let record = this.state.record;

    let fields = configStore.actions.getProperty('retrievo.ui.entity.records.component.header.fields').map((field, i) => {
      let fieldValue = get(record, field);
      return (
        <React.Fragment key={'metadata-field-'+ i}>
          <dt key={'header-' + field + '-key'} className='col-12 col-lg-2 metadata-field-key' >
            {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.records.' + field + '.label'})}
          </dt>
          <dd key={'header-' + field + '-values'} className='col-12 col-lg-9  metadata-field-value'>
            {this.renderMetadataValues(field, fieldValue)}
          </dd>
        </React.Fragment>
      );
    });

    let link = get(record, 'metadataEnhanced.link');
    let originalUrlElem = undefined;
    if(link) {
      if(link.length > 0) {
        originalUrlElem = (
            <a href={link[0]} target='_blank' rel='noopener noreferrer' title={localeStore.intl.formatMessage({ id: 'retrievo.ui.records.show-original' })}>
              <span className='fas fa-external-link-alt'/>
            </a>
        );
      }
    }

    let fromSearchResults = this.props.location.state? this.props.location.state.fromSearch || false : false;

    return (
      <div className='row record-header'>
        <div className='public-container'>
          <div className='row'>
            <div className='col-12'>
              {
                fromSearchResults?
                  <a href='#' className='back btn btn-link' onClick={this.goBack}>
                    <span className='fas fa-arrow-left'/>
                    { localeStore.intl.formatMessage({ id: 'retrievo.ui.record.back' }) }
                  </a> : ''
              }
              <h1>{record.metadataEnhanced.titles.join('\r\n') } {originalUrlElem}</h1>
            </div>
            <div className='col-12 col-md-8 col-lg-10'>
              <dl className='row'>
                {fields}
              </dl>
            </div>
            <div className='col-12 col-md-4 col-lg-2'>
              <div className='record-thumbnail'>
                {this.renderThumbnail()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderThumbnail(){
    let { configStore } = this.props;
    let record = this.state.record;

    if(record['thumbnail']) {
      return (
        <a href={record['metadataEnhanced']['link']? record['metadataEnhanced']['link'][0] : ''}
          target='_blank' rel='noopener noreferrer'>
         <img alt='thumbnail' src={configStore.actions.getApiUrl() + '/search/records/' + record['id'] + '/thumbnail'}/>
       </a>
      );
    }
  }

  renderSections() {
    let { configStore } = this.props;
    let sections = configStore.actions.getProperty('retrievo.ui.entity.records.component.sections') || [];
    return sections.map(section => this.renderSection(section));
  }

  renderSection(section) {
    const { configStore, localeStore } = this.props;
    let record = this.state.record;
    let sectionFields = configStore.actions.getProperty('retrievo.ui.entity.records.component.sections.' + section) || [];
    // toliveira 20200730 to avoid exceptions if DATA_TYPE is not set for a specific section (properties.cfg)
    sectionFields = Array.isArray(sectionFields)? sectionFields : [];

    let values = [];

    sectionFields.map(sectionField => {
      let sectionFieldsValues = get(record, sectionField) || [];
      values =  [...values, ...sectionFieldsValues];
    });

    return values.length > 0 ?
     (
      <div key={'record-section-' + section } className='row record-metadata'>
        <div className='col-12'>
          <h2>{localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.records.component.sections.' + section + '.title'})}</h2>
          <dl className='row'>
            {this.renderMetadataFields(sectionFields)}
          </dl>
        </div>
      </div>
    ) : '';
  }

  renderMetadataFields(fields) {
    const { localeStore } = this.props;
    let record = this.state.record;
    return fields.map((field, i) => {
      let value = get(record, field);
      let renderValue = Array.isArray(value) ? value.length > 0 : value !== undefined;
      if(renderValue) {
        return (
          <React.Fragment key={'metadata-field-'+ i}>
            <dt key={field + '-key'} className='col-12 col-lg-3 metadata-field-key' >
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.records.' + field + '.label'})}
            </dt>
            <dd key={field + '-values'} className='col-12 col-lg-9  metadata-field-value'>
              { this.renderMetadataValues(field, value) }
            </dd>
          </React.Fragment>
        );
      }
    });
  }

  renderMetadataValues(field, value) {
    const { localeStore } = this.props;
    let values = Array.isArray(value) ? value : (value !== undefined ? [value] : [] );
    return (
      <span key={field + '-values'} className='col-12 metadata-field-value' >
        {values.length ? values.map((value, pos) => {
          return this.renderMetadataValue(field, value, pos)
        }) : localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.records.no-value.label'}) }
      </span>
    );
  }

  renderMetadataValue(field, value, pos) {
    let { configStore, localeStore } = this.props;

    let fieldsAsFilter = configStore.actions.getProperty('retrievo.ui.links.records.as_filter') || [];
    let fieldsAsFacets = configStore.actions.getProperty('retrievo.ui.links.records.as_facet') || [];

    if (value.match(/^https?/)) {
      return <a key={field + '-value-' + (pos + 1)} href={value} target='_blank' rel='noopener noreferrer'>{value}</a>
    } else if(fieldsAsFilter.includes(field)) {
      return (
        <a key={field + '-value-' + (pos + 1)} href={'/#/records?filters.0.' + field + '.equals=' + this.getFieldLinkValue(field, value)}>
          {value}
        </a>
      )
    } else if(fieldsAsFacets.includes(field)) {
      let label = localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.records.' + field + '.' + value + '.label', defaultMessage: value});
      return (
        <a key={field + '-value-' + (pos + 1)} href={'/#/records?facets.' + ( field === 'dataSourceName' ? 'dataSourceId' : field ) + '.selected=' + this.getFieldLinkValue(field, value)}>
          {label}
        </a>
      );
    }
    else {
      return <span key={field + '-values-' + (pos + 1)}>{value}</span>
    }
  }

  getFieldLinkValue(field, value) {
    let record = this.state.record;
    if(field === 'dataSourceName') {
      return get(record, 'dataSourceId');
    }
    return value.replace(/<[^>]+>/g, '');
  }

  goBack(event) {
    event.preventDefault();
    if(this.props.history.length <= 2) {
      this.props.history.push("/records");
    } else {
      this.props.history.goBack();
    }
  }
}

RecordComponent.contextType = EntityContext;


export default
  withLocaleStore(
    withRouter(
      withConfigStore(RecordComponent)
    )
  );
