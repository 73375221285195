import '@babel/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// External Libs
import React from 'react';
import { render } from 'react-dom'

// CSS
// in this component css imports must come before other imports
import './_.css'
import 'bootstrap/dist/js/bootstrap.min.js'

// Global Components
import RouterComponent from 'containers/RouterComponent'

// Stores
import { ConfigStore } from 'stores/ConfigStore'
import { UserStore } from 'stores/UserStore';

render(
    <ConfigStore>
        <UserStore>
          <RouterComponent/>
        </UserStore>
    </ConfigStore>,
  document.getElementById('react-main')
);
