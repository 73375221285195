// External Libs
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput'

// Include the locale utils designed for moment
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'

// Include moment
import moment from 'moment'

// Components
import DropdownComponent from './DropdownComponent'

//Stores
import { withLocaleStore } from 'stores/LocaleStore'

// CSS
import 'react-day-picker/lib/style.css'
import '../_.css'

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 100, 0);
const toMonth = new Date(currentYear + 5, 11);

function YearMonthForm({ date, locale, localeUtils, onChange }) {
  const months = localeUtils.getMonths(locale);

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleMonthChange = function handleChange(month) {
    onChange(new Date(date.getFullYear(), months.indexOf(month)));
  };

  const handleYearChange = function handleChange(year) {
    onChange(new Date(year, date.getMonth()));
  };

  return (
    <form className='DayPicker-Caption'>
      <DropdownComponent name='month' selected={date.getMonth()} options={months} onChange={handleMonthChange} />
      <DropdownComponent name='year' selected={date.getFullYear()} options={years} onChange={handleYearChange} />
    </form>
  );
}


class SimpleDatePicker extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.getInitialState = this.getInitialState.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
  }

  getInitialState() {
    const { from } = this.props;
    return {
      from: from !== undefined ? moment(from).toDate() : undefined,
      enteredTo: undefined, // Keep track of the last day for mouseEnter.
      yearMonth: undefined // Keep track of changes on month or year
    };
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  handleDayClick(day) {
      this.setState({
        from: day,
      }, this.handleChange(day));
  }

  handleDayChange(from, modifiers, dayPickerInput) {
    const { onChange } = this.props;
    const input = dayPickerInput.getInput();
    const isEmpty = !input.value.trim();
    if(onChange) {
      if(from) {
        onChange(from);
      } else if(isEmpty) {
        onChange(null);
      }
    }
  }

  handleDayMouseEnter(day) {
    this.setState({ enteredTo: day, });
  }

  handleYearMonthChange(yearMonth) {
    this.setState({ yearMonth: yearMonth });
  }

  handleChange(range) {
    const { onChange } = this.props;

    if(onChange != null) {
      onChange(range);
    }
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }


  render() {
    const { placeholderFrom } = this.props;
    const { from, yearMonth } = this.state;
    const locale = this.props.localeStore.actions.getLocale();

    /* keep overlay open - > showOverlay={true} */
    return (
      <span className='datepicker simple'>
        <DayPickerInput
          value={from}
          placeholder={placeholderFrom}
          format='LL'
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            locale,
            localeUtils: MomentLocaleUtils,
            month: yearMonth,
            numberOfMonths: 1,
            captionElement: ({ date, localeUtils }) => (
              <YearMonthForm
                date={date}
                locale={locale}
                localeUtils={localeUtils}
                onChange={this.handleYearMonthChange}
              />
            ),
            onDayClick: this.handleDayClick,
            onDayMouseEnter: this.handleDayMouseEnter
          }}
        onDayChange={this.handleDayChange}
      />
      </span>
    );
  }
}

export default withLocaleStore(SimpleDatePicker);

