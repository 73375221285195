// External Libs
import React from 'react'
import get from 'lodash/get'

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'

class BooleanFormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.onBooleanFormFieldChange = this.onBooleanFormFieldChange.bind(this);
  }

  render() {
    const { field, fieldName, entity } = this.props;

    let value = get(entity, field) === undefined ? false : get(entity, field);

    if(value) {
      return (
        <React.Fragment>
          <label htmlFor={'input-' + field}>{fieldName}</label>
          <span style={{display: 'block'}}>
            <button onClick={this.onBooleanFormFieldChange} className='btn btn-sm btn-secondary btn-on' value='true' data-target={field}>On</button>
            <button onClick={this.onBooleanFormFieldChange} className='btn btn-sm btn-outline-danger btn-off' value='false' data-target={field}>Off</button>
          </span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <label htmlFor={'input-' + field}>{fieldName}</label>
          <span style={{display: 'block'}}>
            <button onClick={this.onBooleanFormFieldChange} className='btn btn-sm btn-outline-secondary' value='true' data-target={field}>On</button>
            <button onClick={this.onBooleanFormFieldChange} className='btn btn-sm btn-danger' value='false' data-target={field}>Off</button>
          </span>
        </React.Fragment>
      );
    }
  }

  onBooleanFormFieldChange(event) {
    // stop event and collect form info
    event.preventDefault();

    // extract form values
    let value = event.target.value === 'true';
    let field = event.target.dataset.target;

    // update entity
    let { entity, onEntityChange } = this.props;
    entity[field] = value;
    onEntityChange(entity);
  }

}

export default withConfigStore(withLocaleStore(BooleanFormComponent))
