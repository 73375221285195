import React from 'react';
import Axios from 'axios';
import Cookies from 'js-cookie/src/js.cookie.js'

import { withConfigStore } from 'stores/ConfigStore';

const UserContext = React.createContext();
const UserStoreProvider = UserContext.Provider;
const UserStoreConsumer = UserContext.Consumer;

class UserStoreComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        data: {
          user: {
            username : '',
            groups : []
          },
          isLoggedIn: undefined
        },
        actions: {
          login: this.login.bind(this),
          logout: this.logout.bind(this),
          getUser: this.getUser.bind(this),
          isSudo: this.isSudo.bind(this),
          isAdmin: this.isAdmin.bind(this),

        }
    };
  }

  render() {
    return (
      <UserStoreProvider value={this.state}>
        {this.props.children}
      </UserStoreProvider>
    );
  }

  login(username, password, onError) {
    const { configStore } = this.props;

    // 20190806 hsilva: withCredentials is only necessary when in dev mode as CORS is in place
    Axios.defaults.withCredentials = true;

    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return Axios.post(configStore.actions.getApiUrl() + '/auth/login', params)
      .then(() => { return this.getUser(onError)});
  }

  logout() {
    const { configStore } = this.props;
    return Axios.get(configStore.actions.getApiUrl() + '/auth/logout')
      .finally(() => {
        this.removeUser();
      })
  }

  getUser(onError) {
    const { configStore } = this.props;
    return Axios.get(configStore.actions.getApiUrl() + '/users')
      .then((result) => {
        let data = {
          user: {
            username : result.data.username,
            groups : result.data.groups,
          },
          isLoggedIn: true
        };
        this.setState({ data });
        return data;
      })
      .catch(() => {
        let data = {
          ...this.state.data,
          isLoggedIn: false
        };
        this.setState( { data });
        if(onError) {
          onError();
        }
        return data;
      });
  }

  removeUser() {
    let data = {
      user: { username: '', groups : [] },
      isLoggedIn: false
    };
    Cookies.remove('jj');
    this.setState({ data: data });
  }

  isSudo() {
    return this.state.data.user.groups.includes('sudo');
  }

  isAdmin() {
    return this.state.data.user.groups.includes('administrator');
  }

}


// Providing context as higher-order-component
// https://reactjs.org/docs/context.html#consuming-context-with-a-hoc
function withUserStore(Component) {
  return function userStore(props) {
    return(
      <UserStoreConsumer>
        { (userStore) => <Component {...props} userStore={userStore} /> }
      </UserStoreConsumer>
    )
  }
}

const UserStore = withConfigStore(UserStoreComponent);

export { UserStore, withUserStore }
