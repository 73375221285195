import React, { Component } from 'react';

const offset = 2;
const max_record_number = 10000;

class PaginationComponent extends Component {

  constructor(props) {
    super(props);
  }

  /* Actions */
  onChange(pageNumber) {
    this.props.onChange(pageNumber);
  }

  getLastPage() {
    const { perPage, total } = this.props;
    return total > 0 ? Math.ceil(total/perPage) : 1;
  }

  render() {
    return (
      <nav aria-label='Page navigation'>
        <ul className='pagination'>
          {this.renderPrevious()}
          {this.renderPageNumbers()}
          {this.renderNext()}
        </ul>
      </nav>
    );
  }

  renderPageNumbers() {
    const { currentPage } = this.props;
    var rows = [];

    for (let i = offset; i > 0; i--) {
      rows.push(this.renderPageNumber(currentPage-i))
    }

    rows.push(this.renderPageNumber(currentPage));

    for (let i = 1; i <= offset ; i++) {
      rows.push(this.renderPageNumber(currentPage+i))
    }

    rows.push(this.renderLast());

    return rows;
  }

  renderPageNumber(pageNumber) {
    const { currentPage } = this.props;
    const lastPage = this.getLastPage();

    if(pageNumber > 0 && pageNumber <= lastPage) {
      return (
        <li key={pageNumber} className={'page-item' + (pageNumber === currentPage ? ' active' : '')  + ' ' + pageNumber }>
          <a className='page-link'
             onClick={() => {this.onChange(pageNumber)} }>
             { pageNumber }
          </a>
        </li>
      );
    }
  }

  renderPrevious() {
    const { currentPage } = this.props;
    return (
      <li key='page-previous' className={ 'page-previous page-item' + (currentPage -1 < 1? ' disabled' : '')} >
        <a className='page-link' onClick={() => {this.onChange(currentPage - 1)} } aria-label='Previous'>
          <span aria-hidden='true'>&laquo;</span>
          <span className='sr-only'>Previous</span>
        </a>
      </li>
    );
  }

  renderNext() {
    const { currentPage } = this.props;
    const lastPage = this.getLastPage();

    return (
      <li key='page-next' className={ 'page-next page-item' + (currentPage +1 > lastPage? ' disabled' : '')} >
        <a className='page-link' 
           onClick={() => {this.onChange(currentPage + 1)} }
           aria-label='Next'>
          <span aria-hidden='true'>&raquo;</span>
          <span className='sr-only'>Next</span>
        </a>
      </li>
    );
  }

  renderLast() {
    const { perPage, currentPage } = this.props;
    const lastPage = this.getLastPage();
    const lastPageAchievable = !((lastPage * perPage) >= max_record_number);
    const nextPageAchievable = !(currentPage + 1 === lastPage);

    if( lastPage > currentPage + offset + 1 && lastPageAchievable) {
      return (
        <React.Fragment key='separator-last-enable'>
          <li key='separator' className={'page-item'}>
            <a className='page-link' onClick={() => { nextPageAchievable? this.onChange(currentPage + offset + 1) : '' }} >
              ...
            </a>
          </li>
          <li key='last-page' className={'page-item'}>
            <a className='page-link' onClick={() => {this.onChange(lastPage)} }>
              { lastPage }
            </a>
          </li>
        </React.Fragment>
      );
    } else if( lastPage > currentPage + offset + 1 && !lastPageAchievable) {
      return (
        <React.Fragment key='separator-last-disabled'>
          <li key='separator-last' className={'page-item'}>
            <a className={'page-link' + (nextPageAchievable? '' : ' disabled')  } onClick={() => { nextPageAchievable? this.onChange(currentPage + offset + 1) : '' }} >
              ...
            </a>
          </li>
          <li key='last-page-disabled' className={'page-item'}>
            <a className='page-link disabled' >
              { lastPage }
            </a>
          </li>
        </React.Fragment>
      );
    }
  }

}


export default PaginationComponent;
