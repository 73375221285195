// External Libs/Components
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

// Global Components
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'

// Container Components

// Stores
import { withLocaleStore } from 'stores/LocaleStore'

// CSS (css paths are the only relative paths)
import './_.css';

class ErrorComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errorCode: undefined
    }
  }

  setErrorCode() {
    let queryParameters = this.props.location.search;
    let parameters = qs.parse(queryParameters);
    let errorCode;
    switch (parameters['error_code']) {
      case undefined:
        errorCode = '404';
        break;
      default:
        errorCode = parameters['error_code'];
    }
    this.setState({ errorCode: errorCode});
  }

  componentDidMount() {
    this.setErrorCode();
  }

  componentDidUpdate(previousProps) {
    let currentLocation = this.props.location;
    let previousLocation = previousProps.location;

    if(currentLocation.pathname !== previousLocation.pathname ||
      currentLocation.search !== previousLocation.search) {
      this.setErrorCode()
    }
  }

  render() {
    return (
      <React.Fragment>
        <NavbarComponent/>
        <section id='error'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h1>Error title: {this.state.errorCode}</h1>
                <p>Error description</p>
              </div>
            </div>
          </div>
        </section>
        <FooterComponent/>
      </React.Fragment>
      );
  }

}

export default withRouter(
  withLocaleStore(ErrorComponent)
);
