// External Libs
import React from 'react'
import get from 'lodash/get'

// Components
import SimpleDatePicker from 'components/global/elements/SimpleDatePicker'

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'

class DateFormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.onDateFormFieldChange = this.onDateFormFieldChange.bind(this);
  }

  render() {
    const { field, fieldName, entity } = this.props;
    let value = get(entity, field)? get(entity, field) : undefined;
    return (
        <React.Fragment>
          <label htmlFor={'input-' + field}>{fieldName}</label>
          <SimpleDatePicker
            from={value}
            placeholderFrom={fieldName}
            onChange={this.onDateFormFieldChange}/>
        </React.Fragment>
      )
  }

  onDateFormFieldChange(from) {
    // extract form values
    let field = this.props.field;

    // update entity
    let { entity, onEntityChange } = this.props;
    entity[field] = from;
    onEntityChange(entity);
  }

}

export default withConfigStore(withLocaleStore(DateFormComponent))
