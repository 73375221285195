import React, { Component } from 'react';
import SpinnerComponent from 'components/global/misc/SpinnerComponent';



class PageSpinnerComponent extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='page-spinner'>
        <SpinnerComponent/>
      </div>
    )
  }
}

export default PageSpinnerComponent
