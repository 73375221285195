// External libs
import React, { Component } from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import moment from 'moment'

// Global Components
import TableSearchResultComponent from 'components/search/results/TableSearchResultComponent'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { withRouter } from 'react-router-dom'

class TaskSearchResultComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      displayFields : [
        'name',
        'started',
        'duration',
        'stateEnhanced',
        'report.processedRecords',
        'report.validRecords',
        'report.invalidRecords',
        'successRate'
      ],
    };
    this.onClick = this.onClick.bind(this);
    moment.locale(props.localeStore.actions.getLocale());
  }
  onClick(target) {
    this.props.history.push({
      pathname: '/admin/tasks/' + target,
    });
  }

  render() {
    let { results } = this.props;
    let tasks = this.processTasks(results);

    return (
      <TableSearchResultComponent
        {...this.props}
        results={tasks}
        displayFields={this.state.displayFields}
        onRowClick={this.onClick}
      />
    );
  }

  processTasks(tasks) {

    tasks.map((task) => {
      if(task) {
        // Update title
        this.setName(task);

        // Update state badge
        this.setStateBadge(task);

        // Update counter info
        this.setCounters(task);

        // Add duration
        let started = moment(task['started']);
        let ended = moment(task['ended']);
        let duration = moment.duration(ended.diff(started));
        set(task, 'started', started.isValid()? started.format("YYYY-MM-DD HH:mm:ss") : '');
        set(task, 'duration', duration.isValid()? duration.humanize() : '');

        return task;
      }
    });
    return tasks;
  }

  setName(task) {
    const { localeStore } = this.props;

    switch (task['type']) {
      case 'UPDATE_VISIBILITY' :
        set(task, 'name',
          localeStore.intl.formatMessage({ id: 'retrievo.ui.task.' + task['type'] + (task['visible'] ? '.show' : '.hide') + '.name' },
          { name_to_update: task['nameToUpdate'] })
        );
        break;
      default:
        set(task, 'name',
          localeStore.intl.formatMessage( { id: 'retrievo.ui.task.' + task['type'] + '.name'},
          { data_connector: task['dataConnector']? task['dataConnector']['name'] : task['dataConnectorId'],
            name_to_delete: task['nameToDelete']
          }));
    }
  }

  setCounters(task) {
    switch (task['type']) {
      case 'HARVEST':
      case 'CSV_HARVEST' :
      case 'REPROCESS' : {
        let recordsProcessed = get(task, 'report.recordsHarvested') || 0;
        let recordsInvalid = get(task, 'report.recordsInvalid') || 0;
        let recordsValid = get(task, 'report.recordsValid') || 0;

        set(task, 'report.processedRecords', recordsProcessed.toLocaleString());
        set(task, 'report.invalidRecords', recordsInvalid.toLocaleString());
        set(task, 'report.validRecords', recordsValid.toLocaleString());
        set(task, 'successRate', this.getSuccessRate(recordsInvalid, recordsProcessed));
        break;
      }
      case 'FULLTEXT_THUMBNAIL' : {
        let thumbnailsHarvested = get(task, 'report.thumbnailsHarvested') || 0;
        let fulltextsHarvested  = get(task, 'report.fulltextsHarvested') || 0;
        let thumbnailsAdded = get(task, 'report.thumbnailsAdded') || 0;
        let fulltextsAdded  = get(task, 'report.fulltextsAdded') || 0;

        let invalidRecords = '0 / 0';
        if(task.state !== 'CREATED' && task.state !== 'STARTED') {
          invalidRecords = ((fulltextsHarvested - fulltextsAdded) || "0") + ' / ' + ((thumbnailsHarvested - thumbnailsAdded) || "0");
        }

        set(task, 'report.processedRecords', fulltextsHarvested.toLocaleString() + ' / ' + thumbnailsHarvested.toLocaleString());
        set(task, 'report.invalidRecords', invalidRecords);
        set(task, 'report.validRecords', fulltextsAdded.toLocaleString() + ' / ' + thumbnailsAdded.toLocaleString());
        set(task, 'successRate', this.getSuccessRate(thumbnailsHarvested - thumbnailsAdded, thumbnailsHarvested) + ' / ' +
        this.getSuccessRate(fulltextsHarvested - fulltextsAdded, fulltextsHarvested));
        break;
      }
    }
  }

  setStateBadge(task) {
    const { localeStore } = this.props;

    switch (task['state']) {
      case 'STOPPED':
      case 'CLEANED_UP':
      case 'FINISHED_WITH_SUCCESS':
        set(task, 'stateEnhanced',
          <span className='badge badge-success'>
            { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.state.' + task['state'] + '.label'}) }
          </span>
        );
        break;
      case 'FINISHED_WITH_ERROR':
        set(task, 'stateEnhanced',
          <span className='badge badge-danger'>
            { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.state.' + task['state'] + '.label'}) }
          </span>
        );
        break;
      case 'TO_STOP':
      case 'STOPPING':
        set(task, 'stateEnhanced',
          <span className='badge badge-warning'>
            { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.state.' + task['state'] + '.label'}) }
          </span>
        );
        break;
      case 'UNKNOWN':
        set(task, 'stateEnhanced',
          <span className='badge badge-warning'>
            { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.state.UNKNOWN.label'}) }
          </span>
        );
        break;
      default:
        set(task, 'stateEnhanced',
          <span className='badge badge-warning'>
            { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.tasks.state.' + task['state'] + '.label'}) }
          </span>
        );
    }
  }

  getSuccessRate(records, totalRecords) {
    let percentage = records/totalRecords * 100;
    if(percentage < 1) {
      percentage = 100 - Math.ceil(percentage);
    } else {
      percentage = 100 - Math.floor(percentage);
    }
    if(percentage > 0) {
      return '' + percentage + '%';
    } else {
      return '0%';
    }
  }

}

export default withLocaleStore(
  withRouter(
    TaskSearchResultComponent
  )
);
