// External Libs/Components
import React, { Component } from 'react'

// Components
import SorterComponent from './SorterComponent';
import RecordFederatedSearchComponent from 'containers/public/record/RecordFederatedSearchComponent';

// Stores
import { withConfigStore} from '../../../stores/ConfigStore'
import { withLocaleStore } from '../../../stores/LocaleStore'
import { withSearchStore } from '../../../stores/SearchStore'

// CSS
import './_.css'

class RefineSearchComponent extends Component {

  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  getInfo() {
    const { localeStore, searchStore } = this.props;
    let sources = searchStore.data.result.facets.find((facet) => { return facet.field === 'dataSourceId' });
    let sourcesTotal = sources? sources.totalMatchCount : 0;
    return searchStore.data.result.total > 0? localeStore.intl.formatMessage({ 'id' : 'retrievo.ui.search.info.' + searchStore.actions.getEntity() },
      { total_records: searchStore.data.result.total.toLocaleString(), total_sources: sourcesTotal }) : '';
  }

  render() {
    const { searchStore } = this.props;
    if(searchStore.data.result.items.length > 0) {
      let info;
      if(searchStore.data.result.federatedSearchTaskId) {
        info = <RecordFederatedSearchComponent message={this.getInfo()} />
      } else {
        info = <p className='refine-search-info'>{this.getInfo()}</p>
      }
      return (
        <div className='refine-search'>
          <div className='row'>
            <div className='col-12 col-sm'>
              {info}
            </div>
            <div className='col-12 col-sm-auto'>
              <SorterComponent/>
            </div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  }


  renderProjectionItems() {
    const { configStore, localeStore, searchStore } = this.props;

    const projectionFields = configStore.actions.getProperty('retrievo.ui.search.projection.' + searchStore.actions.getEntity() + '.options') || [];
    return projectionFields.map((field, i) => {
      const fieldName = localeStore.intl.formatMessage({'id' : 'retrievo.ui.entity.' + searchStore.actions.getEntity() + '.' + field + '.label'});
      let value = searchStore.data.request.projection.includes(field);
      return (
        <div key={i} className='form-group form-check'>
          <input
            key={'projection-item-' + field}
            id={i}
            type='checkbox'
            className='form-check-input'
            name={field}
            onChange={this.handleToggle}
            checked={value}/>
          <label className='form-check-label' htmlFor={i}>{fieldName}</label>
        </div>
      );
    })
  }

  handleToggle(event) {
    const { searchStore } = this.props;
    searchStore.actions.updateProjectionFields(event.currentTarget.name);
  }

}

export default withLocaleStore(
  withConfigStore(
    withSearchStore(RefineSearchComponent)
  )
);
