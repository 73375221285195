import React, { Component } from 'react'
import { withLocaleStore } from 'stores/LocaleStore'

class SelectedFacetsComponent extends Component {

  constructor(props) {
    super(props);
    this.removeFacet = this.removeFacet.bind(this);
    this.removeAllFacets = this.removeAllFacets.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  render() {
    const { localeStore } = this.props;
    return (
      <div className='selected-facets'>
        <h2>
          { localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.selected.title'}) }
        </h2>
        <div className='selected-facets-list'>
          { this.renderSelectedFacets() }
        </div>
        <div className='selected-facet-actions'>
          <a className='apply-facets' href='#' onClick={this.applyFilters}>
            { localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.selected.apply.button'}) }
          </a>
          <a className='remove-facets' href='#' onClick={this.removeAllFacets}>
            { localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.selected.clear.button'}) }
          </a>
        </div>
      </div>
    );
  }

  renderSelectedFacets() {
    const { localeStore, entityType, resultFacets } = this.props;
    return resultFacets.map((facet) => {
      return facet.selectedValues.map((selectedValue, i) => {
        let label = localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.' + entityType + '.' +
            facet.field + '.' + selectedValue.label + '.label', defaultMessage: selectedValue.label});
        let value = selectedValue.from? [selectedValue.from, selectedValue.to] : selectedValue.key;
        return (
          <a href='#'
             key={'selected-facet-'+i}
             className='selected-facet'
             onClick={(e) => { this.removeFacet(e, facet.field, value) }}>
            <span className='selected-facet-value'>{label}</span>
            <span className='selected-facet-remove' aria-hidden='true'>×</span>
          </a>
        )
      })
    })
  }

  removeFacet(event, field, key) {
    event.preventDefault();
    this.props.onClear(false, field, key);
  }

  removeAllFacets(event) {
    event.preventDefault();
    this.props.onClearAll();
  }

  applyFilters(event) {
    event.preventDefault();
    this.props.onSubmit();
  }

}

export default withLocaleStore(SelectedFacetsComponent);
