import React, { Component } from 'react';

import CardSearchResultComponent from 'components/search/results/CardSearchResultComponent';

import { withLocaleStore } from 'stores/LocaleStore';

class DataSourceSearchResultComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let expandedFields = [];
    return <CardSearchResultComponent {...this.props} expandedFields={expandedFields} edit={true} />
  }

}

export default withLocaleStore(DataSourceSearchResultComponent);
