import $ from 'jquery'
// External
import React, { Component } from 'react';
import { pageview } from 'react-ga'
import { Route, Redirect} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import cssVars from 'css-vars-ponyfill'

import SpinnerComponent from 'components/global/misc/SpinnerComponent'
import ConfigurationsComponent from 'containers/admin/configurations/ConfigurationsComponent'

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withUserStore } from "../../stores/UserStore";

class AdminComponent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { configStore, location } = this.props;
    if (configStore.data.loaded) {
      pageview(location.pathname + location.search);
    }
  }

  componentDidUpdate() {
    const { configStore, location } = this.props;
    if (configStore.data.loaded) {
      pageview(location.pathname + location.search);
    }
  }

  onHelmetChange() {
    cssVars({});
  }

  render() {
    const { configStore } = this.props;
    let content;
    if(!configStore.data.loadedAdmin) {
      configStore.actions.loadAdminConfigurations();
      content = <SpinnerComponent/>
    } else if (this.props.component === ConfigurationsComponent) {
      content = <Route key={'config-' + Date.now()} {...this.props}/>
    } else {
      content = <Route {...this.props}/>
    }

    return (
      <React.Fragment>
        <Helmet onChangeClientState={this.onHelmetChange}>
          <style id='custom-admin-theme' type='text/css'>{configStore.actions.getProperty('retrievo.ui.css_admin_theme', '')}</style>
        </Helmet>
        { content }
      </React.Fragment>
    );
  }
}

const AdminComponentWithConfigurations = withConfigStore(AdminComponent);

class IsLoggedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addDynamicJavascript() {
    const { configStore } = this.props;
    if (configStore.data.loaded) {
      const script = document.createElement('script');
      window.$ = $;
      script.async = true;
      script.innerHTML = configStore.actions.getProperty('retrievo.ui.custom_js');
      document.body.appendChild(script);
    }
  }

  render() {
    const { userStore, component } = this.props;

    this.addDynamicJavascript();

    if(userStore.data.isLoggedIn) {
      return <AdminComponentWithConfigurations component={component} {...this.props}/>
    } else {
      return <Redirect to={{
        pathname: '/auth/login',
        state: {
          referrer: this.props.location.pathname,
          referrerParams: this.props.location.search
        }
      }}/>
    }
  }

}

export default withUserStore(IsLoggedComponent);
