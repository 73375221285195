import React from 'react'

// Stores
import { withRouter} from 'react-router-dom'
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'
import { withUserStore } from 'stores/UserStore'

import './_.css'

class NavbarComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scrolling: window.scrollY >= 85
    };
    this.onScroll = this.onScroll.bind(this);
    this.handleLocaleChange = this.handleLocaleChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    if(window.scrollY >= 85) {
      this.setState({scrolling: true})
    } else {
      this.setState({scrolling: false})
    }
  }

  isActive(currentPath) {
    let match = false;
    if(this.props.location.pathname === currentPath.replace('/#', '')){
      match = true;
    }
    return match
  }

  render() {
    const { configStore, localeStore } = this.props;
    const scrolling = this.state.scrolling;
    let brand = configStore.actions.getProperty('retrievo.ui.brand.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.brand.' + localeStore.actions.getLocaleWithoutRegionCode()) || 'Retrievo';

    let navbarPlaceHolderElements = configStore.actions.getProperty('retrievo.ui.navbar.placeholder.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.navbar.placeholder.' + localeStore.actions.getLocaleWithoutRegionCode());

    return (
      <nav className='navbar-container'>
        <a href='#main-content' style={{display: 'none'}}/>
        <nav className={'navbar navbar-expand-lg fixed-top ' + (scrolling? 'navbar-scrolling' : 'navbar-top')}>
          <div className='navbar-row'>
            <div className='navbar-placeholder' dangerouslySetInnerHTML={{ __html : navbarPlaceHolderElements }} />
          </div>
          <div className='navbar-row main-navbar'>
            <a className='navbar-brand' href='/' dangerouslySetInnerHTML={{ __html: brand}} />
            <button
              className='navbar-toggler'
              type='button' data-toggle='collapse'
              data-target='#navbarNav'
              aria-controls='navbarNav'
              aria-expanded='false'
              aria-label='Toggle navigation'>
              <span className='fas fa-bars navbar-toggler-icon'/>
            </button>
            <div className='collapse navbar-collapse justify-content-end' id='navbarNav'>
              {this.renderNavbar()}
            </div>
          </div>
        </nav>
      </nav>
    )
  }

  renderNavbar() {
    const { userStore, localeStore } = this.props;
    const adminLink = '/#/admin/data_sources';
    const recordLink = '/#/records';
    const dataSourcesLink = '/#/data_sources';
    const aboutLink = '/#/about';
    const loginLink = '/#/auth/login';

    let admin = !userStore.data.isLoggedIn? null : (
      <li className='nav-item'>
        <a className={'nav-link' + (this.props.location.pathname.includes('admin') ? ' active' : '') }
           href={adminLink}>
          <span className='fas fa-cog'/>
        </a>
      </li>
    );

    let login = userStore.data.isLoggedIn? null : (
      <li className='nav-item'>
        <a className={'nav-link' + (this.isActive(loginLink) ? ' active' : '') }
           href={loginLink}>
          <span style={{display: 'none'}}>{localeStore.intl.formatMessage({id: 'retrievo.ui.navbar.login'})}</span>
          <span className='fas fa-lock'/>
        </a>
      </li>
    );

    return (
      <ul className='navbar-nav menu'>
        <li className='nav-item'>
          <a id='search' className={'nav-link' + (this.isActive(recordLink) ? ' active' : '') }
             href={recordLink}>
            {localeStore.intl.formatMessage({id: 'retrievo.ui.navbar.records.label'})}
          </a>
        </li>
        <li className='nav-item'>
          <a id='sources' className={'nav-link' + (this.isActive(dataSourcesLink) ? ' active' : '') }
             href={dataSourcesLink}>
            {localeStore.intl.formatMessage({id: 'retrievo.ui.navbar.data_sources.label'})}
          </a>
        </li>
        <li className='nav-item'>
          <a id='about' className={'nav-link' + (this.isActive(aboutLink) ? ' active' : '') }
             href={aboutLink}>
            {localeStore.intl.formatMessage({id: 'retrievo.ui.navbar.about.label'})}
          </a>
        </li>
        {admin}
        {login}
        <li className='nav-item dropdown d-none d-lg-flex'>
          <a className='nav-link dropdown-toggle'
             href='#'
             id='locale-dropdown'
             data-toggle='dropdown'
             aria-haspopup='true'
             aria-expanded='false'
          >
            <span className='fas fa-globe-europe'/>
            { localeStore.actions.getLocaleWithoutRegionCode() }
          </a>
          <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
            <a className='dropdown-item' data-target='pt-PT' onClick={this.handleLocaleChange} href='#'>
              { localeStore.intl.formatMessage({ id: 'retrievo.ui.navbar.locale.pt_PT' }) }
            </a>
            <a className='dropdown-item' data-target='en' onClick={this.handleLocaleChange} href='#'>
              { localeStore.intl.formatMessage({ id: 'retrievo.ui.navbar.locale.en' }) }
            </a>
          </div>
        </li>
        <li className='locale-mobile d-block d-lg-none'>
          <a className={localeStore.actions.getLocale().includes('pt')? 'active' : ''} data-target='pt-PT' onClick={this.handleLocaleChange} href='#'>
            { localeStore.intl.formatMessage({ id: 'retrievo.ui.navbar.locale.pt_PT' }) }
          </a>
          <a className={localeStore.actions.getLocale().includes('en')? 'active' : ''} data-target='en' onClick={this.handleLocaleChange} href='#'>
            { localeStore.intl.formatMessage({ id: 'retrievo.ui.navbar.locale.en' }) }
          </a>
        </li>
      </ul>
    )
  }

  handleLocaleChange(event) {
    event.preventDefault();
    const { localeStore } = this.props;
    localeStore.actions.setLocale(event.target.dataset.target);
  }

}

export default
  withRouter(
    withLocaleStore(
      withConfigStore(
        withUserStore(
          NavbarComponent
        )
      )
    )
  )
