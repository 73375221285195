// External Components
import React from 'react'
import { toast } from 'react-toastify';
import {Redirect, withRouter} from 'react-router-dom';

// Global Components
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { withUserStore } from 'stores/UserStore'

import './_.css'
import SpinnerComponent from "components/global/misc/SpinnerComponent";

class AuthComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      referrer: this.props.referrer || this.props.location.state? this.props.location.state.referrer || undefined : undefined,
      referrerParams : this.props.referrerParams || this.props.location.state? this.props.location.state.referrerParams || undefined : undefined
    };

    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onError = this.onError.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
  }

  onLogin(event) {
    event.preventDefault();
    const { userStore } = this.props;
    userStore.actions.login(this.state.username, this.state.password, this.onError)
      .catch(() => {
        this.onError();
      })
  }

  onLogout() {
    const { userStore } = this.props;
    userStore.actions.logout()
      .then(() => {
        this.props.history.push('/');
      })
      .catch(() => {
        this.props.history.push('/');
      })
  }

  onError() {
    const { localeStore } = this.props;
    toast.error( localeStore.intl.formatMessage({ id : 'retrievo.ui.auth.login.error'}), { toastId: 'login.error' });
  }

  onUsernameChange(event) {
    this.setState({ username: event.target.value })
  }

  onPasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  render() {
    const { localeStore, userStore } = this.props;
    const { operation } = this.props.match.params;
    const isLoggedIn = userStore.data.isLoggedIn;

    let content;
    if( operation === 'logout') {
      this.onLogout();
    } else if(isLoggedIn === undefined) {
      userStore.actions.getUser();
      content = <SpinnerComponent/>;
    } else if(!isLoggedIn) {
      content = (
          <div className='col-10 col-md-6 col-lg-4 login-container'>
            <h1 className='container-title'>
              {localeStore.intl.formatMessage({ id: 'retrievo.ui.auth.login.title' })}
            </h1>
            { this.renderAuthForm() }
          </div>
        )
    } else {
      return <Redirect to={{pathname: this.state.referrer || '/admin/data_sources', search: this.state.referrerParams}}/>
    }

    return (
      <React.Fragment>
        <NavbarComponent/>
        <section id='main-content' className='login'>
          <div className='container-fluid public-container'>
            <div className='row justify-content-center'>
              { content }
            </div>
          </div>
        </section>
        <FooterComponent/>
      </React.Fragment>
    );
  }

  renderAuthForm() {
    const { localeStore } = this.props;

    return (
      <React.Fragment>
        <form onSubmit={this.onLogin}>
          <div className='form-group'>
            <label htmlFor='login-username'>{localeStore.intl.formatMessage({ id: 'retrievo.ui.auth.login.username' })}</label>
            <input
              type='text'
              className='form-control'
              key='login-username'
              id='login-username'
              value={this.state.username}
              placeholder={localeStore.intl.formatMessage( { id: 'retrievo.ui.auth.login.username.placeholder', defaultMessage: ' ' } )}
              onChange={this.onUsernameChange} />
          </div>
          <div className='form-group'>
            <label htmlFor='login-password'>{localeStore.intl.formatMessage({ id: 'retrievo.ui.auth.login.password' })}</label>
            <input
              type='password'
              className='form-control'
              key='login-password'
              id='login-password'
              value={this.state.password}
              placeholder={localeStore.intl.formatMessage( { id: 'retrievo.ui.auth.login.password.placeholder', defaultMessage: ' ' } )}
              onChange={this.onPasswordChange} />
          </div>
          <button onClick={this.onLogin} type='submit' className='btn btn-primary'>
            {localeStore.intl.formatMessage({ id: 'retrievo.ui.auth.login.button' })}
          </button>
        </form>
      </React.Fragment>
    );
  }

}

export default
withLocaleStore(
  withUserStore(
    withRouter(
      AuthComponent
    )
  )
)
