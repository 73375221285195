import React, { Component } from 'react'

// Components
import SimpleSearchBarComponent from './SimpleSearchBarComponent'
import AdvancedSearchBarComponent from './AdvancedSearchBarComponent'

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'
import { withSearchStore } from 'stores/SearchStore'

import './_.css'


class SearchBarComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      advanced: props.advanced === undefined ?  (props.showAdvanced === undefined ? false :  props.showAdvanced) : false,
      showAdvanced: props.showAdvanced === undefined? true : props.showAdvanced,
      advancedFilterNext: 0,
      advancedFilterTotal: 3
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onAddMore = this.onAddMore.bind(this);
    this.onToggleAdvancedSearch = this.onToggleAdvancedSearch.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    const { searchStore } = this.props;
    let input = document.querySelector('.rbt-input-main');
    let currentInputValue = input? input.value : '';
    if(currentInputValue.length > 0) {
      searchStore.actions.updateFiltersAndResults([...searchStore.data.request.filters,{
        id: searchStore.data.request.filters.size + 1,
        key: '_all',
        field: '_all',
        label: 'Any: ' + currentInputValue,
        value: currentInputValue,
        operator: 'EQUALS'
      }]);
    } else {
      searchStore.actions.updateResults();
    }
  }

  onAddMore(event) {
    event.preventDefault();
    this.setState({ advancedFilterTotal: this.state.advancedFilterTotal + 1 });
  }

  onToggleAdvancedSearch(e) {
    e.preventDefault();
    this.setState({ advanced: !this.state.advanced });
  }

  render() {
    return (
      <div className='search-bar-container'>
        <form className='search-form' onSubmit={this.onSubmit}>
          {this.renderSearchBar()}
          {this.renderAdvancedSearchBarControls()}
        </form>
      </div>
    );
  }

  renderAdvancedSearchBarControls() {
    const { localeStore } = this.props;
    if(this.state.advanced) {
      return (
        <div className='search-bar-controls row'>
          <div className='search-bar-add col'>
            <button type='submit'
                   onClick={this.onAddMore}
                   className='btn btn-primary btn-primary'>
              <span className='fas fa-plus'/>
              <span className='btn-text'>{ localeStore.intl.formatMessage({id: 'retrievo.ui.search.advanced.add_more.button'}) }</span>
            </button>
          </div>
          <div className='search-bar-submit col'>
            <button type='submit' onClick={this.onSubmit} className='btn btn btn-primary'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'>
                <g id='search-icon'>
                  <path id='Path_2'
                        d='M16.215 14.629h-.835l-.3-.285a6.882 6.882 0 1 0-.74.74l.285.3v.835l5.286 5.275 1.575-1.575zm-6.343 0a4.757 4.757 0 1 1 4.757-4.757 4.751 4.751 0 0 1-4.757 4.757z'
                        className='cls-1' data-name='Path 2'/>
                  <path id='Path_3' d='M0 0h25v25H0z' className='cls-2' data-name='Path 3'/>
                </g>
              </svg>
              <span className='btn-text'>{ localeStore.intl.formatMessage({id: 'retrievo.ui.search.button'}) }</span>
            </button>
          </div>
        </div>
      );
    }
  }

  renderSearchBar() {
    if(this.state.advanced) {
      return this.renderAdvancedSearch();
    } else {
      return this.renderSimpleSearch()
    }
  }

  renderSimpleSearch() {
    const { localeStore, searchStore } = this.props;
    const selectedFilters = searchStore.data.request.filters
      .filter(filter => { return filter.value !== undefined && filter.value !== ''})
      .map(filter => {
        filter['label'] =
          localeStore.intl.formatMessage({ id: 'retrievo.ui.search.filters.' + searchStore.actions.getEntity() + '.' + filter['field'] + '.label' });
        return filter
      });
    return (
      <React.Fragment>
        <SimpleSearchBarComponent
          key={searchStore.data.request.filters.length}
          dynamicPlaceholder={this.getDynamicPlaceholder()}
          placeholder={localeStore.intl.formatMessage({id: 'retrievo.ui.search.placeholder'})}
          fields={selectedFilters}
          onChange={searchStore.actions.updateFilters}
          onSubmit={this.onSubmit}
          showSubmitButton={true}
          onChangeAndSubmit={searchStore.actions.updateFiltersAndResults}
        />
        {this.renderAdvancedSearchToggler()}
      </React.Fragment>
    );
  }

  renderAdvancedSearch() {
    const { localeStore, searchStore } = this.props;
    const selectedFilters = searchStore.data.request
      .filters.filter(filter => { return filter.field.startsWith('_all') });
    return (
      <div className='search-bar search-bar-advanced'>
        <div className='search-bar search-bar-simple'>
          <SimpleSearchBarComponent
            key={searchStore.data.request.filters.length}
            dynamicPlaceholder={this.getDynamicPlaceholder()}
            placeholder={localeStore.intl.formatMessage({id: 'retrievo.ui.search.placeholder'})}
            fields={selectedFilters}
            onChange={searchStore.actions.updateFilters}
            onSubmit={this.onSubmit}
            showSubmitButton={false}
            onChangeAndSubmit={searchStore.actions.updateFiltersAndResults}
          />
          {this.renderAdvancedSearchToggler()}
        </div>
        <div className='search-bar search-bar-advanced-forms'>
          {this.renderAdvancedSearchFields()}
        </div>
      </div>
    );
  }

  renderAdvancedSearchToggler() {
    const { localeStore } = this.props;

    if(this.state.showAdvanced) {
      return (
        <a href='#' className='search-bar-advanced-button' onClick={this.onToggleAdvancedSearch}>
          {localeStore.intl.formatMessage({id: 'retrievo.ui.search.advanced'})}
        </a>
      );
    }
  }

  renderAdvancedSearchFields() {
    const { localeStore, searchStore } = this.props;

    let activeSearchFields = this.getAdvancedFilters();
    let optionalSearchFields = this.getAdvancedFiltersFromConfiguration();
    activeSearchFields = activeSearchFields.filter(filter => { return !filter.field.startsWith('_all')});
    return activeSearchFields.map((searchField, i)=> {
      return <AdvancedSearchBarComponent
        key={'advanced-filter-' + i}
        selectedField={searchField}
        optionFields={optionalSearchFields}
        onChange={searchStore.actions.updateFilter}
        placeholder={localeStore.intl.formatMessage({id: 'retrievo.ui.search.placeholder'})}
        placeholderFrom={localeStore.intl.formatMessage({id: 'retrievo.ui.search.advanced.placeholder.from'})}
        placeholderTo={localeStore.intl.formatMessage({id: 'retrievo.ui.search.advanced.placeholder.to'})}
      />
    });
  }


  getAdvancedFilters() {
    const { searchStore } = this.props;

    let selectedAdvancedSearchFilters = [];
    let storedFilters = searchStore.data.request.filters;

    let storedSimpleSearchFilters = storedFilters.filter(filter => filter.field.startsWith('_all'));
    let storedAdvancedSearchFilters = storedFilters.filter(filter => !filter.field.startsWith('_all'));
    let configurationAdvancedSearchFilters = this.getAdvancedFiltersFromConfiguration();

    if(storedAdvancedSearchFilters.length === 0) {
      selectedAdvancedSearchFilters = storedFilters.concat(
        configurationAdvancedSearchFilters.filter( (filter, i) => i < this.state.advancedFilterTotal)
      );
      searchStore.actions.updateFilters(selectedAdvancedSearchFilters);
    } else if(storedAdvancedSearchFilters[0].id == null) {
      selectedAdvancedSearchFilters = storedAdvancedSearchFilters.map((filter, i) => {
        return this.createAdvancedFilter(filter, i);
      });
      searchStore.actions.updateFilters(storedSimpleSearchFilters.concat(selectedAdvancedSearchFilters));
    } else if(storedAdvancedSearchFilters.length < this.state.advancedFilterTotal) {
      selectedAdvancedSearchFilters = storedAdvancedSearchFilters.concat(
        this.getAdvancedFiltersFromConfiguration(this.state.advancedFilterTotal - storedAdvancedSearchFilters.length))
        .map((filter, i) => {
          return this.createAdvancedFilter(filter, i);
        });
      searchStore.actions.updateFilters(storedSimpleSearchFilters.concat(selectedAdvancedSearchFilters));
    } else {
      selectedAdvancedSearchFilters = storedAdvancedSearchFilters;
    }

    return selectedAdvancedSearchFilters.map((filter, i) => {
      return this.createAdvancedFilter(filter, i);
    });
  }


  getAdvancedFiltersFromConfiguration(number) {
    const { configStore, searchStore } = this.props;
    const allowedFields = configStore.actions.getProperty('retrievo.ui.search.filters.' + searchStore.actions.getEntity());

    let advancedFilters = [];
    let total = number === undefined ? allowedFields.length : number;
    let nextPosition = this.state.advancedFilterNext;

    for (let i = 0; i < total; i++) {
      if( nextPosition >= allowedFields.length ) {
        nextPosition = 0;
      }
      advancedFilters.push(this.createAdvancedFilter(allowedFields[nextPosition], i));
      nextPosition++;
    }

    return advancedFilters;
  }

  getSearchOperatorsFromConfiguration() {
    const { configStore, localeStore, searchStore } = this.props;
    const allowedOperators = configStore.actions.getProperty('retrievo.ui.search.filters.' + searchStore.actions.getEntity() + '.operators');
    return allowedOperators.map(operator => {
      return {
        key : operator,
        label : localeStore.intl.formatMessage({id : 'retrievo.ui.search.filters.operators.' + operator + '.label'})
      };
    });
  }

  getFilterOperatorsFromConfiguration() {
    const { configStore, localeStore, searchStore } = this.props;
    const allowedOperators = configStore.actions.getProperty('retrievo.ui.search.filters.' + searchStore.actions.getEntity() + '.operators');
    return allowedOperators.map(operator => {
      return {
        key : operator,
        label : localeStore.intl.formatMessage({id : 'retrievo.ui.search.filters.operators.' + operator + '.label'})
      };
    });
  }

  createAdvancedFilter(filter, pos) {
    const { configStore, localeStore, searchStore } = this.props;
    const filterOperators = this.getFilterOperatorsFromConfiguration();
    return {
      id : 'advanced-filter-' + pos,
      key : filter.field || filter,
      field: filter.field || filter,
      label : localeStore.intl.formatMessage({id : 'retrievo.ui.search.filters.' + searchStore.actions.getEntity() + '.' + (filter.field || filter) + '.label'}),
      type : configStore.actions.getProperty('retrievo.ui.search.filters.' + searchStore.actions.getEntity() + '.' + (filter.field || filter) + '.type'),
      value : filter.value || '',
      operator : filter.operator || filterOperators[0].key,
      operators:  filterOperators,
    };
  }

  getDynamicPlaceholder() {
    const { localeStore, searchStore } = this.props;
    let defaultPlaceholder = localeStore.intl.formatMessage({id: 'retrievo.ui.search.placeholder'});
    let sources = searchStore.data.result.facets.find((facet) => { return facet.field === 'dataSourceId' });
    let sourcesTotal = sources? sources.totalMatchCount : 0;
    return searchStore.data.result.total > 0? localeStore.intl.formatMessage({ 'id' : 'retrievo.ui.search.bar_info.' + searchStore.actions.getEntity(), defaultMessage: defaultPlaceholder},
      { total_records: searchStore.data.result.total.toLocaleString(), total_sources: sourcesTotal }) : '';
  }

}

export default
  withLocaleStore(
    withConfigStore(
      withSearchStore(
        SearchBarComponent
      )
    )
);

