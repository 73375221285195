// External Libs
import React from 'react'
import get from 'lodash/get'

// Components

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'

class SimpleFormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.onSimpleFormFieldChange = this.onSimpleFormFieldChange.bind(this);
  }

  render() {
    const { localeStore } = this.props;
    const { field, fieldName, entity, entityType, entityValidation } = this.props;

    let placeholder = localeStore.intl.formatMessage({
      id: 'retrievo.ui.entity.' + entityType + '.' + field + '.placeholder',
      defaultMessage: ' '
    });

    let value = get(entity, field) || '';
    return (
      <React.Fragment>
        <label htmlFor={'input-' + field}>{fieldName}</label>
        <input
          type='text'
          className='form-control'
          key={'input-' + field}
          id={'input-' + field}
          placeholder={placeholder}
          value={value}
          onChange={this.onSimpleFormFieldChange}
          data-target={field}/>
        <small
          id={'input-info-' + field}
          className='form-text text-muted'>
          {entityValidation[field] || ''}
        </small>
      </React.Fragment>
    );
  }

  onSimpleFormFieldChange(event) {
    let { onEntityChange, entity } = this.props;

    // stop event and collect form info
    event.preventDefault();
    let value = event.target.value;
    let field = event.target.dataset.target;

    // update entity
    entity[field] = value;
    onEntityChange(entity);
  }

}

export default withConfigStore(withLocaleStore(SimpleFormComponent))
