// External libs
import React, { Component } from 'react'

// Stores
import { withSearchStore } from 'stores/SearchStore'
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore';

// CSS
import './_.css'

class BadgeFacetsComponent extends Component {

  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  render() {
    return (
      <React.Fragment>
        {this.renderFacets()}
      </React.Fragment>
    );
  }

  renderFacets() {
    const { searchStore } = this.props;

    const allowedFacets = this.props.allowedFacets || undefined;
    const selectedFacets = searchStore.data.request.facets;
    let facetsElements = searchStore.data.result.facets.map((facet) => {
      if(allowedFacets !== undefined) {
        if(!allowedFacets.includes(facet.field)) {
          return;
        }
      }
      let selectedValues;

      for (let i = 0; i < selectedFacets.length ; i++) {
        if(selectedFacets[i]['field'] === facet.field) {
          selectedValues = selectedFacets[i]['selectedValues'];
        }
      }

      return this.renderFacet(facet, selectedValues);
    });

    if(searchStore.data.result.facets.length > 0) {
      if(facetsElements.length > 0) {
        return (
          <div className='badge-facets'>
            {facetsElements}
          </div>
        );
      }
    }

  }

  handleToggle(event) {
    event.preventDefault();
    const { searchStore } = this.props;
    let field = event.currentTarget.getAttribute('data-target');
    let value = event.currentTarget.getAttribute('data-info');
    let active = event.currentTarget.getAttribute('data-value') === 'true';
    searchStore.actions.toggleFacet(active, field, value, 'SimpleFacetValue');
  }

  renderFacet(facet, selectedValues) {
    const { localeStore } = this.props;
    let valueElements = facet.values.map((value, i) => {
      let label = localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.' +
          facet.field + '.' + value.label + '.label', defaultMessage: value.label, defaultValue: value.label});
      let active = selectedValues? selectedValues.includes(value.key) : false;
      return (
        <a key={i}
           href='#'
           onClick={this.handleToggle}
           data-target={facet.field}
           data-info={value.key}
           data-value={!active}
           className={'btn btn-sm ' + ( active? 'btn-primary' : 'btn-outline-primary') }>
          {label}
          <span className="badge badge-light">{value.count.toLocaleString()}</span>
        </a>
      );
    });
    return valueElements.length > 0 ? (
      <div className='badge-facet' key={'badge-facet-' + facet.field}>
        { valueElements }
      </div>
    ) : undefined

  }

}

export default withSearchStore(
  withConfigStore(
    withLocaleStore(
      BadgeFacetsComponent
    )
  )
);
