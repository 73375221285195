// External Libs/Components
import React, { Component } from 'react'
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'
import { toast } from 'react-toastify';


// Global Components
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'
import EntityComponent from 'components/entity/EntityComponent'
import SidebarComponent from 'components/layout/SidebarComponent'
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'
import CsvDataConnectorComponent from 'containers/admin/data_sources/data_connectors/CsvDataConnectorComponent'
import OaiPmhDataConnectorComponent from 'containers/admin/data_sources/data_connectors/OaiPmhDataConnectorComponent'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { EntityStore, EntityContext } from 'stores/EntityStore'

// CSS (css paths are the only relative paths)
import './_.css'
import FederatedDataConnectorComponent
  from "containers/admin/data_sources/data_connectors/FederatedDataConnectorComponent";

class DataSourceComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSourceWithConnectors : undefined,
    };
    moment.locale(props.localeStore.actions.getLocale());
    this.getDataSourceWithConnectors = this.getDataSourceWithConnectors.bind(this);
    this.handleAddConnector = this.handleAddConnector.bind(this);
    this.handleDataSourceChange = this.handleDataSourceChange.bind(this);
    this.handleDataSourceDelete = this.handleDataSourceDelete.bind(this);
    this.handleDataSourceUpdateVisibilityTask = this.handleDataSourceUpdateVisibilityTask.bind(this);
    this.handleDataConnectorChange = this.handleDataConnectorChange.bind(this);
    this.handleDataConnectorDelete = this.handleDataConnectorDelete.bind(this);
  }

  componentDidMount() {
    let { dataSourceId } = this.props.match.params;
    if(dataSourceId === 'create') {
      this.setState({ dataSourceWithConnectors : { dataConnectors : [] }});
    } else {
      this.getDataSourceWithConnectors(dataSourceId);
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname !== this.props.location.pathname) {
      let { dataSourceId } = this.props.match.params;
      if(dataSourceId === 'create') {
        this.setState({dataSourceWithConnectors : { dataConnectors : [] }});
      } else {
        this.getDataSourceWithConnectors(dataSourceId);
      }
    }
  }

  getDataSourceWithConnectors(dataSourceId) {
    if( dataSourceId !== undefined) {
      this.context.actions.getById(dataSourceId, { params: { expanded : true } })
        .then((result) => {
          this.setState({ dataSourceWithConnectors : result.data })
        })
        .catch((error) => {
          this.props.history.push({
            pathname: '/error',
            search: '?error_code=' + error.response.status
          });
        });
    }
  }

  render() {
    let dataSource = this.state.dataSourceWithConnectors;
    return (
      <React.Fragment>
        <EntityStore context='data_sources'>
          <NavbarComponent/>
          <section className='data-source'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-auto sidebar-container'>
                  <SidebarComponent/>
                </div>
                <div className='col admin-container'>
                  {this.renderBreadcrumb(dataSource)}
                  {this.renderHeader(dataSource)}
                  {this.renderDataSource(dataSource)}
                  {this.renderDataConnectors(dataSource)}
                </div>
            </div>
            </div>
          </section>
          <FooterComponent/>
        </EntityStore>
      </React.Fragment>
    );
  }

  renderBreadcrumb(dataSource) {
    let items = [];

    if(dataSource === undefined) {
      items = [];
    } else if (dataSource['id'] !== undefined) {
      items = [ { key: 'data_sources' }, { value: dataSource['name'] || dataSource['id'], target: '/#/admin/data_sources/' + dataSource['id'] }];
    } else {
      items = ['data_sources', 'data_sources.add'];
    }

    return (
      <div className='row'>
        <div className='col-12'>
          <BreadcrumbComponent
            items={items}
          />
        </div>
      </div>
    );
  }

  renderHeader(dataSource) {
    const { localeStore } = this.props;

    if(dataSource !== undefined) {
      let title = dataSource['id'] !== undefined ?
        localeStore.intl.formatMessage({id : 'retrievo.ui.data_source.title'}, { data_source: dataSource['name'] }) :
        localeStore.intl.formatMessage({id : 'retrievo.ui.data_source.create.title'});
      let description = dataSource['id'] !== undefined ?
        localeStore.intl.formatMessage({id : 'retrievo.ui.data_source.description'}) :
        localeStore.intl.formatMessage({id : 'retrievo.ui.data_source.create.description'});

      return (
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='container-title'>
              {title}
            </h1>
            <p className='container-description'>
              {description}
            </p>
          </div>
        </div>
      );
    }
  }

  renderDataSource(dataSource) {
    if(dataSource !== undefined) {
      let title = (
        <React.Fragment>
          <span className='fas fa-exchange-alt'/> {dataSource['name']}
        </React.Fragment>
      );
      return (
        <div className='row data-source-header'>
          <div className='col-12'>
            <EntityComponent
              key={dataSource['id'] || 'create'}
              title={title}
              context='data_sources'
              editable={dataSource['id'] !== undefined}
              deletable={dataSource['id'] !== undefined}
              edit={dataSource['id'] === undefined}
              entity={dataSource}
              entityType={'data_sources'}
              actions={this.renderDataSourceActions(dataSource)}
              formFields={
                [
                  'name',
                  'url',
                  'mail',
                  'description.descriptionsPerLocale.pt_PT',
                  'description.descriptionsPerLocale.en',
                  'tags.tagsByLocale.pt_PT',
                  'tags.tagsByLocale.en',
                  'thumbnail'
                ]
              }
              viewerFields={
                [
                  'url',
                  'mail',
                  'order',
                  'description.descriptionsPerLocale.pt_PT',
                  'description.descriptionsPerLocale.en',
                  'tags.tagsByLocale.en',
                  'tags.tagsByLocale.pt_PT',
                  'thumbnail'
                ]
              }
              onDelete={this.handleDataSourceDelete}
              onChange={this.handleDataSourceChange}
            />
          </div>
        </div>
      )
    }
  }

  renderDataSourceActions(dataSource) {
    const { localeStore } = this.props;
    return (
      <a href='#' className='btn btn-sm btn-outline-primary' onClick={this.handleDataSourceUpdateVisibilityTask}>
        { dataSource['visible']?
           <span className='fas fa-eye-slash'/> :
           <span className='fas fa-eye'/>
        }
        { dataSource['visible']?
           localeStore.intl.formatMessage({id: 'retrievo.ui.data_source.UPDATE_VISIBILITY.hide.create.button'}) :
           localeStore.intl.formatMessage({id: 'retrievo.ui.data_source.UPDATE_VISIBILITY.show.create.button'})
        }
      </a>
    )
  }

  renderAddMore(dataSource) {
    const { localeStore } = this.props;
    if(dataSource !== undefined) {
      if(dataSource['id'] !== undefined) {
        return (
          <div className='row data-connector-buttons'>
            <div className='col-12'>
              <button onClick={this.handleAddConnector} data-target='OAI_PMH' className='btn btn-outline-primary data-connector-button'>
                <span className='fas fa-plus'/>
                {localeStore.intl.formatMessage({ id: 'retrievo.ui.data_sources.data_connectors.add.OAI_PMH'})}
              </button>
              <button onClick={this.handleAddConnector} data-target='CSV' className='btn btn-outline-primary data-connector-button'>
                <span className='fas fa-plus'/>
                {localeStore.intl.formatMessage({ id: 'retrievo.ui.data_sources.data_connectors.add.CSV'})}
              </button>
              <button onClick={this.handleAddConnector} data-target='FEDERATED' className='btn btn-outline-primary data-connector-button'>
                <span className='fas fa-plus'/>
                {localeStore.intl.formatMessage({ id: 'retrievo.ui.data_sources.data_connectors.add.FEDERATED'})}
              </button>
            </div>
          </div>
        );
      }
    }
  }

  renderDataConnectors(dataSource) {
    const { localeStore } = this.props;

    let dataConnectors = (dataSource !== undefined) ?  dataSource['dataConnectors'] : [];
    let dataConnectorsElements = dataConnectors.map((dataConnector, i) => {
      let dataConnectorElement = undefined;
      let entity = cloneDeep(dataConnector);
        if(dataConnector['harvestMethod'] != null &&
           dataConnector['software'] != null &&
           dataConnector['type'] != null &&
           dataConnector['version'] != null) {
           entity['configuration'] = dataConnector['harvestMethod'] + '-'
             + dataConnector['software'] + '-'
             + dataConnector['type'] + '-'
             + dataConnector['version'];
        }

      switch (dataConnector['type']) {
        case 'OAI_PMH':
          dataConnectorElement =
            <OaiPmhDataConnectorComponent
              key={dataConnector['id']}
              dataConnector={dataConnector}
              dataSource={this.state.dataSourceWithConnectors}
              onChange={this.handleDataConnectorChange}
              onDelete={this.handleDataConnectorDelete}
            />;
          break;
        case 'CSV' :
          dataConnectorElement =
            <CsvDataConnectorComponent
              key={dataConnector['id']}
              dataConnector={dataConnector}
              dataSource={this.state.dataSourceWithConnectors}
              onChange={this.handleDataConnectorChange}
              onDelete={this.handleDataConnectorDelete}
            />;
          break;
        case 'FEDERATED' :
          dataConnectorElement =
            <FederatedDataConnectorComponent
              key={dataConnector['id']}
              dataSource={this.state.dataSourceWithConnectors}
              dataConnector={dataConnector}
              onDelete={this.handleDataConnectorDelete}
              onChange={this.handleDataConnectorChange}
          />;
          break;
        default:
          dataConnectorElement = <p>Not supported</p>;
        break;
      }
      return (
        <div key={'data-connector-' + i} className='col-12'>
          {dataConnectorElement}
        </div>
      );
    });

    if(dataSource !== undefined ) {
      if(dataSource['id'] !== undefined) {
        return (
          <div className='row data-connectors'>
            <div className='col-12 data-connector-button'>
              <h1 className='container-title'>
                {localeStore.intl.formatMessage({id : 'retrievo.ui.data_source.data_connectors.title'})}
              </h1>
              <p className='container-description'>
                {localeStore.intl.formatMessage({id : 'retrievo.ui.data_source.data_connectors.description'})}
              </p>
              {this.renderAddMore(dataSource)}
            </div>
            {dataConnectorsElements}
          </div>
        );
      }
    }
  }

  handleDataSourceChange(updatedDataSource) {
    if(this.props.location.pathname.includes(updatedDataSource['id'])) {
      let result = { ...updatedDataSource,
        dataConnectors: this.state.dataSourceWithConnectors['dataConnectors'] };
      this.setState( { dataSourceWithConnectors: result } )
    }
    else {
      this.props.history.push({
        pathname: '/admin/data_sources/' + updatedDataSource['id']
      });
    }
  }

  handleDataSourceDelete() {
    this.props.history.push({
      pathname: '/admin/data_sources',
    })
  }

  handleDataSourceUpdateVisibilityTask(event) {
    event.preventDefault();

    const { localeStore } = this.props;
    let dataSourceWithConnectors = this.state.dataSourceWithConnectors;

    this.context.actions.put({}, { context: 'data_sources/' + dataSourceWithConnectors['id'] + '/visibility' },
      { visible: !dataSourceWithConnectors['visible'] })
      .then((result) => {
        toast.success(
          <SuccessDialog
            taskId={result.data['id']}
            title={localeStore.intl.formatMessage({id: 'retrievo.ui.data_source.UPDATE_VISIBILITY.created.title'})}
            message={localeStore.intl.formatMessage({id: 'retrievo.ui.data_source.UPDATE_VISIBILITY.created.message'},
              { link: '/admin/tasks/'+ result.data['id'] })}
            successButton={localeStore.intl.formatMessage( { id: 'retrievo.ui.data_source.UPDATE_VISIBILITY.created.visit'})}
            cancelButton={localeStore.intl.formatMessage({id: 'retrievo.ui.data_source.UPDATE_VISIBILITY.created.dismiss'})}
          />
        );
      })
      .catch((error) => {
        let relatedResources = error.response? error.response.data['relatedResources'] : [];
        let errorMessage = localeStore.intl.formatMessage({id: 'retrievo.ui.data_connector.REPROCESS.create.error'},
          { relatedResource: relatedResources.length > 0? relatedResources[0] : '' });
        toast.error(<p dangerouslySetInnerHTML={{ __html: errorMessage}}/>);
      });
  }

  handleAddConnector(event) {
    event.preventDefault();
    const { localeStore } = this.props;
    let found = false;
    let type = event.currentTarget.dataset.target;
    let typeTranslated = localeStore.intl.formatMessage({ id: 'retrievo.ui.data_sources.data_connectors.add.' + type });
    let dataSourceWithConnectors = this.state.dataSourceWithConnectors;

    dataSourceWithConnectors['dataConnectors'].map((dataConnector) => {
      if(dataConnector['id'] === undefined) {
        found = true;
        dataConnector['name'] = dataSourceWithConnectors['name'] + ' - ' + typeTranslated;
        dataConnector['type'] = type
      }
    });

    if(!found) {
      dataSourceWithConnectors['dataConnectors'].unshift(
        {
          name: dataSourceWithConnectors['name'] + ' - ' + typeTranslated,
          type : type,
          dataSourceId: dataSourceWithConnectors['id']
        }
      );
    }

    this.setState( { dataSourceWithConnectors } );
  }

  handleDataConnectorChange(updatedDataConnector, notification) {
    let dataSourceWithConnectors = this.state.dataSourceWithConnectors;
    let dataConnectors = dataSourceWithConnectors['dataConnectors'] || [];
    dataSourceWithConnectors['dataConnectors'] = dataConnectors.map((dataConnector) => {
      if (dataConnector['id'] === undefined || dataConnector['id'] === updatedDataConnector['id']) {
        return updatedDataConnector
      }
      return dataConnector;
    });
    toast.success(notification);
    this.setState({ dataSourceWithConnectors });
  }

  handleDataConnectorDelete(deletedDataConnector) {
    let dataSourceWithConnectors = this.state.dataSourceWithConnectors;
    let dataConnectors = dataSourceWithConnectors['dataConnectors'];
    dataSourceWithConnectors['dataConnectors'] = dataConnectors
      .filter((dataConnector => (dataConnector['id'] !== undefined && dataConnector['id'] !== deletedDataConnector['id']) ));
    this.setState({ dataSourceWithConnectors });
  }

}

const SuccessDialog = ({ closeToast, title, message, taskId, successButton, cancelButton}) => (
  <div className='csv-file-submission'>
    <h2 className='toast-title' dangerouslySetInnerHTML={{__html : title}}/>
    <div className='toast-body'>
      <span className='toast-message' dangerouslySetInnerHTML={{__html : message}}/>
    </div>
    <div className='toast-actions'>
      <a href={'/#/admin/tasks/' + taskId} className='btn btn-primary' style={{marginRight: '.2rem'}}>{successButton}</a>
      <button className='btn btn-outline-primary' onClick={closeToast}>{cancelButton}</button>
    </div>
  </div>
);

DataSourceComponent.contextType = EntityContext;

export default withLocaleStore(DataSourceComponent);
