// External Libs
import React from 'react'
import get from 'lodash/get'

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'

class NumberFormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.onNumberFormFieldChange = this.onNumberFormFieldChange.bind(this);
  }

  render() {
    const { localeStore } = this.props;
    const { field, fieldName, entity, entityType, entityValidation } = this.props;

    let placeholder = localeStore.intl.formatMessage({
      id: 'retrievo.ui.entity.' + entityType + '.' + field + '.placeholder',
      defaultMessage: ' '
    });

    let value = get(entity, field);

    return (
      <React.Fragment>
        <label htmlFor={'input-' + field}>{fieldName}</label>
        <input
          type='number'
          placeholder={placeholder}
          className='form-control'
          key={'input-' + field}
          id={'input-' + field}
          value={value}
          onChange={this.onNumberFormFieldChange}
          data-target={field}/>
        <small
          id={'input-info-' + field}
          className='form-text text-muted'>
          {entityValidation[field] || ''}
        </small>
      </React.Fragment>
    );
  }

  onNumberFormFieldChange(event) {
    event.preventDefault();
      let { entity, onEntityChange } = this.props;
    let value = parseInt(event.target.value);
    let field = event.target.dataset.target;
    entity[field] = value;
    onEntityChange(entity);
  }

}

export default withConfigStore(withLocaleStore(NumberFormComponent))
