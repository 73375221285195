// External Libs
import React from 'react'
import get from 'lodash/get'

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'

class FileFormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.onFileFormFieldChange = this.onFileFormFieldChange.bind(this);
  }

  render() {
    const { localeStore, configStore, field, fieldName, entity, entityType, entityFiles } = this.props;

    let originalImage = get(entity, field) === undefined ? false : get(entity, field);
    let currentImage = entityFiles.filter((entityFile) => { return entityFile.field === field })[0] || undefined;
    let currentImageFilename = currentImage? currentImage['filename'] : undefined;
    let defaultPlaceholder = localeStore.intl.formatMessage({
      id: 'retrievo.ui.entity.' + entityType + '.' + field + '.placeholder',
      defaultMessage: ' '
    });

    let placeholder = currentImageFilename || originalImage  || defaultPlaceholder;
    let accept = configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.accept') || '';

    return (
      <React.Fragment>
        <label htmlFor='custom-file'>{fieldName}</label>
        <div id='custom-file' className='custom-file'>
          <input type='file' className='custom-file-input' id='input-file'
                 aria-describedby='input-file' onChange={(event) => { this.onFileFormFieldChange(event, field) }} accept={accept}/>
          <label className='custom-file-label' htmlFor='input-file'>
            { placeholder }
          </label>
        </div>
      </React.Fragment>
    );

  }

  onFileFormFieldChange(event, field) {
    let {entity, entityFiles, onEntityChange} = this.props;

    let file = event.target.files[0];
    let data = new FormData();

    data.append('file', file, file.name);
    entityFiles.push({field, data, filename: file.name});
    onEntityChange(entity, entityFiles);
  }

}

export default withConfigStore(withLocaleStore(FileFormComponent))
