import React, { Component } from 'react';
import moment from 'moment';
import get from 'lodash/get'

import { withConfigStore } from '../../../stores/ConfigStore';
import { withLocaleStore } from '../../../stores/LocaleStore';
import { withRouter } from 'react-router-dom';

class SearchResultComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expand: this.props.expand === undefined? true : this.props.expand,
      expanded: {},
    };
    moment.locale(props.localeStore.actions.getLocale());
    this.goTo = this.goTo.bind(this);
    this.onToggleExpanded = this.onToggleExpanded.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  goTo(resultId) {
    this.props.history.push({ pathname: '/' + this.props.context + '/' + resultId, state: { fromSearch: true } });
  }

  render() {
    const { results } = this.props;

    let items = results.map((result) => {
      return this.renderItem(result);
    });

    return (
      <ul className={'search-results-list' + (this.props.loading? ' loading' : '')}>
        { items }
      </ul>
    );

  }

  renderItem (result) {
    const { configStore } = this.props;

    let thumbnail = this.props.thumbnail === undefined? result['thumbnail'] !== undefined : this.props.thumbnail;
    let expanded = this.state.expanded[result['id']] === undefined ? false : this.state.expanded[result['id']];
    let visible = result.visible !== undefined ? result.visible : true;

    return (
      <li key={result['id']} className={'container-fluid search-result' + (expanded ?  ' search-result-expanded' : '') + (!visible? ' search-result-warning' : '' ) } >
        <div className='search-result-link' onClick={() => { this.goTo(result['id']) }}>
          <div className='row'>
            {thumbnail ?
              <div className='col-auto order-2 order-sm-2 search-result-thumbnail'>
                {
                  result['thumbnail'] !== null?
                    <img alt='thumbnail' className='search-result-source-thumbnail' src={configStore.actions.getApiUrl() + '/search/' + this.props.entity +'/' + result['id'] + '/thumbnail'}/> :
                    <img alt='thumbnail' className='search-result-default-thumbnail' src='assets/png/placeholder.png'/>
                }
              </div>
              : '' }
            <div className='col-12 col-sm order-3 order-sm-1 search-result-fields'>
              {this.renderFields(result) }
            </div>
            <div className='col-auto order-1 order-sm-3 search-result-actions'>
              { this.renderActions(result) }
            </div>
          </div>
        </div>
      </li>
    );
  }

  renderFields(result) {
    let { titleField, descriptionField, displayFields, expandedFields } = this.props;

    displayFields = displayFields || Object.keys(result) || [];
    expandedFields = expandedFields || [];
    titleField = titleField || displayFields[0] || undefined;
    descriptionField = descriptionField || undefined;
    let expanded = this.state.expanded[result['id']] === undefined ? false : this.state.expanded[result['id']];
    let fields = expanded ? displayFields.concat(expandedFields) : displayFields;
    return fields.map((field) => {
      let value = get(result,field);
      if( value != null ) {
        switch(field) {
          case titleField:
            return this.renderTitle(result, field, value);
          case descriptionField:
            return this.renderDescription(result, field, value);
          default: {
            return this.renderMetadata(result, field, value);
          }
        }
      }
    });
  }

  renderTitle(result, field, value) {
    return (
      <a onClick={(event) => { event.preventDefault(); this.goTo(result['id']); }} href={'#/' + this.props.context + '/' +result.id} key={field} className='search-result-title' >
        <h2 dangerouslySetInnerHTML={ {__html: Array.isArray(value) ? value.join(' \r\n ') : value } }/>
      </a>
    );
  }

  renderDescription(result, field, value) {
    const { localeStore } = this.props;
    return  (
      <p key={field}
         dangerouslySetInnerHTML={ {__html: Array.isArray(value) ? value[0] || localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.records.no-description.label' }) : value } }
         className='search-result-description'/>
    );
  }

  renderMetadata(result, field, value) {
    const { configStore, localeStore } = this.props;
    let values = [];
    let fieldsAsFilter = configStore.actions.getProperty('retrievo.ui.links.records.as_filter') || [];
    let fieldsAsFacets = configStore.actions.getProperty('retrievo.ui.links.records.as_facet') || [];
    let fieldsAsLinks = configStore.actions.getProperty('retrievo.ui.links.records.as_link') || [];
    let linkJustFirst = configStore.actions.getProperty('retrievo.ui.links.records.as_link_just_first') === 'true';
    let addFirst = true;

    if(Array.isArray(value)) {
      values = value.map((elem, i) => {
        if(fieldsAsFilter.includes(field)) {
          return (
            <span className='search-result-value' key={i}>
              <a href={'/#/records?filters.0.' + field + '.equals='+elem.replace(/<[^>]+>/g, '')} dangerouslySetInnerHTML={ { __html : elem } }/>
            </span>
          );
        } else if(fieldsAsFacets.includes(field)) {
          let label = localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.records.' +
              field + '.' + elem.replace(/<[^>]+>/g, '') + '.label', defaultMessage: elem});
          return (
            <span className='search-result-value' key={i}>
              <a href={'/#/records?facets.' + field + '.selected='+elem.replace(/<[^>]+>/g, '')} dangerouslySetInnerHTML={ { __html : label } }/>
            </span>
          );
        } else if(fieldsAsLinks.includes(field)) {
          if(linkJustFirst && addFirst){
            addFirst = false;
            return (
              <span className='search-result-value' key={i}>
                <a href={elem} onClick={event => {event.stopPropagation()}} target='_blank' rel='noopener noreferrer' dangerouslySetInnerHTML={ { __html : elem } }/>
              </span>
            );
          } else if(!linkJustFirst){
            return (
              <span className='search-result-value' key={i}>
                <a href={elem} onClick={event => {event.stopPropagation()}} target='_blank' rel='noopener noreferrer' dangerouslySetInnerHTML={ { __html : elem } }/>
              </span>
            );
          }
        } else if(typeof elem === 'string') {
          return <span className='search-result-value' key={i}>{elem.replace(/<[^>]+>/g, '')}</span>
        }
      })
    } else if(field === 'dataSourceName') {
      values.push(
        <span className='search-result-value' key='0'>
          <a href={'/#/records?facets.dataSourceId.selected=' + get(result,'dataSourceId')} dangerouslySetInnerHTML={ { __html : value } }/>
        </span>
      );
    } else if(typeof value === 'string' || typeof value === 'number') {
      values.push(<span className='search-result-value' key='0'>{value}</span>);
    }

    return values.length > 0? (
      <span key={field} className='search-result-generic'>
        <span className='search-result-key' key={field + '-label'}>
          {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.'+ this.props.entity +'.' + field.replace(/\[\d+\]/,'') + '.label'}) + ': '}
        </span>
        <span className='search-result-values' key={field + '-values'}>
          { values }
        </span>
      </span>
    ) : '';
  }

  onToggleExpanded(event) {
    event.preventDefault();
    event.stopPropagation();
    let expanded = this.state.expanded;
    let id = event.currentTarget.dataset.target;
    expanded[id] = expanded[id] === undefined ? true : !expanded[id];
    this.setState({ expanded });
  }

  onExternal() {
    window.open(URL, '_blank');
  }

  renderActions(result) {
    const { localeStore, renderActions } = this.props;

    let visible = result.visible !== undefined ? result.visible : true;
    let expanded = this.state.expanded[result['id']] === undefined ? false : this.state.expanded[result['id']];
    let expandedElement = (
      <li>
        <a href='#' onClick={this.onToggleExpanded} data-target={result['id']} >
          { expanded ?
            <span className='fas fa-caret-up fa-lg' title={localeStore.intl.formatMessage({ id: 'retrievo.ui.records.less-info' })}/> :
            <span className='fas fa-caret-down fa-lg' title={localeStore.intl.formatMessage({ id: 'retrievo.ui.records.more-info' })}/>
          }
          { expanded ?
            <span className='d-inline-block d-sm-none'>{localeStore.intl.formatMessage({ id: 'retrievo.ui.records.less-info' })}</span> :
            <span className='d-inline-block d-sm-none'>{localeStore.intl.formatMessage({ id: 'retrievo.ui.records.more-info' })}</span>
          }
        </a>
      </li>
    );

    let notVisibleElement = <li><a><span className='fas fa-eye-slash fa-lg'/></a></li>;

    return (
      <ul>
        { this.state.expand ? expandedElement : ''}
        { renderActions === undefined ? '' : renderActions(result) }
        { !visible? notVisibleElement : '' }
      </ul>
    );
  }

}

export default withLocaleStore(
  withConfigStore(
    withRouter(SearchResultComponent)
  )
);
