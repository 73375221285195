// External Libs/Components
import React from 'react'

// Global Components
import EntityComponent from 'components/entity/EntityComponent'

// Stores
import { EntityContext } from 'stores/EntityStore'
import { withLocaleStore } from 'stores/LocaleStore'
import { withRouter } from 'react-router-dom'

class FederatedDataConnectorComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { localeStore } = this.props;
    const { dataSource, dataConnector } = this.props;
    const { onChange, onDelete } = this.props;

    let context = 'data_sources/' + dataSource['id'] + '/data_connectors';
    let link = '/admin/' + context + '/' + dataConnector['id'];
    let entityOptions= {};

    let title = (
      <React.Fragment>
        <span className='fas fa-cog'/>
        { dataConnector['name'] || localeStore.intl.formatMessage({ id: 'retrievo.ui.data_connector.FEDERATED.create'}) }
      </React.Fragment>
    );

    let viewerFields = [
      'federated_slug',
      'args',
      'harvest',
      'lastHarvest',
      'recordsValid'
    ];

    let formFields = [
      'name',
      'federated_slug',
      'args',
      'args_info',
      'harvest'
    ];

    if(!dataConnector['federated_slug'] && dataConnector['software']) {
      dataConnector['federated_slug'] = dataConnector['slug'];
    }


    return (
      <div className='row data-connector'>
        <EntityComponent
          title={title}
          context={context}
          link={'/#' + link}
          edit={dataConnector.id === undefined}
          entity={dataConnector}
          entityOptions={entityOptions}
          entityType={'data_connectors'}
          visitable={link !== this.props.location.pathname}
          viewerFields={viewerFields}
          formFields={formFields}
          onDelete={onDelete}
          onChange={onChange}
        />
      </div>
    );
  }
}


FederatedDataConnectorComponent.contextType = EntityContext;



export default withRouter(
  withLocaleStore( FederatedDataConnectorComponent )
);
