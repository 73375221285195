// External Libs
import React from 'react'
import assign from 'lodash/assign'

// Components
import DropdownComponent from 'components/global/elements/DropdownComponent'

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'

class SelectFormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.onSelectFormFieldChange = this.onSelectFormFieldChange.bind(this);
  }

  render() {
    const { configStore, onEntityChange, field, fieldName, entityType } = this.props;
    let { entity } = this.props;

    let defaultValueKey = entity[field] || configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.defaultValue');
    let defaultValue = configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.options.' + defaultValueKey + '.value') || defaultValueKey;
  
    if(entity[field] === undefined) {
      entity[field] = defaultValueKey;
      if(this.isJson(defaultValue)) {
        entity = assign(entity, JSON.parse(defaultValue));
      }
      onEntityChange(entity);
    }

    let selected = {
      key: defaultValueKey,
      label: configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.options.' + defaultValueKey + '.label')
    };

    let optionFields = configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.options') || [];

    let options = optionFields.map( (optionField) => {
      return {
        key: optionField,
        label: configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.options.' + optionField + '.label')
      }
    });

    return (
      <React.Fragment>
        <label htmlFor={'input-' + field}>{fieldName}</label>
        <DropdownComponent
          id={'input-' + field}
          selected={selected}
          options={options}
          onChange={(selected) => { this.onSelectFormFieldChange(field, selected); }}/>
      </React.Fragment>
    );
  }

  onSelectFormFieldChange(field, selectedOption) {
    const { configStore, entityType } = this.props;
    let { onEntityChange, entity} = this.props;
    let value = configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.options.' + selectedOption + '.value');
    entity[field] = selectedOption;
    if(this.isJson(value)) {
      value = JSON.parse(value);
    }
    entity = assign(entity, value);
    onEntityChange(entity);
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}

export default withConfigStore(withLocaleStore(SelectFormComponent))
