// External Libs/Components
import React, { Component } from 'react';

import FooterComponent from 'components/layout/FooterComponent';
import NavbarComponent from 'components/layout/NavbarComponent';

import { withLocaleStore } from 'stores/LocaleStore';
import { withConfigStore } from "stores/ConfigStore";

class AboutComponent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <React.Fragment>
        <NavbarComponent/>
        <section id='main-content' className='help'>
          {this.renderHeader()}
          {this.renderContent()}
        </section>
        <FooterComponent/>
      </React.Fragment>
    );
  }


  renderHeader() {
    const { configStore, localeStore } = this.props;
    let html =
      configStore.actions.getProperty('retrievo.ui.help.intro.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.help.intro.' + localeStore.actions.getLocaleWithoutRegionCode());
    return (
      <div className='public-header'>
        <div className='container-fluid public-container'>
          <div className='row'>
            <div className='col-12'
                 dangerouslySetInnerHTML={{ __html: html } }/>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { configStore, localeStore } = this.props;
    let html =
      configStore.actions.getProperty('retrievo.ui.help.content.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.help.content.' + localeStore.actions.getLocaleWithoutRegionCode());
    return (
      <div className='container-fluid public-container'>
        <div className='row'>
          <div className='col-12'
               dangerouslySetInnerHTML={{ __html: html}}/>
        </div>
      </div>
    );
  }
}

export default withLocaleStore(withConfigStore(AboutComponent));
