// External Libs/Components
import React, { Component } from 'react'

// Global Components
import SearchBarComponent from 'components/search/bars/SearchBarComponent'
import SearchResultsComponent from 'components/search/results/SearchResultsComponent'
import TaskSearchResultComponent from '../../tasks/search/TaskSearchResultComponent'
import BadgeFacetsComponent from 'components/search/facets/BadgeFacetsComponent'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { SearchContext, SearchStore } from 'stores/SearchStore'
import { withRouter } from 'react-router-dom'

// CSS (css paths are the only relative paths)
import '../_.css';

class DataConnectorTasksComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.context.actions.updateResults('?facets.dataConnectorId.selected='+this.props.dataConnector['id'], false);
  }

  render() {
    const { dataConnector } = this.props;
    if(dataConnector !== undefined ) {
      return (
        <SearchStore entity='tasks' context='/#/admin/tasks' redirect={false}>
          <div className='row data-connector-tasks'>
            <div className='col-12'>
              <SearchBarComponent showAdvanced={false}/>
            </div>
            <div className='col-12'>
              <BadgeFacetsComponent allowedFacets={["state", "type"]}/>
            </div>
            <div className='col-12'>
              <SearchResultsComponent SearchResultComponent={TaskSearchResultComponent}/>
            </div>
          </div>
        </SearchStore>
      );
    }
  }
}

DataConnectorTasksComponent.contextType = SearchContext;

export default withRouter(withLocaleStore(DataConnectorTasksComponent));
