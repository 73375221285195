// External Libs/Components
import React, { Component } from 'react';

import FooterComponent from 'components/layout/FooterComponent';
import NavbarComponent from 'components/layout/NavbarComponent';

import { withLocaleStore } from 'stores/LocaleStore';
import { withConfigStore } from "stores/ConfigStore";

class CookiesComponent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <NavbarComponent/>
        <section id='main-content' className='cookies'>
          {this.renderHeader()}
          {this.renderContent()}
        </section>
        <FooterComponent/>
      </React.Fragment>
    );
  }


  renderHeader() {
    const { localeStore } = this.props;
    return (
      <div className='public-header'>
        <div className='container-fluid public-container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='container-title'>{localeStore.intl.formatMessage({ 'id' : 'retrievo.ui.cookies.title' })}</h1>
              <p className='container-description'>{localeStore.intl.formatMessage({ 'id' : 'retrievo.ui.cookies.description' })}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { localeStore } = this.props;
    return (
      <div className='container-fluid public-container'>
        <div className='row'>
          <div className='col-12'
               dangerouslySetInnerHTML={{ __html: localeStore.intl.formatMessage({ 'id' : 'retrievo.ui.cookies.content' })}}/>
        </div>
      </div>
    );
  }
}

export default withLocaleStore(withConfigStore(CookiesComponent));
