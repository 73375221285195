// External
import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import set from 'lodash/set'
import { toast } from 'react-toastify';

// Components
import MultiSelectFormComponent from './form/MultiSelectFormComponent'
import SimpleFormComponent from './form/SimpleFormComponent'
import TextAreaFormComponent from './form/TextAreaFormComponent'
import SelectFormComponent from './form/SelectFormComponent'
import NumberFormComponent from 'components/entity/form/NumberFormComponent';
import BooleanFormComponent from 'components/entity/form/BooleanFormComponent';
import FileFormComponent from 'components/entity/form/FileFormComponent';
import SourceViewerComponent from 'components/entity/viewer/SourceViewerComponent';
import DateFormComponent from 'components/entity/form/DateFormComponent';

// Stores
import { withLocaleStore } from 'stores/LocaleStore';
import { withConfigStore } from 'stores/ConfigStore';
import { EntityContext, EntityStore } from 'stores/EntityStore';

import './_.css'

class EntityComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editable: props.editable === undefined? true : props.editable,
      deletable: props.deletable === undefined? true : props.deletable,
      visitable: props.visitable === undefined? false : props.visitable,
      submit: props.submit !== undefined? props.submit : true,
      submitting: false,
      edit: props.edit !== undefined? props.edit : false,
      title: props.title,
      originalEntity: cloneDeep(props.entity) || {},
      entity: cloneDeep(props.entity) || {},
      entityOptions: props.entityOptions || {},
      entityType: props.entityType || props.context || '',
      entityValidation: {},
      entityFiles: [],

    };
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitInternal = this.onSubmitInternal.bind(this);
    this.onFileSubmit = this.onFileSubmit.bind(this);
    this.onEntityChange = this.onEntityChange.bind(this);
  }

  render() {
    return (
      <div className='entity'>
        <EntityStore context={this.props.context}>
          { this.renderHeader() }
          { this.state.edit ? this.renderForm(this.state.entity) : this.renderViewer(this.state.entity) }
          { this.renderFooter() }
        </EntityStore>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if(prevProps !== this.props) {
      this.setState({
        ...this.state,
        title: this.props.title,
        entity: cloneDeep(this.props.entity) || {}
      })
    }
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  onEdit(event) {
    event.preventDefault();
    let { localeStore } = this.props;
    let { edit, entity, originalEntity } = this.state;
    let editDialogTitle =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType + '.edit_dialog.title'});
    let editDialogMessage =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.edit_dialog.description'});
    let editDialogCancelButton =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.edit_dialog.cancel'});
    let editDialogConfirmButton =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.edit_dialog.confirm'});

    if(entity['id'] === undefined && edit === true) {
      toast.success(
        <ConfirmationDialog
          title={editDialogTitle}
          message={editDialogMessage}
          confirmButton={editDialogConfirmButton}
          cancelButton={editDialogCancelButton}
          successToast={ () => {
            this.props.onDelete(entity);
          }}
        />,
        { autoClose: false, closeOnClick: false }
      );
    }
    else if(edit === true && !isEqual(originalEntity, entity)) {
      toast.success(
        <ConfirmationDialog
          title={editDialogTitle}
          message={editDialogMessage}
          confirmButton={editDialogConfirmButton}
          cancelButton={editDialogCancelButton}
          successToast={ () => {
            this.setState({ edit: !edit, entity: originalEntity}); }
          }
        />,
        { autoClose: false, closeOnClick: false }
      );
    } else {
      this.setState({ edit: !edit });
    }
  }

  onSubmit(event) {
    event.preventDefault();

    const { onChange } = this.props;

    this.setState({ submitting: !this.state.submitting }, () => {
      if(this.state.submit) {
        this.onSubmitInternal();
      } else {
        onChange(this.state.entity);
      }
    });

  }

  onSubmitInternal() {
    const { configStore, localeStore, onChange } = this.props;

    let currentEntity = this.state.entity;
    let updatedEntity = {};

    let submitDialogTitle =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType + '.submit_dialog.title'});
    let submitDialogSuccessMessage =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.submit_dialog.description'});
    let submitDialogErrorMessage =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.submit_dialog.error'});
    let submitDialogFileErrorMessage =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.submit_dialog.error'});

    Object.keys(currentEntity).map( (entityKey) => {
      let type = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.' + entityKey + '.viewer.form');
      if (type !== 'file') {
        set(updatedEntity, entityKey, currentEntity[entityKey])
      }
    });

    let response = updatedEntity['id'] === undefined?
      this.context.actions.post(updatedEntity, { context: this.props.context }) :
      this.context.actions.put(updatedEntity, { context : this.props.context });

    response
      .then((result) => {
        currentEntity['id'] = result.data['id'];
        updatedEntity = result.data;
        this.onFileSubmit(updatedEntity, this.state.entityFiles)
          .then(() => {
            return submitDialogSuccessMessage
          })
          .catch(() => {
            return submitDialogFileErrorMessage
          })
          .finally((message) => {
            if(onChange) {
              onChange(updatedEntity, (
                <div>
                  <h2 className='toast-title'>{submitDialogTitle}</h2>
                  <span className='toast-message'>{message}</span>
                </div>
              ));
            } else {
              toast.success(
                <div>
                  <h2 className='toast-title'>{submitDialogTitle}</h2>
                  <span className='toast-message'>{message}</span>
                </div>
              );
            }
            this.setState({edit: false, submitting: false});
          })

      })
      .catch(() => {
        toast.error(
          <div>
            <h2 className='toast-title'>{submitDialogTitle}</h2>
            <span className='toast-message'>{submitDialogErrorMessage}</span>
          </div>
        );
      })
  }

  onFileSubmit(entity, entityFiles) {
    let promises = entityFiles.map( (entityFile) => {
      return this.context.actions
        .post(entityFile['data'],
          { context: this.state.entityType + '/' + entity.id + '/' + entityFile['field']});
    });
    return Promise.all(promises);
  }

  onDelete(event) {
    event.preventDefault();
    let { localeStore } = this.props;
    let deleteDialogTitle =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType + '.delete_dialog.title'});
    let deleteDialogMessage =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.delete_dialog.description'});

    let deleteDialogCancelButton =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.delete_dialog.cancel'});
    let deleteDialogConfirmButton =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.delete_dialog.confirm'});

    let deleteDialogSuccessMessage =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.delete_dialog.success'});
    let deleteDialogErrorMessage =
      localeStore.intl.formatMessage({id: 'retrievo.ui.entity.' + this.state.entityType +'.delete_dialog.error'});

    let entity = this.state.entity;

    if(entity['id'] === undefined) {
      if(this.props.onDelete) {
        toast.success(
          <ConfirmationDialog
            title={deleteDialogTitle}
            message={deleteDialogMessage}
            confirmButton={deleteDialogConfirmButton}
            cancelButton={deleteDialogCancelButton}
            successToast={ () => {
              this.props.onDelete(entity);
            }}
          />,
          { autoClose: false, closeOnClick: false }
        );
      }
    } else {
      toast.success(
        <ConfirmationDialog
          title={deleteDialogTitle}
          message={deleteDialogMessage}
          confirmButton={deleteDialogConfirmButton}
          cancelButton={deleteDialogCancelButton}
          successToast={ () => {
            this.context.actions.delete(entity, { context : this.props.context })
              .then((result) => {
                if(this.props.onDelete) {
                  this.props.onDelete(entity);
                }
                deleteDialogSuccessMessage = localeStore.intl.formatMessage(
                  {id: 'retrievo.ui.entity.' + this.state.entityType +'.delete_dialog.success'},
                  {related_resource: result.data['id'], name_to_delete: result.data['nameToDelete']}
                );
                toast.success(
                  <div>
                    <h2 className='toast-title'>{deleteDialogTitle}</h2>
                    <span className='toast-message' dangerouslySetInnerHTML={{__html: deleteDialogSuccessMessage}}/>
                  </div>
                );
                this.setState({ edit: false });
              })
              .catch(() => {
                toast.error(
                  <div>
                    <h2 className='toast-title'>{deleteDialogTitle}</h2>
                    <span className='toast-message' dangerouslySetInnerHTML={{__html: deleteDialogErrorMessage}}/>
                  </div>
                );
              })
          }}
        />,
        { autoClose: false, closeOnClick: false }
      );
    }
  }

  onEntityChange(entity, entityValidation, entityFiles) {
    this.setState({
      entity: entity,
      entityValidation: entityValidation || this.state.entityValidation,
      entityFiles: entityFiles || this.state.entityFiles
    });
  }

  getFieldName(field) {
    const { localeStore } = this.props;
    return localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.' + this.state.entityType + '.' + field + '.label'});
  }

  renderHeader() {
    if(this.state.visitable || this.state.editable || this.state.deletable || this.props.actions !== undefined) {
      return (
        <div className='entity-header'>
          <div className='row'>
            <div className='col-12 col-xl'>
              <h2 className='entity-title'>
                {this.state.title}
              </h2>
            </div>
            <div className='col-12 col-xl-auto'>
              { this.renderControls() }
            </div>
          </div>
        </div>
      );
    }
  }

  renderFooter() {
    const { localeStore } = this.props;

    if(this.state.edit) {
      return (
        <div className='entity-footer'>
          <div className='row'>
            <div className='col-12'>
              <div className='entity-controls'>
                <button
                   type='button'
                   className='btn btn-sm btn-primary'
                   onClick={this.onSubmit}
                   disabled={this.state.submitting} >
                  <span className='fas fa-save'/>
                  {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.actions.save' })}
                </button>
                <button
                   type='button'
                   className='btn btn-sm btn-outline-primary'
                   onClick={this.onEdit}>
                  <span className='fas fa-times'/>
                  {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.actions.cancel' })}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderControls() {
    const { localeStore } = this.props;
    let actions = [];

    if(this.state.visitable && !this.state.edit) {
      actions.unshift(
        <a key='entity-action-details' href={this.props.link} className='btn btn-sm btn-outline-primary'>
          <span className='fas fa-info'/>
          {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.actions.details' })}
        </a>
      )
    }

    if(this.state.editable && this.state.edit) {
      actions.push(
        <a href='#'
           key='entity-action-edit'
           onClick={this.onSubmit}
           className={'btn btn-sm ' + (this.state.edit? 'btn-primary' : 'btn-outline-primary') }
           data-toggle='tooltip'
           data-placement='top'
           title='Edit'>
          <span className='fas fa-save'/>
          {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.actions.save' })}
        </a>
      );
      actions.push(
        <a href='#'
           key='entity-action-edit-cancel'
           onClick={this.onEdit}
           className='btn btn-sm btn-outline-primary'
           data-toggle='tooltip'
           data-placement='top'
           title='Edit'>
          <span className='fas fa-times'/>
          {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.actions.cancel' })}
        </a>
      )
    } else if(this.state.editable && !this.state.edit) {
      actions.push(
        <a href='#'
           key='entity-action-edit-cancel-2'
           onClick={this.onEdit}
           className={'btn btn-sm ' + (this.state.edit? 'btn-primary' : 'btn-outline-primary') }
           data-toggle='tooltip'
           data-placement='top'
           title='Tooltip on top'>
          <span className='fas fa-pencil-alt'/>
          {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.actions.edit' })}
        </a>
      );
    }

    if(this.state.deletable && !this.state.edit) {
      actions.push(
        <a href='#'
           key='entity-action-details-delete'
           onClick={this.onDelete}
           className='btn btn-sm btn-outline-danger'>
          <span className='fas fa-trash'/>
          {localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.actions.delete' })}
        </a>
      )
    }

    return (
      <div className='entity-controls'>
        { this.state.edit? '' : this.props.actions }
        { actions }
      </div>
    );
  }

  renderViewer(entity) {
    const { configStore, localeStore, viewerFields } = this.props;
    let sections = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.sections') || [];


    let elements;

    if (sections.length > 0 ) {
      elements = sections.map((section, i) => {
        let sectionFields = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.sections.' + section) || [];
        let sectionCollapsed = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.sections.' + section + '.collapsed') || false;
        let sectionFieldsElements = sectionFields.map((sectionField) => {
          return this.renderViewerField(sectionField, entity);
        });
        return (
          <div className='entity-section' key={'entity-section-' + i}>
            <div className='row'>
              <div className='col-12'>
                <a className='entity-section-button' data-toggle='collapse' href={'#entity-section-' + i} role='button' aria-expanded={sectionCollapsed? 'false' : 'true'} aria-controls='entity-section' >
                  <h3 className={'entity-section-title'}>
                    { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.' + this.state.entityType + '.sections.' + section + '.title' }) }
                  </h3>
                  <span className='fas button-caret entity-section-icon'/>
                </a>
              </div>
              <div id={'entity-section-' + i} className={'col-12 collapse' + (sectionCollapsed? '' : ' show')}>
                <div className='row'>
                  {sectionFieldsElements}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }  else {
      let entityFields = viewerFields || this.props.fields || Object.keys(entity);
      let entityElements = entityFields.map((entityField) => {
        return this.renderViewerField(entityField, entity);
      });
      elements = <div className='row'>{entityElements}</div> ;
    }

    return (
      <div className='entity-viewer'>
          { elements }
      </div>
    );
  }

  renderViewerField(field, entity) {
    const { configStore } = this.props;
    let fieldType = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.' + field + '.viewer.type')
      || typeof get(entity, field);
    let columns = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.' + field + '.columns') || 12;
    let fieldElement;

    switch (fieldType) {
      case 'text': case 'string':
        fieldElement = this.renderSimpleViewerField(field, entity);
        break;
      case 'number':
        fieldElement = this.renderNumberViewerField(field, entity);
        break;
      case 'boolean':
        fieldElement = this.renderBooleanViewerField(field, entity);
        break;
      case 'file':
        fieldElement = this.renderImageViewerField(field, entity);
        break;
      case 'select':
        fieldElement = this.renderSelectViewerField(field, entity);
        break;
      case 'multi-select' :
        fieldElement = this.renderMultiSelectViewerField(field, entity);
        break;
      case 'src' :
        fieldElement = <SourceViewerComponent
          field={field}
          fieldName={this.getFieldName(field)}
          entity={this.state.entity}
          entityType={this.state.entityType}
        />;
        break;
      default:
        fieldElement = this.renderSimpleViewerField(field, entity);
    }

    return (
      <div key={'entity-viewer-' + field} className={'form-group col-12' + ' col-lg-' + columns }>
        { fieldElement }
      </div>
    );

  }

  renderSimpleViewerField(field, entity) {
    const { localeStore } = this.props;
    let value = get(entity, field);
    let element;
    if(value === null || value === undefined) {
      element = <span style={{color: 'var(--grey-dark)'}}>
        { localeStore.intl.formatMessage({id : 'retrievo.ui.entity.unavailable', defaultMessage: ''})}
      </span>
    } else if(value.length > 0) {
      element = <span>{value}</span>
    }

    return (
      <dl>
        <dt>{this.getFieldName(field)}</dt>
        <dd>{element}</dd>
      </dl>
    );

  }

  renderNumberViewerField(field, entity) {
    return (
      <dl>
        <dt>{this.getFieldName(field)}</dt>
        <dd>
          <span>{entity[field]}</span>
        </dd>
      </dl>
    );
  }

  renderBooleanViewerField(field, entity) {
    let value = get(entity, field) === undefined ? false : get(entity, field);
    if(value) {
      return (
        <React.Fragment>
          <label htmlFor={'input-' + field}>{this.getFieldName(field)}</label>
          <span style={{display: 'block'}}>
            <button className='btn btn-sm btn-secondary disabled' value='true' data-target={field} disabled>On</button>
            <button className='btn btn-sm btn-outline-danger disabled' value='false' data-target={field} disabled>Off</button>
          </span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <label htmlFor={'input-' + field}>{this.getFieldName(field)}</label>
          <span style={{display: 'block'}}>
            <button className='btn btn-sm btn-outline-secondary disabled' value='true' data-target={field}>On</button>
            <button className='btn btn-sm btn-danger disabled' value='false' data-target={field}>Off</button>
          </span>
        </React.Fragment>
      );
    }
  }

  renderImageViewerField(field, entity) {
    const { configStore, localeStore } = this.props;

    return (
      <dl id={'input-' + field}>
        <dt>{this.getFieldName(field)}</dt>
        <dd id={'input-' + field + '-error'} style={{display: 'none'}} >{ localeStore.intl.formatMessage({id : 'retrievo.ui.entity.unavailable', defaultMessage: ''})}</dd>
        <img alt='thumbnail'
             src={configStore.actions.getApiUrl() + '/' + this.props.context + '/' + entity['id'] + '/thumbnail'}
             onError={(event) => {
               event.currentTarget.style.display = 'none';
               event.currentTarget.previousSibling.style.display = 'block';}
             } />
      </dl>
    );
  }

  renderSelectViewerField(field, entity) {
    const {configStore} = this.props;
    let {entityType} = this.state;

    let defaultValueKey = entity[field] ||
      configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.' + field + '.form.defaultValue');
    let selected = {
      key: defaultValueKey,
      label: configStore.actions.getProperty('retrievo.ui.entity.' + entityType + '.' + field + '.form.options.' + defaultValueKey + '.label')
    };

    return (
      <dl>
        <dt>{this.getFieldName(field)}</dt>
        <dd>{selected.label}</dd>
      </dl>
    );
  }

  renderMultiSelectViewerField(field, entity) {
    let values = get(entity, field) === undefined ? [] : get(entity, field);
    let elements = values.map((value, i) => {
      return (
        <a key={field + '-' + i} href='#' onClick={(e) => {e.preventDefault()}} className='tag' aria-disabled='true'>
          <span>{value}</span>
        </a>
      )
    });

    return (
      <React.Fragment>
        <label>{this.getFieldName(field)}</label>
        <span style={{display: 'block'}}>
        {elements}
        </span>
      </React.Fragment>
    )
  }

  renderForm(entity) {
    const { configStore, localeStore } = this.props;
    let sections = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.sections') || [];
    let elements;

    if (sections.length > 0 ) {
      elements = sections.map((section, i) => {
        let sectionFields = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.sections.' + section) || [];
        let sectionCollapsed = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.sections.' + section + '.collapsed') || false;
        let sectionFieldsElements = sectionFields.map((sectionField) => {
          return this.renderFormField(sectionField, entity);
        });
        return (
          <div className='entity-section' key={'entity-section-' + i}>
            <div className='row'>
              <div className='col-12'>
                <a className='entity-section-button' data-toggle='collapse' href={'#entity-section-' + i} role='button' aria-expanded={sectionCollapsed? 'false' : 'true'} aria-controls='entity-section' >
                  <h3 className={'entity-section-title'}>
                    { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.' + this.state.entityType + '.sections.' + section + '.title' }) }
                  </h3>
                  <span className='fas button-caret entity-section-icon'/>
                </a>
              </div>
              <div id={'entity-section-' + i} className={'col-12 collapse' + (sectionCollapsed? '' : ' show') }>
                <div className='row'>
                  {sectionFieldsElements}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }  else {
      let entityFields = this.props.formFields || this.props.fields || Object.keys(entity);
      let entityElements = entityFields.map((entityField) => {
        return this.renderFormField(entityField, entity);
      });
      elements = (
        <div className='row'>
          { entityElements }
        </div>
      );
    }

    return (
      <div className='entity-form'>
        <form>
          { elements }
        </form>
      </div>
    );
  }

  renderFormField(field) {
    const { configStore } = this.props;
    let fieldType = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.' + field + '.form.type')  || typeof this.state.entity[field];
    let columns = configStore.actions.getProperty('retrievo.ui.entity.' + this.state.entityType + '.' + field + '.columns') || 12;

    let fieldElement;
    switch (fieldType) {
      case 'string': case 'text':
        fieldElement = (
          <SimpleFormComponent
            field={field}
            fieldName={this.getFieldName(field)}
            entity={this.state.entity}
            entityType={this.state.entityType}
            entityValidation={this.state.entityValidation}
            onEntityChange={this.onEntityChange}
          />
        );
        break;
      case 'text-read-only':
        fieldElement = this.renderSimpleViewerField(field, this.state.entity);
        break;
      case 'textarea' :
        fieldElement = (
          <TextAreaFormComponent
            field={field}
            fieldName={this.getFieldName(field)}
            entity={this.state.entity}
            entityType={this.state.entityType}
            entityValidation={this.state.entityValidation}
            onEntityChange={this.onEntityChange}
          />
        );
        break;
      case 'number':
        fieldElement = (
          <NumberFormComponent
            field={field}
            fieldName={this.getFieldName(field)}
            entity={this.state.entity}
            entityType={this.state.entityType}
            entityValidation={this.state.entityValidation}
            onEntityChange={this.onEntityChange}
          />
        );
        break;
      case 'boolean':
        fieldElement = (
          <BooleanFormComponent
            field={field}
            fieldName={this.getFieldName(field)}
            entity={this.state.entity}
            entityType={this.state.entityType}
            entityValidation={this.state.entityValidation}
            onEntityChange={this.onEntityChange}
          />
        );
        break;
      case 'file':
        fieldElement = (
          <FileFormComponent
            field={field}
            fieldName={this.getFieldName(field)}
            entity={this.state.entity}
            entityType={this.state.entityType}
            entityFiles={this.state.entityFiles}
            entityValidation={this.state.entityValidation}
            onEntityChange={this.onEntityChange}
          />
        );
        break;
      case 'select':
        fieldElement = (
          <SelectFormComponent
            field={field}
            fieldName={this.getFieldName(field)}
            entity={this.state.entity}
            entityType={this.state.entityType}
            entityValidation={this.state.entityValidation}
            onEntityChange={this.onEntityChange}
          />
        );
        break;
      case 'multi-select':
        fieldElement = (
          <MultiSelectFormComponent
            field={field}
            fieldName={this.getFieldName(field)}
            entity={this.state.entity}
            entityOptions={this.state.entityOptions}
            entityType={this.state.entityType}
            entityValidation={this.state.entityValidation}
            onEntityChange={this.onEntityChange}
          />
          );
        break;
      case 'date' :
        fieldElement = (
          <DateFormComponent
            field={field}
            fieldName={this.getFieldName(field)}
            entity={this.state.entity}
            entityType={this.state.entityType}
            entityValidation={this.state.entityValidation}
            onEntityChange={this.onEntityChange}
          />
        );
        break;
      default:
        fieldElement = (
          <SimpleFormComponent
            field={field}
            fieldName={this.getFieldName(field)}
            entity={this.state.entity}
            entityType={this.state.entityType}
            entityValidation={this.state.entityValidation}
            onEntityChange={this.onEntityChange}
          />
        );
    }

    return (
      <div key={'entity-form-' + field} className={'form-group col-12' + ' col-lg-' + columns }>
        { fieldElement }
      </div>
    );
  }
}

const ConfirmationDialog = ({ closeToast, successToast, title, message, confirmButton, cancelButton}) => (
  <div>
    <h2 className='toast-title' dangerouslySetInnerHTML={{__html : title}}/>
    <span className='toast-message' dangerouslySetInnerHTML={{__html : message}}/>
    <div className='toast-actions'>
      <button className='btn btn-primary' onClick={() => {successToast(); closeToast();}}>{confirmButton}</button>
      <button className='btn btn-outline-primary' onClick={closeToast}>{cancelButton}</button>
    </div>
  </div>
);


EntityComponent.contextType = EntityContext;

export default withConfigStore(
  withLocaleStore(
    EntityComponent
  )
);
