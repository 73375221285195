// External Libs
import React from 'react'
import get from 'lodash/get'
import hljs from 'highlight.js'
import xml from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('xml', xml);

// Components

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'

class SourceViewerComponent extends React.Component {

  constructor(props) {
    super(props);
    // do not show anything until language is loaded
    this.state = { loaded: false };
    // create a ref to highlight only the rendered node and not fetch all the DOM
    this.codeNode = React.createRef();
    this.highlight = this.highlight.bind(this);
  }

  componentDidMount() {
    this.setState({loaded : true}, () => {
      this.highlight();
    });
  }

  componentDidUpdate() {
    this.highlight();
  }

  highlight() {
    this.codeNode && this.codeNode.current && hljs.highlightBlock(this.codeNode.current);
  }

  render() {
    const { field, fieldName, entity } = this.props;

    let value = get(entity, field) || '';
    if (!this.state.loaded) return '';
    return (
      <React.Fragment>
        <dl>
          <dt>{fieldName}</dt>
          <dd>
          <pre>
            <code ref={this.codeNode} className='xml'>{value}</code>
          </pre>
          </dd>
        </dl>
      </React.Fragment>
    );
  }

}

export default withConfigStore(withLocaleStore(SourceViewerComponent))
