// External Libs/Components
import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import cssVars from 'css-vars-ponyfill'

// Components
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'

// Containers
import SearchBarComponent from 'components/search/bars/SearchBarComponent'
import HomeFacetsComponent from 'components/search/facets/HomeFacetsComponent'

// Stores
import { SearchContext, SearchStore } from 'stores/SearchStore'
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'

// CSS (css paths are the only relative paths)
import './_.css'

class HomeComponent extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {};
    this.seeMore = React.createRef();
    this.scrollToSeeMore = this.scrollToSeeMore.bind(this);
  }

  onHelmetChange() {
    cssVars({});
  }
  render() {
    const { configStore } = this.props;

    return (
      <section className='home'>
        <Helmet onChangeClientState={this.onHelmetChange()}>
          <style id='custom-home-theme' type='text/css'>{configStore.actions.getProperty('retrievo.ui.home.theme.css', '')}</style>
        </Helmet>
        <SearchStore entity='records' entityType='records' context='records' redirect={true}>
          <NavbarComponent/>
          <div className='container-fluid'>
            {this.renderBeforeIntro()}
            {this.renderIntro()}
          </div>
          <div ref={this.seeMore} className='arrow-up'/>
          {this.renderMore()}
          <div className='container'>
            {this.renderFacets()}
          </div>
          <FooterComponent mode='full'/>
        </SearchStore>
      </section>
    );
  }

  renderBeforeIntro() {
    const { configStore, localeStore } = this.props;

    let beforeIntroElements = configStore.actions.getProperty('retrievo.ui.home.theme.before_intro.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.home.theme.before_intro.' + localeStore.actions.getLocaleWithoutRegionCode());

    return <div className='before-intro' dangerouslySetInnerHTML={{__html: beforeIntroElements}}/>
  }

  renderIntro() {
    const { configStore, localeStore } = this.props;
    let introElements = configStore.actions.getProperty('retrievo.ui.home.theme.intro.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.home.theme.intro.' + localeStore.actions.getLocaleWithoutRegionCode());

    return (
      <div id='main-content' className='row intro'>
        <div className='container'>
          <div className='intro-custom' dangerouslySetInnerHTML={{__html: introElements}}/>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-10 search'>
              <h1 className='title'>{localeStore.intl.formatMessage({id: 'retrievo.ui.home.intro.title', defaultMessage: ''})}</h1>
              <SearchBarComponent/>
            </div>
          </div>
          {this.renderAbout()}
          <div className='row show-more'>
            <div className='col-12'>
              <a href='#' onClick={this.scrollToSeeMore}>
                <span>{localeStore.intl.formatMessage({id: 'retrievo.ui.home.about.more'})}</span>
                <span className='fas fa-arrow-down'/>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderAbout() {
    const { configStore, localeStore } = this.props;
    let aboutElements = configStore.actions.getProperty('retrievo.ui.home.theme.about.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.home.theme.about.' + localeStore.actions.getLocaleWithoutRegionCode());

    return <div className='about-custom' dangerouslySetInnerHTML={{__html: aboutElements}}/>
  }

  renderMore() {
    const { configStore, localeStore } = this.props;
    let moreElements = configStore.actions.getProperty('retrievo.ui.home.theme.more.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.home.theme.more.' + localeStore.actions.getLocaleWithoutRegionCode());

    return <div className='more-custom' dangerouslySetInnerHTML={{__html: moreElements}}/>
  }

  renderFacets() {
    return (
      <div className='facets row justify-content-center'>
        <HomeFacetsComponent/>
      </div>
    );
  }

  scrollToSeeMore(event) {
    event.preventDefault();
    this.seeMore.current.scrollIntoView({behavior: 'smooth'});
    // without animation example
    // scrollIntoView might not be supported in edge
    //window.scrollTo(0, this.seeMore.current.offsetTop)
  }

}

HomeComponent.contextType = SearchContext;

export default withConfigStore(
  withLocaleStore(
    withRouter(
      HomeComponent)
  )
)
