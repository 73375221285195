import React from 'react'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { withRouter } from 'react-router-dom'
import { withUserStore } from 'stores/UserStore'

class SidebarComponent extends React.Component {

  constructor(props) {
    super(props);
    const { userStore } = props;
    let screenWidth = window.innerWidth || 0;
    this.state = {
      collapsed: !(screenWidth > 992),
      menu: {
        'data_sources': [
          'list', 'add'
        ],
        'tasks': [
          'finished', 'doing'
        ],
        'dashboard': [
          'records', 'tasks', 'data_sources'
        ],
        'configurations': [
          'general', 'theme', 'tasks', 'i18n',
          (userStore.actions.isSudo()? 'advanced' : undefined),
          (userStore.actions.isSudo()? 'cdm' : undefined),
          (userStore.actions.isSudo()? 'entity' : undefined)
        ],
        'info': [
          'about', 'license'
        ],
        'logout' : []
      }
    };

    this.onCollapseToggle = this.onCollapseToggle.bind(this);

  }

  render() {
    return (
      <div className={'sticky-top sidebar' + (this.state.collapsed? ' collapsed' : '')}>
        { this.renderSidebarMenu() }
      </div>
    );
  }

  renderSidebarMenu() {
    const { localeStore } = this.props;

    let sidebarMenuItems = Object.keys(this.state.menu).map((menuItemKey, i) => {
      return this.renderSideBarMenuItem(menuItemKey, this.state.menu[menuItemKey], i);
    });

    sidebarMenuItems.push(
      <li key={'#sidebar-item-collapse'} className={'sidebar-item'} style={{position: 'absolute', bottom: '0', width: '100%'}}>
        <a href='#' onClick={this.onCollapseToggle} className='sidebar-link' data-toggle='collapse' dangerouslySetInnerHTML={{__html:
            this.state.collapsed?
              localeStore.intl.formatMessage({id: 'retrievo.ui.sidebar.collapsed'}) :
              localeStore.intl.formatMessage({id: 'retrievo.ui.sidebar.collapse'})
        }}/>
      </li>
    );

    return (
      <ul id='sidebar-menu-accordion' className='sidebar-menu accordion'>
        { sidebarMenuItems }
      </ul>
    );
  }

  renderSideBarMenuItem(menuItemKey, subMenuItems, menuItemPos) {
    const { localeStore } = this.props;
    let label = localeStore.intl.formatMessage({id: 'retrievo.ui.sidebar.' + menuItemKey + '.label'});

    if(subMenuItems.length > 0 && !this.state.collapsed) {
      return (
        <li key={'#sidebar-item-' + menuItemPos} className={'sidebar-item' + (this.isParentActive(menuItemKey, subMenuItems)? ' active' : '') }>
          <a href={'#sidebar-submenu-' + menuItemPos}
             aria-expanded={this.isParentActive(menuItemKey, subMenuItems)}
             className='sidebar-link' data-toggle='collapse'
             dangerouslySetInnerHTML={{__html: label}}>
          </a>
          {this.renderSidebarSubMenu(menuItemKey, subMenuItems, menuItemPos)}
        </li>
      );
    } else {
      let link = localeStore.intl.formatMessage({id: 'retrievo.ui.sidebar.' + menuItemKey + '.link'});
      return (
        <li key={'#sidebar-item-' + menuItemPos} className='sidebar-item'>
          <a href={link}
             aria-expanded='false'
             className='sidebar-link'
             dangerouslySetInnerHTML={{__html: label}}
          />
        </li>
      );
    }
  }

  renderSidebarSubMenu(menuItemKey, subMenuItems, menuItemPos) {
    let sidebarSubMenuItems = subMenuItems.map((subMenuItem) => {
      if(subMenuItem !== undefined) {
        return this.renderSidebarSubMenuItem(menuItemKey, subMenuItem);
      }
    });

    return (
      <ul className={'sidebar-submenu collapse' + (this.isParentActive(menuItemKey, subMenuItems)? ' show' : '')} id={'sidebar-submenu-' + menuItemPos} data-parent='#sidebar-menu-accordion'>
        { sidebarSubMenuItems }
      </ul>
    );
  }

  renderSidebarSubMenuItem(menuItemKey, subMenuItem) {
    const { localeStore } = this.props;
    let label = localeStore.intl.formatMessage({id: 'retrievo.ui.sidebar.' + menuItemKey + '.' + subMenuItem + '.label'});
    let link = localeStore.intl.formatMessage({id: 'retrievo.ui.sidebar.' + menuItemKey + '.' + subMenuItem + '.link'});

    return (
      <li key={'#sidebar-submenu-item-' + subMenuItem} className={'sidebar-item' + (this.isActive(menuItemKey, subMenuItem) ? ' active' : '')}>
        <a
          href={link}
          onClick={(event) => {
            event.preventDefault();
            let linkParts = link.split('?');
            let path = linkParts[0].replace('/#', '');
            let search = linkParts[1]? '?' + linkParts[1] : '';
            this.props.history.push({ pathname: path, search: search});
          }}
          className='sidebar-link'>
          { label }
        </a>
      </li>
    );
  }

  isParentActive(menuItemKey, subMenuItems) {
    let active = false;
    subMenuItems.map((subMenuItem) => {
      if(this.isActive(menuItemKey, subMenuItem)) {
        active = true;
      }
    });

    return active;
  }


  isActive(menuItemKey, subMenuItemKey) {
    const { localeStore } = this.props;
    let match = false;
    let currentPath = this.props.location.pathname + this.props.location.search;
    let link = (localeStore.intl.formatMessage({id: 'retrievo.ui.sidebar.' + menuItemKey + '.' + subMenuItemKey + '.link'}) || '').replace('/#', '');
    if(currentPath === link){
      match = true;
    } else {
      if(currentPath.includes(link)) {
        match = true;
      }
    }
    return match
  }

  onCollapseToggle(event) {
    event.preventDefault();
    this.setState({ collapsed: !this.state.collapsed })
  }

}


export default withLocaleStore(
  withRouter(
    withUserStore(
      SidebarComponent
    )
  )
)
