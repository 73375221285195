import React from 'react'

// Components
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'
import NavbarComponent from 'components/layout/NavbarComponent'
import FooterComponent from 'components/layout/FooterComponent'
import SidebarComponent from 'components/layout/SidebarComponent'

// Stores
import {withLocaleStore} from 'stores/LocaleStore'
import {withConfigStore} from 'stores/ConfigStore'

import './_.css';

class DashboardComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.onLoad = this.onLoad.bind(this);
  }

  componentDidMount() {
  }

  onLoad() {
    this.setState({ loading: false });
  }

  render () {

    return (
      <React.Fragment>
        <NavbarComponent/>
        <section className='dashboards'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-auto sidebar-container'>
                <SidebarComponent/>
              </div>
              <div className='admin-container col'>
                {this.renderHeader()}
                {this.renderDashboard()}
              </div>
            </div>
          </div>
          <FooterComponent/>
        </section>
      </React.Fragment>
    )
  }

  renderHeader() {
    const { localeStore, match } = this.props;
    return (
      <div className='row'>
        <div className='col-12'>
          <BreadcrumbComponent items={['dashboard']}/>
        </div>
        <div className='col-12'>
          <h1 className='container-title'>{localeStore.intl.formatMessage({id: 'retrievo.ui.dashboards.' + match.params.section + '.title'})}</h1>
          <p className='container-description'>{localeStore.intl.formatMessage({id: 'retrievo.ui.dashboards.' + match.params.section + '.description'})}</p>
        </div>
      </div>
    );
  }

  renderDashboard() {
    const { configStore, localeStore, match } = this.props;

    let dashboards = configStore.actions.getProperty('retrievo.ui.dashboard.' + match.params.section, []);
    let dashboardsElements = dashboards.map((dashboard) => {
      let columns = configStore.actions.getProperty('retrievo.ui.dashboard.' + match.params.section + '.' + dashboard + '.columns', 4);
      let link = configStore.actions.getBaseUrl() + configStore.actions.getProperty('retrievo.ui.dashboard.' + match.params.section + '.' + dashboard + '.link', 4);
      let title = localeStore.intl.formatMessage({id: 'retrievo.ui.dashboard.' + match.params.section + '.' + dashboard + '.title'});
      return (
        <div key={'dashboard-' + dashboard} className={'dashboard-container col-12 col-lg-' + columns +' col-xl-' + columns + ' ' + match.params.section + '-' + dashboard}>
          <h2 className='dashboard-title'>{title}</h2>
          <iframe className='dashboard-iframe' src={link} height={(parseInt(columns)>6? '500' : '300')} width='100%'/>
        </div>
      );
    });

    return (
      <div className='row'>
        { dashboardsElements }
      </div>
    );
  }

}


export default
withConfigStore(
  withLocaleStore(
    DashboardComponent
  )
);
