// External libs
import React, { Component } from 'react'

// Components
import DropdownComponent from 'components/global/elements/DropdownComponent'
import RangeDatePicker from 'components/global/elements/RangeDatePicker'

// CSS
import 'react-day-picker/lib/style.css'
import './_.css'

class AdvancedSearchBarComponent extends Component {

  constructor(props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleOperatorChange = this.handleOperatorChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRangeInputChange = this.handleRangeInputChange.bind(this);
  }

  componentDidMount() {
  }

  handleFieldChange(field) {
    const { selectedField, onChange } = this.props;
    selectedField.key = field;
    selectedField.field = field;
    onChange(selectedField);
  }

  handleOperatorChange(operator) {
    const { selectedField, onChange } = this.props;
    selectedField.operator = operator;
    onChange(selectedField);
  }

  handleInputChange(event) {
    event.preventDefault();
    const { selectedField, onChange } = this.props;
    selectedField.value = event.target.value;
    onChange(selectedField);
  }

  handleRangeInputChange(values) {
    const { selectedField, onChange } = this.props;
    const { from, to } = values;
    selectedField.value = [from ? from.toISOString().split('T')[0] : from, to ? to.toISOString().split('T')[0] : to];
    onChange(selectedField);
  }

  render() {
    const { selectedField, optionFields } = this.props;
    let searchBar;

    switch (selectedField.type) {
      case 'Simple_Filter_Parameter' :
        searchBar = this.renderTermSearch(selectedField);
        break;
      case 'Range_Filter_Parameter' :
        searchBar = this.renderRangeSearch(selectedField);
        break;
      case 'Date_Range_Filter_Parameter' :
        searchBar = this.renderDateRangeSearch(selectedField);
        break;
    }
    return <div className='detailed-search-bar form-row'>
      <div className='search-field col-12 col-md-auto'>
        <DropdownComponent selected={selectedField} options={optionFields} onChange={this.handleFieldChange} />
      </div>
      {searchBar}
    </div>;
  }

  renderTermSearch(filter) {
    const { placeholder } = this.props;
    const selectedOperator = filter.operators.find(operator => { return operator.key === filter.operator });
    return (
      <React.Fragment>
        <div className='search-query col'>
          <div className='form-group'>
            <input type='text' className='form-control form-control' id='term-search-value' aria-describedby='emailHelp'
                   placeholder={placeholder} value={filter.value} onChange={this.handleInputChange} />
          </div>
        </div>
        <div className='search-operator col-auto'>
          <DropdownComponent selected={selectedOperator} options={filter.operators} onChange={this.handleOperatorChange} />
        </div>
      </React.Fragment>
    );
  }

  renderRangeSearch(filter) {
    const { placeholderFrom, placeholderTo } = this.props;

    return (
      <React.Fragment>
        <div className='search-query-range col'>
          <div className='form-group'>
            <input type='text' className='form-control form-control' id='range-search-lower' aria-describedby='emailHelp'
                   placeholder={placeholderFrom} value={filter.value[0] || filter.value} onChange={this.handleInputChange} />
          </div>
        </div>
        <div className='search-query-range col'>
          <div className='form-group'>
            <input type='text' className='form-control form-control' id='range-search-upper' aria-describedby='emailHelp'
                   placeholder={placeholderTo} value={filter.value[1] || filter.value} onChange={this.handleInputChange} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderDateRangeSearch(filter) {
    const { placeholderFrom, placeholderTo } = this.props;

    return <div className='search-query-range col-12 col-md'>
      <div className='form-group'>
        <RangeDatePicker
          from={filter.value[0]}
          to={filter.value[1]}
          placeholderFrom={placeholderFrom}
          placeholderTo={placeholderTo}
          onChange={this.handleRangeInputChange} />
      </div>
    </div> ;
  }

}

export default AdvancedSearchBarComponent;

