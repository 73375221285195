// External libs
import React, { Component } from 'react'

// Stores
import {withSearchStore } from 'stores/SearchStore'
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore';

// CSS
import './_.css'

class HomeFacetsComponent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { configStore, searchStore } = this.props;
    const allowedFacets = configStore.actions.getProperty('retrievo.ui.home.facets') || [];
    let requestFacets = [];

    allowedFacets.map((facet) => {
      let facetLimit = configStore.actions.getProperty('retrievo.ui.home.facets.' + facet + '.limit', 0);
      requestFacets.push({field : facet, limit: facetLimit});
    });

    searchStore.actions.updateRankingFacetsAndResults(requestFacets);
  }

  render() {
    return (
      <React.Fragment>
        {this.renderFacets()}
      </React.Fragment>
    );
  }

  renderFacets() {
    const { configStore, searchStore } = this.props;
    const allowedFacets = configStore.actions.getProperty('retrievo.ui.home.facets') || [];
    return allowedFacets.map((allowedFacetKey) => {
      if(searchStore.data.result.facets.some(this.containsFacet, allowedFacetKey)) {
        let facet = searchStore.data.result.facets.find(this.containsFacet, allowedFacetKey);
        if(facet.totalCount > 0) {
          return this.renderFacet(facet);
        }
      }
    });
  }

  containsFacet(facet) {
    return facet.field === this;
  }

  renderFacet(facet) {
    const { configStore, localeStore } = this.props;
    let type = configStore.actions.getProperty("retrievo.ui.home.facets." + facet.field + ".type", "Simple").toLowerCase() || 'Simple';
    let largestCount = 0;
    let smallestCount = 0;

    if(type === 'tagcloud') {
      largestCount = facet.values.length ? facet.values[0].count : 0;
      smallestCount = facet.values.length ? facet.values[facet.values.length - 1].count : 0;

      // get max value and shuffle entries
      facet.values = facet.values.map((a) => ({sort: Math.random(), value: a}))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value)
    }
    let valueElements = facet.values.map((value) => {
      return this.renderFacetElement(facet, value, type, smallestCount, largestCount);
    });
    return (
      <div className={'facet-home ' + type +' col-10 col-sm-9 col-md-4'} key={'home-facet-' + facet.field}>
        <h2>{ localeStore.intl.formatMessage({id: 'retrievo.ui.home.facets.' + facet.field + '.title'}) }</h2>
        <ul>
          { valueElements }
        </ul>
      </div>
    )
  }

  renderFacetElement(facet, value, type, smallestCount, largestCount) {
    const { localeStore, searchStore } = this.props;
    let min = 1; let max = 10;
    let size = (value.count / largestCount) * (max - min) + min;

    let label = localeStore.intl.formatMessage({id: 'retrievo.ui.search.facets.' + searchStore.actions.getEntity() + '.' +
        facet.field + '.' + value.label + '.label', defaultMessage: value.label});

    switch (type) {
      case 'tagcloud':
        return (
          <li key={'home-facet-' + value.key.replace(/[\W_]+/g,'')}>
            <a href={'/#/records?facets.' + facet.field + '.selected=' + value.key} title={'Total: ' + value.count}>
              <span className={'home-facet-label size-' + Math.round(size)}>{label}</span>
            </a>
          </li>
        );
      default:
        return (
          <li key={'home-facet-' + value.key.replace(/[\W_]+/g,'')}>
            <a href={'/#/records?facets.' + facet.field + '.selected=' + value.key} title={label}>
              <span className='home-facet-label'>{label}</span>
              <span className='home-facet-counter badge badge-light'>{value.count}</span>
            </a>
          </li>
        )

    }
  }

}

export default withSearchStore(
  withConfigStore(
    withLocaleStore(
      HomeFacetsComponent
    )
  )
);
