// External Libs/Components
import React, { Component } from 'react';

// Global Components
import BadgeFacetsComponent from 'components/search/facets/BadgeFacetsComponent'
import SearchResultsComponent from 'components/search/results/SearchResultsComponent';
import TableSearchResultComponent from 'components/search/results/TableSearchResultComponent';

// Stores
import { withLocaleStore } from 'stores/LocaleStore';
import {SearchContext, SearchStore, withSearchStore} from 'stores/SearchStore';
import { withRouter } from 'react-router-dom';

// CSS (css paths are the only relative paths)
import './_.css';
import SearchBarComponent from "components/search/bars/SearchBarComponent";

class TaskReportEntriesComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.update;
  }

  componentDidMount() {
    this.context.actions.updateResults('?facets.taskId.selected=' + this.props.task['id'], false);
  }

  componentDidUpdate(prevProps) {
    const previousQueryParameters = prevProps.location.search;
    const currentQueryParameters = this.props.location.search;

    if(previousQueryParameters !== currentQueryParameters) {
      this.context.actions.updateResults('?facets.taskId.selected=' + this.props.task['id'], false);
    }
  }

  render() {
    const { task } = this.props;
    if(task !== undefined ) {
      return (
        <SearchStore entity='report_entries' context={'admin/tasks/' + task['id']} redirect={false}>
          <TaskReportEntriesHeader/>
          <div className='row task-report-entries'>
            <div className='col-12'>
              <SearchBarComponent showAdvanced={false}/>
              <BadgeFacetsComponent allowedFacets={['type']}/>
            </div>
            <div className='col-12'>
              {
                (task['type'] === 'DELETE_DATA_SOURCE' || task['type'] === 'DELETE_DATA_CONNECTOR' || task['type'] === 'UPDATE_VISIBILITY') ?
                  <SearchResultsComponent SearchResultComponent={EnhancedTableSearchResult2Component}/> :
                  <SearchResultsComponent SearchResultComponent={EnhancedTableSearchResultComponent}/>
              }
            </div>
          </div>
        </SearchStore>
      );
    }
  }
}

const EnhancedTableSearchResultComponent = withLocaleStore(withOriginalIdDisplayFields(TableSearchResultComponent));
const EnhancedTableSearchResult2Component = withLocaleStore(withDisplayFields(TableSearchResultComponent));

function withEnhancedReportEntryItems(localeStore, results) {
  return results.map((result) => {
    switch (result['type']) {
      case 'INFO':
        result['typeEnhanced'] = <span className='badge badge-success'>
              { localeStore.intl.formatMessage( { id: 'retrievo.ui.report_entries.report_entry_items.type.' + result['type'] + '.label' } ) }
            </span>;
        break;
      case 'WARN':
        result['typeEnhanced'] = <span className='badge badge-warning'>
              { localeStore.intl.formatMessage( { id: 'retrievo.ui.report_entries.report_entry_items.type.' + result['type'] + '.label' } ) }
            </span>;
        break;
      case 'ERROR':
        result['typeEnhanced'] = <span className='badge badge-danger'>
              { localeStore.intl.formatMessage( { id: 'retrievo.ui.report_entries.report_entry_items.type.' + result['type'] + '.label' } ) }
            </span>;
        break;
      default:
        result['typeEnhanced'] = <span className='badge badge-warning'>
              { localeStore.intl.formatMessage( { id: 'retrievo.ui.report_entries.report_entry_items.type.' + result['type'] + '.label' } ) }
            </span>;
        break;
    }

    result['lastActionEnhanced'] = localeStore.intl.formatMessage(
      { id: 'retrievo.ui.report_entries.report_entry_items.action.' + result['lastAction'] + '.label', defaultMessage: result['lastAction']});

    return result;
  });
}

function withOriginalIdDisplayFields(Component) {
  const component = class EnhancedTableSearchResultComponent extends React.Component {

    constructor(props) {
      super(props);
      this.onRowClick = this.onRowClick.bind(this);
    }

    onRowClick(target) {
      let { taskId } = this.props.match.params;
      this.props.history.push({
        pathname: '/admin/tasks/' + taskId + '/report_entries/' + target
      });
    }

    render() {
      let { results, localeStore } = this.props;
      return <Component
        {...this.props}
        results={withEnhancedReportEntryItems(localeStore, results)}
        onRowClick={this.onRowClick}
        displayFields={['originalId', 'lastActionEnhanced', 'details', 'typeEnhanced']}
      />;
    }
  };

  return withRouter(component);

}

function withDisplayFields(Component) {
  const component = class EnhancedTableSearchResultComponent extends React.Component {

    constructor(props) {
      super(props);
    }

    render() {
      let { results, localeStore } = this.props;
      return <Component
        {...this.props}
        results={withEnhancedReportEntryItems(localeStore, results)}
        displayFields={['lastActionEnhanced', 'details', 'typeEnhanced']}
      />;
    }
  };

  return withRouter(component);
}

const TaskReportEntriesHeaderComponent = class TaskReportEntriesHeaderComponent extends React.Component {
  render() {
    const { localeStore, searchStore } = this.props;
    return (
      <React.Fragment>
        <h1 className='container-title'>
          { localeStore.intl.formatMessage({ id: 'retrievo.ui.report_entries.title' }) }
        </h1>
        <p className='container-description'>
          { searchStore.data.result.total > 0 ?
            localeStore.intl.formatMessage({ id: 'retrievo.ui.report_entries.description' },
              { total_report_entries: searchStore.data.result.total>0? searchStore.data.result.total : '' }) :
            localeStore.intl.formatMessage({ id: 'retrievo.ui.report_entries.no_results.description'})}
        </p>
      </React.Fragment>
    )
  }
};

const TaskReportEntriesHeader = withLocaleStore(withSearchStore(TaskReportEntriesHeaderComponent));

TaskReportEntriesComponent.contextType = SearchContext;

export default withLocaleStore(
  withRouter(TaskReportEntriesComponent)
);
