// External Libs/Components
import React, { Component } from 'react'
import FileDownload from 'js-file-download'
import { toast } from 'react-toastify'

// Global Components
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'
import EntityComponent from 'components/entity/EntityComponent'
import FileFormComponent from 'components/entity/form/FileFormComponent'
import FooterComponent from 'components/layout/FooterComponent'
import NavbarComponent from 'components/layout/NavbarComponent'
import SidebarComponent from 'components/layout/SidebarComponent'

// Stores
import { withLocaleStore } from 'stores/LocaleStore'
import { withConfigStore } from 'stores/ConfigStore'
import { EntityContext, EntityStore } from 'stores/EntityStore'

// CSS (css paths are the only relative paths)
import './_.css';

class LicenseComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      license: undefined,
      licenseRequest: {}
    };
    this.getLicenseRequest = this.getLicenseRequest.bind(this);
    this.createLicenseRequest = this.createLicenseRequest.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.getLicenseInfo();
  }

  componentDidUpdate() {
    /*
    if(this.state.license === undefined) {
      this.getLicenseInfo();
    }
    */
  }

  render() {
    return (
      <React.Fragment>
        <EntityStore context='license'>
        <NavbarComponent/>
        <section className='license'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-auto sidebar-container'>
                <SidebarComponent/>
              </div>
              <div className='col admin-container'>
                {this.renderBreadcrumb()}
                {this.renderLicense(this.state.license)}
                {this.renderLicenseUpload()}
              </div>
            </div>
          </div>
        </section>
        </EntityStore>
        <FooterComponent/>
      </React.Fragment>
    );
  }

  renderBreadcrumb() {
    const { localeStore } = this.props;
    return (
      <React.Fragment>
        <BreadcrumbComponent items={['info', 'info.license']}/>
        <h1 className='container-title'>
          {localeStore.intl.formatMessage({id: 'retrievo.ui.license.title'})}
        </h1>
        <p className='container-description'>
          {localeStore.intl.formatMessage({id: 'retrievo.ui.license.description'})}
        </p>
      </React.Fragment>
    );
  }

  renderLicense() {
    let isLicensed = this.state.license !== undefined ? Object.entries(this.state.license).length > 0 : false;
    if(isLicensed) {
      return this.renderLicenseInfo();
    } else {
      return this.renderLicenseRequest();
    }
  }

  renderLicenseInfo() {
    if(this.state.license !== undefined) {
      return (
        <EntityComponent
          key={this.state.license['request.product.name'] || ''}
          context='license'
          editable={false}
          edit={false}
          deletable={false}
          entity={this.state.license}
          entityType={'license'}
          actions={this.renderActions()}
          viewerFields={
            [
              'request.product.name',
              'request.product.version',
              'issuer.name',
              'issuer.email',
              'request.entity.name',
              'request.entity.country',
              'request.entity.fiscalNumber',
              'request.entity.address',
              'request.contactPerson.name',
              'request.contactPerson.email',
              'request.contactPerson.role',
              'request.contactPerson.phone'
            ]
          }
        />
      );
    }
  }

  renderLicenseRequest() {
    if(this.state.license !== undefined) {
      let edit = Object.entries(this.state.licenseRequest).length === 0;
      return (
        <EntityComponent
          context='license'
          editable={false}
          edit={edit}
          submit={false}
          deletable={false}
          entity={this.state.licenseRequest}
          entityType={'license.request'}
          viewerFields={
            [
              'entityName',
              'entityCountry',
              'entityFiscalNumber',
              'entityAddress',
              'entityPhone',
              'contactName',
              'contactEmail',
              'contactPosition',
              'contactPhone'
            ]
          }
          formFields={
            [
              'entityName',
              'entityCountry',
              'entityFiscalNumber',
              'entityAddress',
              'entityPhone',
              'contactName',
              'contactEmail',
              'contactPosition',
              'contactPhone'
            ]
          }
          onChange={this.getLicenseRequest}
        />
      );
    }
  }

  renderActions() {
    const { localeStore } = this.props;

    return (
      <button className='btn btn-sm btn-outline-primary' onClick={this.createLicenseRequest}>
        <span className='fa fa-plus'/>
        { localeStore.intl.formatMessage({ id: 'retrievo.ui.entity.license.request.new' }) }
      </button>
    )
  }

  createLicenseRequest() {
    this.setState({license : {}});
  }

  renderLicenseUpload() {
    const { localeStore } = this.props;

    return (
      <React.Fragment>
        <h1 className='container-title'>
          {localeStore.intl.formatMessage({ id: 'retrievo.ui.license.upload.title'})}
        </h1>
        <p className='container-description'>
          {localeStore.intl.formatMessage({ id: 'retrievo.ui.license.upload.description' })}
        </p>
        <FileFormComponent
          field={'file'}
          entity={{}}
          entityType={'license'}
          entityFiles={[]}
          onEntityChange={(entity, entityFiles) => {
            this.setLicense(entity, entityFiles)
          }}
        />
      </React.Fragment>
    );
  }

  getLicenseInfo() {
    const { localeStore } = this.props;

    this.context.actions.get({ context: 'license' })
      .then((result) => {
        this.setState({ license: result.data });
      })
      .catch(() => {
        toast.error(localeStore.intl.formatMessage({ id: 'retrievo.ui.license.not_licensed' }));
        this.setState({ license: {} });
      })
  }

  getLicenseRequest(licenseRequest) {
    const { localeStore } = this.props;

    this.context.actions.post({}, { context: 'license' }, licenseRequest)
      .then((result) => {
        this.setState({licenseRequest: licenseRequest});
        FileDownload(result.data, "license.request");
      })
      .catch(() => {
        toast.error(localeStore.intl.formatMessage({ id: 'retrievo.ui.license.request.error' }));
      })
  }

  setLicense(entity, entityFiles) {
    const { localeStore } = this.props;

    this.context.actions
      .put(entityFiles[0]['data'], { context: 'license'})
      .then(() => {
        toast.success("License submitted with success");
        this.getLicenseInfo();
      })
      .catch(() => {
        toast.error(localeStore.intl.formatMessage({ id: 'retrievo.ui.license.upload.error' }));
      });
  }

}

LicenseComponent.contextType = EntityContext;


export default withLocaleStore(withConfigStore(LicenseComponent))
