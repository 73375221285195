import React from 'react'
import { iframeResizer } from 'iframe-resizer'

import NavbarComponent from 'components/layout/NavbarComponent'
import SidebarComponent from 'components/layout/SidebarComponent'
import BreadcrumbComponent from 'components/layout/BreadcrumbComponent'
import FooterComponent from 'components/layout/FooterComponent'

import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore'
import { withUserStore } from 'stores/UserStore'

import './_.css'


class ConfigurationsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      type: this.props.match.params.type
    };
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    iframeResizer({ log: false }, this.iframeRef.current);
  }

  componentDidUpdate(prevProps) {
    const { userStore } = this.props;
    let type = this.props.match.params.type;

    if(!userStore.actions.isSudo() && type === 'advanced' ) {
      this.props.history.push({
        pathname: '/error',
        search: '?error_code=403'
      });
    }

    if(prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ type });
    }

    window.scrollTo(0, 0);

  }

  render() {
    return (
      <React.Fragment>
        <NavbarComponent/>
        <section className='configurations'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-auto sidebar-container'>
                <SidebarComponent/>
              </div>
              <div className='admin-container col'>
                {this.renderHeader()}
                {this.renderConfigurations()}
              </div>
            </div>
          </div>
          <FooterComponent/>
        </section>
      </React.Fragment>
    );
  }

  renderHeader() {
    const { localeStore } = this.props;
    return (
      <div className='row configurations-header'>
        <div className='col-12'>
          <BreadcrumbComponent items={['configurations', 'configurations.' + this.state.type]}/>
        </div>
        <div className='col-12'>
          <h1 className='container-title'>{ localeStore.intl.formatMessage({ id: 'retrievo.ui.configurations.title' }) }</h1>
        </div>
      </div>
    )
  }

  renderConfigurations() {
    const { configStore, localeStore } = this.props;
    const locale = localeStore.actions.getLocaleWithoutRegionCode();
    let ccUrl = configStore.actions.getBaseUrl() + '/ext/cc-ui';
    let selfUrl = window.location.origin;

    let src = ccUrl + '/#/?style=' + selfUrl +'/assets/css/retrievo-cc.css&mode=brandless&tags='+ this.state.type + '&locale=' + locale;
    return (
      <div className='row'>
        <div className='col-12 configurations-frame'>
          <iframe ref={this.iframeRef} src={src} width={'100%'}/>
        </div>
      </div>
    );
  }

}


export default withLocaleStore( withConfigStore( withUserStore( ConfigurationsComponent ) ) )
