import React, { Component } from 'react';

class SpinnerComponent extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id='spinner' className='row'>
        <div className='mx-auto'>
          <div className={'spinner' + (this.props.fixed? ' fixed' : '')}>
            <div className='double-bounce1'/>
            <div className='double-bounce2'/>
          </div>
        </div>
      </div>
    )
  }
}

export default SpinnerComponent
