import React, { Component } from 'react';

import CardSearchResultComponent from 'components/search/results/CardSearchResultComponent';

import { withLocaleStore } from 'stores/LocaleStore';

class DataSourceSearchResultComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let expandedFields = [];

    let contextParams= '?facets.dataSourceId.selected=';
    return <CardSearchResultComponent
      {...this.props}
      context={'records'}
      contextParams={contextParams}
      expandedFields={expandedFields}
    />
  }

}

export default withLocaleStore(DataSourceSearchResultComponent);
